<template>
  <header
    id="header"
    class="header"
    style="display: flex; align-items: right; justify-content: right"
  >
    <div
      style="
        margin-right: 1%;
        display: flex;
        align-items: right;
        justify-content: right;
      "
    >
      <span>{{ version }}</span>
    </div>
  </header>
  <div class="wrapper">
    <h3 class="setCenter" style="font-size: 2.5rem; text-align: center">
      <!-- <img :src="titleIcon" class="rounded" width="270" /> -->
      &nbsp;{{ titleText }}
    </h3>
    <div class="row col-8 offset-2 setCenter" style="margin-top: 50px">
      <div class="col-12 col-md-9 col-xl-5">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" style="height: 100%">
              <span class="fa-solid fa-user"></span>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="User Name"
            v-model="user"
          />
        </div>
        <div class="input-group" style="margin-top: 20px">
          <div class="input-group-prepend">
            <span class="input-group-text" style="height: 100%">
              <span class="fa-solid fa-key"></span>
            </span>
          </div>
          <input
            id="showHidePassword"
            type="password"
            class="form-control"
            placeholder="Password"
            v-model="password"
          />
          <div class="input-group-prepend" @click="changePasswordIcon">
            <span class="input-group-text" style="height: 100%">
              <span id="showHidePasswordIcon" class="fa fa-eye-slash"></span>
            </span>
          </div>
          <div class="ckbox col-12" style="margin: 10px">
            <input type="checkbox" id="checkIfCustomer" />
            <label for="checkIfCustomer"
              >&nbsp;&nbsp;&nbsp;&nbsp;客戶登入請勾選</label
            >
          </div>
        </div>

        <div class="setCenter">
          <button
            class="btn btn-primary"
            style="margin-top: 20px"
            @click="doLogin"
          >
            login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import { faUser, faKey } from "@fortawesome/fontawesome-free-solid";
fontawesome.library.add(faUser, faKey);

import $ from "jquery";
const Toast_Lib = require("../js/Toast_Lib");
const Common_Lib = require("../js/Common_Lib");
const axios = require("axios").default;

export default {
  id: "login-page",
  name: "login-page",
  components: {},
  data() {
    return {
      titleText: "威勝電動車ERP系統",
      titleIcon: require("../img/weiShengLogo.png"),
      version: Common_Lib.getVersion(),
      user: "",
      password: "",
    };
  },

  setup() {},
  mounted() {
    localStorage.clear();
  },
  methods: {
    changePasswordIcon() {
      if ($("#showHidePassword").attr("type") == "text") {
        $("#showHidePassword").attr("type", "password");
        $("#showHidePasswordIcon").addClass("fa-eye-slash");
        $("#showHidePasswordIcon").removeClass("fa-eye");
      } else if ($("#showHidePassword").attr("type") == "password") {
        $("#showHidePassword").attr("type", "text");
        $("#showHidePasswordIcon").removeClass("fa-eye-slash");
        $("#showHidePasswordIcon").addClass("fa-eye");
      }
    },
    doLogin() {
      var url = Common_Lib.getServerUrl() + "/login";
      var jsonObj = {};
      jsonObj.account = this.user;
      jsonObj.password = this.password;
      jsonObj.customer = document.getElementById("checkIfCustomer").checked;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("登入失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("登入成功", "");

              var token = result.result.token;
              var secretKey = "k" + token + "k";

              var account = this.$CryptoJS.AES.encrypt(
                result.result.account,
                secretKey
              ).toString();
              var role = this.$CryptoJS.AES.encrypt(
                result.result.role,
                secretKey
              ).toString();
              var roleId = this.$CryptoJS.AES.encrypt(
                result.result.roleId,
                secretKey
              ).toString();
              var cname = this.$CryptoJS.AES.encrypt(
                result.result.cname,
                secretKey
              ).toString();
              var ip = this.$CryptoJS.AES.encrypt(
                result.result.ip,
                secretKey
              ).toString();
              var department = this.$CryptoJS.AES.encrypt(
                result.result.department,
                secretKey
              ).toString();
              var userPermission = this.$CryptoJS.AES.encrypt(
                result.result.permissionList,
                secretKey
              ).toString();

              localStorage.setItem("userId", result.result.userId);
              localStorage.setItem("token", token);
              localStorage.setItem("account", account);
              localStorage.setItem("role", role);
              localStorage.setItem("roleId", roleId);
              localStorage.setItem("cname", cname);
              localStorage.setItem("dept", department);
              localStorage.setItem("ip", ip);
              localStorage.setItem("userPermission", userPermission);

              this.$router.push("/dashboard");
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });

      this.$router.push("/login");
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
body {
  font-family: "Noto Sans TC", sans-serif !important;
}

.wrap {
  margin: 0;
  background-color: #fff;
  height: 100%;
}

.setCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss" scoped></style>
