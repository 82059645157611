<template>
  <Header></Header>
  <div class="wrapper setCenter" style="margin-top: 40px">
    <div class="row col-12 col-md-7">
      <h3>{{ mainFunctionTitle }}</h3>
      <div
        class="col-4"
        v-for="(value, key) in subPermissionItemsMap"
        :key="key"
      >
        <button
          class="btn btn-outline-secondary functionButton"
          @click="toFunctionPage(key)"
        >
          <i class="fa-solid fa-file-circle-plus functionButtonIcon"></i
          >&ensp;{{ value["permissionCname"].split("_")[1] }}
          <div
            v-if="value.id == '007-005' && needShowExclamation"
            style="color: #dc3545; float: right"
          >
            <i class="fa-solid fa-circle-exclamation"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import $ from "jquery";

var myID = "ProjectDetail";

const Toast_Lib = require("../js/Toast_Lib");
const Common_Lib = require("../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: { Header },
  props: {
    mainId: {
      type: String,
    },
  },
  data() {
    return {
      mainFunctionTitle: "",
      subPermissionItemsMap: {},
      needShowExclamation: false,
    };
  },

  setup() {},
  mounted() {
    this.doGetUserPermission();
    this.doGetNotConfirmNetworkOrderList();
  },
  methods: {
    toFunctionPage(funcId) {
      this.$router.push("/functionPage/" + this.mainId + "/" + funcId);
    },
    doGetUserPermission() {
      var url = Common_Lib.getServerUrl() + "/getPermissionByRoleId";
      let result = null;

      var secretKey = "k" + Common_Lib.getLocal("token") + "k";

      var jsonObj = {};
      jsonObj.roleId = Common_Lib.doDecrypt(
        Common_Lib.getLocal("roleId"),
        secretKey
      );

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.subPermissionItemsMap = {};

            var allSubPermission = result.result["subPermissionList"];

            for (var i = 0; i < allSubPermission.length; i++) {
              var subItem = allSubPermission[i];
              var subId = subItem["id"].split("-")[0];
              if (subId == this.mainId) {
                this.subPermissionItemsMap[allSubPermission[i]["id"]] = subItem;
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetNotConfirmNetworkOrderList() {
      var url = Common_Lib.getServerUrl() + "/getNotConfirmNetworkOrder";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.result["networkOrderList"].length > 0) {
              this.needShowExclamation = true;
            } else {
              this.needShowExclamation = false;
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
  },
  computed: {},
};
</script>

<style>
.easy-checkbox {
  display: none;
}
.selectedRow {
  --easy-table-body-row-background-color: #add8e6;
  --easy-table-body-row-font-color: #666;
  --easy-table-body-row-hover-background-color: #add8e6;
}
.title {
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 28px;
  color: #666;
}
.subTitle {
  font-weight: bold;
  font-size: 14px;
  color: #ccc;
}
</style>
