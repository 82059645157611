<template>
  <div class="wrapper">
    <div class="row col-12 setCenter" style="margin: 0px">
      <router-view v-slot="{ Component }" :key="$route.fullPath">
        <div>
          <component :is="Component" :key="$route.fullPath" />
        </div>
      </router-view>
    </div>
  </div>
  <div class="footer row">
    <div class="row col-12">
      <div>© WeiSheng, Inc. 2024. Copy Rights Reserved</div>
    </div>
  </div>
</template>

<script>
var myID = "App";
const axios = require("axios").default;
const Toast_Lib = require("./js/Toast_Lib");
const Common_Lib = require("./js/Common_Lib");
import $ from "jquery";

export default {
  name: myID,

  components: {},
  data() {
    return {
      isLogin: null,
    };
  },

  setup() {},
  mounted() {
    this.doCheckSession();
    document.addEventListener("DOMContentLoaded", function () {
      // INSERT CODE HERE
      if (window.history && window.history.pushState) {
        window.onpopstate = function () {
          $("body").removeClass("modal-open");
          $("body").css({ overflow: "visible" });
          $(".modal-backdrop").remove();
        };
      }
    });
  },
  methods: {
    doCheckSession() {
      var url = Common_Lib.getServerUrl() + "/checkSession";
      var jsonObj = {};
      jsonObj.session = localStorage.getItem("token");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
            return false;
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("check session", result.result.errorMsg);
              return result.result.isSessionAvailable;
            } else {
              //Toast_Lib.infoToast("check session", result.result.reason);
              var isSessionAvailable = result.result.isSessionAvailable;
              if (!isSessionAvailable) {
                Toast_Lib.errorToast(
                  "check session",
                  "登入資訊已過期, 請重新登入"
                );

                // 清空local session
                localStorage.removeItem("token");
                localStorage.removeItem("account");
                localStorage.removeItem("role");
                localStorage.removeItem("cname");
                localStorage.removeItem("dept");
                localStorage.removeItem("ip");
                localStorage.removeItem("userPermission");

                this.$router.push("/");
              } else {
                this.isLogin = result.result.isSessionAvailable;

                return result.result.isSessionAvailable;
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
          return false;
        });
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
body {
  font-family: "Noto Sans TC", sans-serif !important;
}

.wrap {
  margin: 0;
  background-color: #fff;
  height: 100%;
}

.setCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerIcon {
  width: 50px;
}

.header {
  height: 100px !important; /*設定header本身高度*/
  text-align: center;
  color: #aaa;
}

.wrapper {
  min-height: calc(100% - 150px); /*減去header+footer高度*/
}

.footer {
  height: 50px; /*設定footer本身高度*/
  background-color: #fff;
  text-align: center;
  color: #aaa;
}
/* function button */
@media (max-width: 769px) {
  .functionButton {
    width: 110px;
    height: 70px;
    margin: 10px;
    text-align: center !important;
    font-size: 14px !important;
  }
  .functionButtonIcon {
    display: none;
  }
}
@media (min-width: 768px) {
  .functionButton {
    width: 200px;
    height: 70px;
    margin: 10px;
    padding-left: 20px !important;
    text-align: left !important;
  }
}
/* table content */
@media (max-width: 769px) {
  .formContent {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) {
  .formContent {
    font-size: 16px !important;
  }
}
/* img */
@media (max-width: 769px) {
  .objImg {
    width: 70px !important;
    height: 97px !important;
  }
}
@media (min-width: 768px) {
  .objImg {
    width: 168px !important;
    height: 232px !important;
  }
}
/* item */
.vehicleCategoryItem {
  border: solid #ccc 1px;
  border-radius: 50px;
  background-color: #eee;
}
.addedItem {
  min-width: max-content;
  padding: 5px;
}

/* search */
.autoComplete {
  padding-left: 0px !important;
}
.searchHover {
  background-color: #eee;
}
.searchHover:hover {
  background-color: bisque;
}
</style>
