<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px">
                    <!--customer info-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-md-6 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >客戶</label
                          >
                          <select
                            class="form-select"
                            id="validationCustomer"
                            v-model="currentCustomerItem"
                            @change="customerOnChange"
                            :disabled="subId == '010-001'"
                            required
                          >
                            <option
                              v-for="(value, key) in currentCustomerList"
                              :key="key"
                              :value="value"
                            >
                              <a class="dropdown-item">{{ value.cname }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇客戶</div>
                        </div>
                        <div class="col-md-6 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >公司抬頭</label
                          >
                          <select
                            class="form-select"
                            id="validationCompanyTitle"
                            v-model="currentCompanyTitleItem"
                            disabled="true"
                            required
                          >
                            <option
                              v-for="t in currentCompanyTitleList"
                              :key="t"
                            >
                              <a class="dropdown-item">{{ t }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇公司抬頭</div>
                        </div>
                      </form>
                    </div>
                    <!--end of customer name-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <div class="row">
                        <div class="row col-12">
                          <div class="col-lg-4 col-12">
                            <label for="validationShipmentNo" class="form-label"
                              >出貨單號</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationShipmentNo"
                              v-model="currentShipmentNo"
                              disabled="true"
                              required
                            />
                            <div class="invalid-feedback">出貨單號不得為空</div>
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationPayPerson" class="form-label"
                              >帳款客戶</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationPayperson"
                              v-model="currentPayPerson"
                              disabled="true"
                              required
                            />
                            <div class="invalid-feedback">請選擇帳款客戶</div>
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationPhone" class="form-label"
                              >電話</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationPhone"
                              v-model="currentPhone"
                              disabled="true"
                              required
                            />
                          </div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationShipmentDate"
                              class="form-label"
                              >出貨日期</label
                            >
                            <input
                              type="date"
                              class="form-control"
                              id="validationShipmentDate"
                              disabled="true"
                              v-model="currentShipmentDate"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationReceiveCustomer"
                              class="form-label"
                              >送貨客戶</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationReceiveCustomer"
                              disabled="true"
                              v-model="currentReceiveCustomer"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationFax" class="form-label"
                              >傳真</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationFax"
                              disabled="true"
                              v-model="currentFax"
                            />
                          </div>
                        </div>

                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationClearanceDate"
                              class="form-label"
                              >結關日期</label
                            >
                            <input
                              type="date"
                              class="form-control"
                              id="validationClearanceDate"
                              disabled="true"
                              v-model="currentClearanceDate"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationDeliveryAddress"
                              class="form-label"
                              Receive
                              >送貨地址</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDeliveryAddress"
                              disabled="true"
                              v-model="currentDeliveryAddress"
                            />
                          </div>
                          <div class="col-lg-4 col-12"></div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label for="validationOrderNo" class="form-label"
                              >訂單單號</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationOrderNo"
                              disabled="true"
                              v-model="currentOrderNo"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationInvoiceNo" class="form-label"
                              >發票號碼</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationInvoiceNo"
                              disabled="true"
                              v-model="currentInvoiceNo"
                              required
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationTax" class="form-label"
                              >稅別</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationTax"
                              disabled="true"
                              v-model="currentTax"
                            />
                          </div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label for="validationSales" class="form-label"
                              >業務員</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationSales"
                              disabled="true"
                              v-model="currentSales"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationDept" class="form-label"
                              >部門</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDept"
                              disabled="true"
                              v-model="currentDetp"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationDeliveryMethod"
                              class="form-label"
                              >交運方式</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDeliveryMethod"
                              disabled="true"
                              v-model="currentDeliveryMethod"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <!-- added prodcuts -->
          <h4 style="font-weight: bold; padding: 20px 0px">已選貨品列表:</h4>
          <table class="table table-filter" style="border: 1px solid #ccc">
            <thead>
              <tr style="font-size: 20px; font-weight: bold">
                <th style="width: 10px"></th>
                <th>#</th>
                <th>產品編號/車架號碼</th>
                <th>品名規格</th>
                <th>數量</th>
                <th>單價</th>
                <th>含稅價</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in tmpSelectComponentList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-for="(item, key) in tmpSelectVehicleList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-primary"
              id="newCustomerBtn"
              v-if="
                subId == '010-001' &&
                currentOrderItem != null &&
                currentOrderItem.status != 'FINISH'
              "
              @click="doConfirmOrder()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定覆核
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form class="autocomplete-container position-relative w-100 mb-4 mb-md-0">
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["companyTitle"] }}-{{ customerIdNameMap[item["customerId"]] }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>

    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>訂單日期</th>
            <th>客戶名稱</th>
            <th>客戶公司抬頭</th>
            <th>訂單狀態</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '007-001'"
                @click="showConfirmModal('', '新增訂單', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentOrderList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createAt"].split(".")[0].replaceAll("T", " ") }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ customerIdNameMap[item["customerId"]] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["companyTitle"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ orderStausConvert[item["status"]] }}
                </div>
              </div>
            </td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '010-001'"
                @click="showConfirmModal(item, '訂單檢視', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '001-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "訂單名稱",
      nameColTitle: "訂單名稱",
      confirmModalTitle: "",
      orderStausConvert: {
        NOTREVIEW: "未覆核",
        NOTCONFIRM: "未確認",
        FINISH: "結單",
      },

      // API
      companyTitleList: [],
      currentCompanyTitleList: [],
      currentCompanyTitleItem: null,
      customerList: [],
      currentCustomerList: [],
      currentCustomerItem: null,
      customerLevelList: [],
      currentCustomerLevelList: [],
      currentCustomerLevelItem: null,
      userList: [],
      currentUserList: [],
      currentUserItem: null,
      orderList: [],
      currentOrderList: [],
      currentOrderItem: null,
      customerIdNameMap: null,

      componentList: [],
      currentComponentList: [],
      vehicleList: [],
      currentVehicleList: [],
      allCustomerLevelPriceMap: null,

      // params
      currentShipmentNo: "",
      currentPhone: "",
      currentShipmentDate: "",
      currentReceiveCustomer: "",
      currentClearanceDate: "",
      currentDeliveryAddress: "",
      currentPayPerson: "",
      currentFax: "",
      currentDetp: "",
      currentOrderNo: "",
      currentInvoiceNo: "",
      currentTax: "",
      currentSales: "",
      currentDeliveryMethod: "",
      currentProductTypeList: ["零件", "完成車"],
      currentComponentTypeItem: null,
      currentProductList: [],
      currentProductItem: null,
      currentPreviewItem: null,
      currentProductQuantity: 1,
      currentUnitPrice: 1,

      tmpSelectComponentList: [],
      tmpSelectVehicleList: [],
      tmpComponentMap: null,
      tmpVehicleMap: null,

      currentStartDate: "",
      currentEndDate: "",

      //
      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchProductText: "",
      autoCompleteProduct: false,
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  mounted() {
    if (this.subId == "010-001") {
      this.doGetOrderList("");
    }
  },
  methods: {
    showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      this.doGetCompanyTitleList();
      this.doGetCustomerList("");

      this.doGetCustomerLevelList();
      this.doGetUserList();
      this.doGetComponentList();
      this.doGetVehicleList();

      if (item != "") {
        this.currentOrderItem = item;
        this.currentCompanyTitleItem = item.companyTitle;

        let customerId = item.customerId;
        this.doGetCustomerList(customerId);

        if (this.subId == "010-001") {
          this.doGetOrderList(item.id);
        }

        this.currentShipmentNo = item.shipmentNo;
        this.currentPhone = item.phone;
        this.currentShipmentDate = item.shipmentAt;
        this.currentReceiveCustomer = item.recivePerson;
        this.currentClearanceDate = item.clearanceAt;
        this.currentDeliveryAddress = item.deliveryAddress;
        this.currentPayPerson = item.payPerson;
        this.currentFax = item.fax;
        this.currentDetp = item.dept;
        this.currentOrderNo = item.orderNo;
        this.currentInvoiceNo = item.invoiceNo;
        this.currentTax = item.tax;
        this.currentSales = item.salesman;
        this.currentDeliveryMethod = item.deliveryMethod;
      }
    },
    resetConfirmModal() {
      this.currentShipmentNo = "";
      this.currentPhone = "";
      this.currentShipmentDate = "";
      this.currentReceiveCustomer = "";
      this.currentClearanceDate = "";
      this.currentDeliveryAddress = "";
      this.currentPayPerson = "";
      this.currentFax = "";
      this.currentDetp = "";
      this.currentOrderNo = "";
      this.currentInvoiceNo = "";
      this.currentTax = "";
      this.currentSales = "";
      this.currentDeliveryMethod = "";

      this.currentComponentTypeItem = null;
      this.currentProductList = [];
      this.currentProductItem = null;
      this.currentPreviewItem = null;
      this.currentProductQuantity = 1;
      this.currentUnitPrice = 1;

      this.tmpSelectComponentList = [];
      this.tmpSelectVehicleList = [];

      this.currentUserItem = null;
      this.currentCustomerItem = null;
      this.currentCompanyTitleItem = null;
    },
    customerOnChange() {
      if (this.currentCustomerItem != null) {
        this.currentFax = this.currentCustomerItem.fax;
        this.currentPhone = this.currentCustomerItem.phone1;
        this.currentReceiveCustomer = this.currentCustomerItem.recivePerson;
        this.currentPayPerson = this.currentCustomerItem.payPerson;
        this.currentDeliveryAddress = this.currentCustomerItem.deliveryAddress1;

        let customerLevelId = this.currentCustomerItem.customerLevelId;
        for (let i = 0; i < this.currentCustomerLevelList.length; i++) {
          let obj = this.currentCustomerLevelList[i];
          if (customerLevelId == obj.id) {
            this.currentTax = obj.tax;
            this.currentDeliveryMethod = obj.payMethod;
          }
        }

        let salesId = this.currentCustomerItem.salesmanId;
        for (let i = 0; i < this.currentUserList.length; i++) {
          let obj = this.currentUserList[i];
          if (salesId == obj.id) {
            this.currentSales = obj.cname;
          }
        }

        this.doGetAllCustomerLevelPriceMap();
      }
    },
    componentTypeOnChange() {
      if (this.currentComponentTypeItem == "零件") {
        this.currentProductList = this.currentComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        this.currentProductList = this.currentVehicleList;
      }
    },

    getCustomerItem(id) {
      for (var i = 0; i < this.currentCustomerList.length; i++) {
        var obj = this.currentCustomerList[i];
        if (id == obj.id) {
          this.currentCustomerItem = obj;
          return;
        }
      }
    },

    doConfirmOrder() {
      var url = Common_Lib.getServerUrl() + "/confirmOrder";

      var jsonObj = {};
      jsonObj.orderId = this.currentOrderItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("覆核訂單失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("覆核訂單成功", "");

              this.doGetOrderList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    doNewAndEditOrder(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentCustomerItem == null) {
        Toast_Lib.errorToast("客戶不得為空");
        return;
      }

      if (this.currentCompanyTitleItem == "") {
        Toast_Lib.errorToast("公司抬頭不得為空");
        return;
      }

      if (this.currentPhone == "") {
        Toast_Lib.errorToast("電話不得為空");
        return;
      }
      if (this.currentDeliveryAddress == "") {
        Toast_Lib.errorToast("送貨地址不得為空");
        return;
      }
      if (this.currentPayPerson == "") {
        Toast_Lib.errorToast("帳款客戶不得為空");
        return;
      }
      if (this.currentReceiveCustomer == "") {
        Toast_Lib.errorToast("送貨客戶不得為空");
        return;
      }
      if (this.currentTax == "") {
        Toast_Lib.errorToast("稅別不得為空");
        return;
      }
      if (this.currentDeliveryMethod == "") {
        Toast_Lib.errorToast("交運方式不得為空");
        return;
      }
      if (this.currentCompanyTitleItem == "") {
        Toast_Lib.errorToast("公司抬頭不得為空");
        return;
      }
      if (this.currentSales == "") {
        Toast_Lib.errorToast("業務員不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newOrder";
      var toastMsg = "新增訂單";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateOrder";
        toastMsg = "編輯訂單";
      }

      // payload
      var jsonObj = {};

      if (!isEdit) {
        jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
        jsonObj.customerId = this.currentCustomerItem.id;
        jsonObj.status = "NOTREVIEW";
      } else {
        jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
        jsonObj.orderId = this.currentOrderItem.id;
      }

      jsonObj.companyTitle = this.currentCompanyTitleItem;

      jsonObj.phone = this.currentPhone;
      jsonObj.fax = this.currentFax;
      jsonObj.deliveryAddress = this.currentDeliveryAddress;
      jsonObj.payPerson = this.currentPayPerson;
      jsonObj.recivePerson = this.currentReceiveCustomer;
      jsonObj.tax = this.currentTax;
      jsonObj.deliveryMethod = this.currentDeliveryMethod;
      jsonObj.salesman = this.currentSales;
      jsonObj.dept = this.currentDetp;

      jsonObj.shipmentNo = this.currentShipmentNo;
      jsonObj.orderNo = this.currentOrderNo;
      jsonObj.shipmentAt = this.currentShipmentDate;
      jsonObj.clearanceAt = this.currentClearanceDate;
      jsonObj.invoiceNo = this.currentInvoiceNo;

      jsonObj.tmpSelectComponentList = this.tmpSelectComponentList;
      jsonObj.tmpSelectVehicleList = this.tmpSelectVehicleList;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetOrderList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetOrderList(id) {
      var url = Common_Lib.getServerUrl() + "/getOrderList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;

            this.orderList = [];
            let tmpList = result.result["orderList"];
            for (let i = 0; i < tmpList.length; i++) {
              let obj = tmpList[i];
              if (obj.status != "FINISH") {
                this.orderList.push(obj);
              }
            }

            this.currentOrderList = this.orderList;

            this.tmpComponentMap = result.result["tmpComponentMap"];
            this.tmpVehicleMap = result.result["tmpVehicleMap"];
            this.customerIdNameMap = result.result["customerIdNameMap"];

            if (id != "") {
              this.tmpSelectComponentList = this.tmpComponentMap[id];
              this.tmpSelectVehicleList = this.tmpVehicleMap[id];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    doGetOrderListByTimeRange(id) {
      var url = Common_Lib.getServerUrl() + "/getTimeRangeOrderList";
      let result = null;
      var jsonObj = {};
      if (this.subId == "007-003") {
        jsonObj.today = true;
        jsonObj.startDate = "";
        jsonObj.endDate = "";
      } else if (this.subId == "007-004") {
        if (this.currentStartDate == "") {
          Toast_Lib.errorToast("開始日期不得為空");
          return;
        }
        if (this.currentEndDate == "") {
          Toast_Lib.errorToast("結束日期不得為空");
          return;
        }
        jsonObj.today = false;
        jsonObj.startDate = this.currentStartDate;
        jsonObj.endDate = this.currentEndDate;
      }

      // just finish
      if (document.getElementById("checkWithoutFinish") == null) {
        jsonObj.withoutFinish = false;
      } else {
        jsonObj.withoutFinish =
          document.getElementById("checkWithoutFinish").checked;
      }

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.orderList = result.result["timeRangeOrderList"];
            this.currentOrderList = this.orderList;

            this.tmpComponentMap = result.result["tmpComponentMap"];
            this.tmpVehicleMap = result.result["tmpVehicleMap"];
            this.customerIdNameMap = result.result["customerIdNameMap"];

            if (id != "") {
              this.tmpSelectComponentList = this.tmpComponentMap[id];
              this.tmpSelectVehicleList = this.tmpVehicleMap[id];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // get combobox list
    doGetCompanyTitleList(customerLevelId) {
      var url = Common_Lib.getServerUrl() + "/getCompanyTitleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.companyTitleList = [];
            this.currentCompanyTitleList = [];

            result = response.data;
            this.companyTitleList = result.result["companyTitleList"];
            this.currentCompanyTitleList = this.companyTitleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerList(id) {
      var url = Common_Lib.getServerUrl() + "/getCustomerList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerList = [];
            this.currentCustomerList = [];

            result = response.data;
            this.customerList = result.result["customerList"];
            this.currentCustomerList = this.customerList;

            if (id != "") {
              this.getCustomerItem(id);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetUserList() {
      var url = Common_Lib.getServerUrl() + "/getUserList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.userList = result.result["userList"];
            this.currentUserList = this.userList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            // this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetAllCustomerLevelPriceMap() {
      if (this.currentCustomerItem == null) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getAllCustomerLevelPriceMap";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerItem.customerLevelId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶分類價格失敗", result.errorMsg);
            } else {
              this.allCustomerLevelPriceMap =
                result.result["allCustomerLevelPriceMap"];
              if (this.currentProductItem != null) {
                this.currentUnitPrice =
                  this.allCustomerLevelPriceMap[this.currentProductItem.id];
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    addTmpProduct() {
      if (
        this.currentProductQuantity == null ||
        this.currentProductQuantity < 1 ||
        this.currentUnitPrice == null ||
        this.currentUnitPrice < 1 ||
        this.currentTax == null ||
        this.currentTax < 1 ||
        this.currentProductItem == null
      ) {
        Toast_Lib.errorToast("無法加入, 請填寫所有欄位");
        return;
      }

      let productObj = {};
      if (!this.checkTmpProductList(this.currentProductItem.id)) {
        Toast_Lib.errorToast(
          "無法加入, 該品項已存在: " + this.currentProductItem.cname
        );
        return;
      }
      productObj.productId = this.currentProductItem.id;
      productObj.cname = this.currentProductItem.cname;
      productObj.partNo = this.currentProductItem.partNo;
      productObj.quantity = this.currentProductQuantity;
      productObj.unitPrice = this.currentUnitPrice;
      productObj.priceWithTax = Math.floor(
        this.currentProductQuantity *
          this.currentUnitPrice *
          (1 + this.currentTax / 100)
      );

      if (this.currentComponentTypeItem == "零件") {
        productObj.type = "component";
        this.tmpSelectComponentList.push(productObj);
      } else if (this.currentComponentTypeItem == "完成車") {
        productObj.type = "vehicle";
        this.tmpSelectVehicleList.push(productObj);
      }
    },
    checkTmpProductList(id) {
      let targetList = [];
      if (this.currentComponentTypeItem == "零件") {
        targetList = this.tmpSelectComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId == id) {
          return false;
        }
      }
      return true;
    },
    removeTmpProduct(id, t) {
      let targetList = [];
      let resultList = [];
      if (t == "component") {
        targetList = this.tmpSelectComponentList;
      } else if (t == "vehicle") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId != id) {
          resultList.push(obj);
        }
      }
      if (t == "component") {
        this.tmpSelectComponentList = resultList;
      } else if (t == "vehicle") {
        this.tmpSelectVehicleList = resultList;
      }
      resultList = [];
    },
    // for search bar function
    clickSearchItem(customerItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "010-001") {
        modalTitle = "訂單檢視";
        isEdit = true;
      }
      //  else {
      //   modalTitle = "確認刪除?";
      //   isEdit = false;
      // }
      this.showConfirmModal(customerItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentOrderList = [];
      if (this.search == "") {
        this.currentOrderList = this.orderList;
      } else {
        for (var i = 0; i < this.orderList.length; i++) {
          var orderItem = this.orderList[i];
          if (
            orderItem["companyTitle"].includes(this.search) ||
            this.customerIdNameMap[orderItem["customerId"]].includes(
              this.search
            )
          ) {
            this.currentOrderList.push(orderItem);
          }
        }
      }
    },
    clickSearchProduct(item) {
      this.currentProductItem = item;
      this.currentPreviewItem = item;
      this.autoCompleteProduct = false;
      this.searchProductText = "";
      this.doGetAllCustomerLevelPriceMap();
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      // if (this.searchProductText) {
      //   console.log("tt");
      //   this.autoCompleteProduct = true;
      // } else {
      //   console.log("ff");
      //   this.autoCompleteProduct = false;
      // }
    },
  },

  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
    searchProductText() {
      if (this.searchProductText) {
        this.autoCompleteProduct = true;
      } else {
        this.autoCompleteProduct = false;
      }
    },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.orderList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.companyTitle.includes(searchKeyword) ||
            this.customerIdNameMap[item["customerId"]].includes(this.search))
        ) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchProductText;
      // 比對字串
      this.currentProductList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.cname.includes(searchKeyword) ||
            item.partNo.includes(searchKeyword))
        ) {
          arr.push(item);
        }
      });
      // if (arr.length > 0) {
      //   this.autoComplete = true;
      // } else {
      //   this.autoComplete = false;
      // }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
