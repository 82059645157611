import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "await";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fas);
library.add(far);

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import VueCryptojs from "vue-cryptojs";
import CryptoJS from "crypto-js";
const Common_Lib = require("./js/Common_Lib");

router.beforeEach((to, from, next) => {
  const isLogin = Common_Lib.getLocal("token") != "";

  // get user permission
  var secretKey = "k" + Common_Lib.getLocal("token") + "k";
  const userPermission = Common_Lib.doDecrypt(
    Common_Lib.getLocal("userPermission"),
    secretKey
  );

  if (isLogin) {
    if (to.path == "/login") next("/dashboard"); // 如果已登入

    var userPermissionList = userPermission.split(",");

    for (var i = 0; i < userPermissionList.length; i++) {
      var mainId = userPermissionList[i].split("-")[0];
      if (!userPermissionList.includes(mainId)) {
        userPermissionList.push(mainId);
      }
    }

    var toPath = to.path;
    var functionId = toPath.substring(toPath.lastIndexOf("/") + 1);

    if (
      (toPath.includes("functionPage") || toPath.includes("subFunction")) &&
      !userPermissionList.includes(functionId)
    ) {
      next("/dashboard");
    }

    next();
  } else {
    if (to.path !== "/") next("/");
    else next();
  }
});
createApp(App)
  .component("EasyDataTable", Vue3EasyDataTable)
  .use(VueCryptojs)
  .use(router)
  .mount("#app");
