<template>
  <!-- order modal -->
  <div
    class="modal fade"
    id="orderModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ orderModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px">
                    <!--customer info-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-md-6 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >客戶</label
                          >
                          <select
                            class="form-select"
                            id="validationCustomer"
                            v-model="currentCustomerItem"
                            @change="customerOnChange"
                            disabled="true"
                            required
                          >
                            <option
                              v-for="(value, key) in currentCustomerList"
                              :key="key"
                              :value="value"
                            >
                              <a class="dropdown-item">{{ value.cname }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇客戶</div>
                        </div>
                        <div class="col-md-6 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >公司抬頭</label
                          >
                          <select
                            class="form-select"
                            id="validationCompanyTitle"
                            v-model="currentCompanyTitleItem"
                            disabled="true"
                            required
                          >
                            <option
                              v-for="t in currentCompanyTitleList"
                              :key="t"
                            >
                              <a class="dropdown-item">{{ t }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇公司抬頭</div>
                        </div>
                      </form>
                    </div>
                    <!--end of customer name-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <div class="row">
                        <div class="row col-12">
                          <div class="col-lg-4 col-12">
                            <label for="validationShipmentNo" class="form-label"
                              >出貨單號</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationShipmentNo"
                              v-model="currentShipmentNo"
                              disabled="true"
                              required
                            />
                            <div class="invalid-feedback">出貨單號不得為空</div>
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationPayPerson" class="form-label"
                              >帳款客戶</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationPayperson"
                              v-model="currentPayPerson"
                              disabled="true"
                              required
                            />
                            <div class="invalid-feedback">請選擇帳款客戶</div>
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationPhone" class="form-label"
                              >電話</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationPhone"
                              v-model="currentPhone"
                              disabled="true"
                              required
                            />
                          </div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationShipmentDate"
                              class="form-label"
                              >出貨日期</label
                            >
                            <input
                              type="date"
                              class="form-control"
                              id="validationShipmentDate"
                              disabled="true"
                              v-model="currentShipmentDate"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationReceiveCustomer"
                              class="form-label"
                              >送貨客戶</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationReceiveCustomer"
                              disabled="true"
                              v-model="currentReceiveCustomer"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationFax" class="form-label"
                              >傳真</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationFax"
                              disabled="true"
                              v-model="currentFax"
                            />
                          </div>
                        </div>

                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationClearanceDate"
                              class="form-label"
                              >結關日期</label
                            >
                            <input
                              type="date"
                              class="form-control"
                              id="validationClearanceDate"
                              disabled="true"
                              v-model="currentClearanceDate"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationDeliveryAddress"
                              class="form-label"
                              Receive
                              >送貨地址</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDeliveryAddress"
                              disabled="true"
                              v-model="currentDeliveryAddress"
                            />
                          </div>
                          <div class="col-lg-4 col-12"></div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label for="validationOrderNo" class="form-label"
                              >訂單單號</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationOrderNo"
                              disabled="true"
                              v-model="currentOrderNo"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationInvoiceNo" class="form-label"
                              >發票號碼</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationInvoiceNo"
                              disabled="true"
                              v-model="currentInvoiceNo"
                              required
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationTax" class="form-label"
                              >稅別</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationTax"
                              disabled="true"
                              v-model="currentTax"
                            />
                          </div>
                        </div>
                        <div class="row col-12" style="margin-top: 20px">
                          <div class="col-lg-4 col-12">
                            <label for="validationSales" class="form-label"
                              >業務員</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationSales"
                              disabled="true"
                              v-model="currentSales"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label for="validationDept" class="form-label"
                              >部門</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDept"
                              disabled="true"
                              v-model="currentDetp"
                            />
                          </div>
                          <div class="col-lg-4 col-12">
                            <label
                              for="validationDeliveryMethod"
                              class="form-label"
                              >交運方式</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDeliveryMethod"
                              disabled="true"
                              v-model="currentDeliveryMethod"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <!-- added prodcuts -->
          <h4 style="font-weight: bold; padding: 20px 0px">貨品列表:</h4>
          <table class="table table-filter" style="border: 1px solid #ccc">
            <thead>
              <tr style="font-size: 20px; font-weight: bold">
                <th style="width: 10px"></th>
                <th>#</th>
                <th>產品編號/車架號碼</th>
                <th>品名規格</th>
                <th>數量</th>
                <th>單價</th>
                <th>含稅價</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in tmpSelectComponentList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-for="(item, key) in tmpSelectVehicleList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-toggle="modal"
              data-bs-target="#confirmModal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of order modal-->
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px">
                    <!--customer info-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-lg-6 col-12">
                          <label for="validationNewCustomNo" class="form-label"
                            >客戶編號</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationNewCustomNo"
                            :disabled="subId != '001-001'"
                            v-model="currentCustomerNo"
                            required
                          />
                          <div class="invalid-feedback">客戶編號不得為空</div>
                        </div>
                        <div class="col-lg-6 col-12">
                          <label for="validationNewAccount1" class="form-label"
                            >帳號</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationNewAccount1"
                            :disabled="subId != '001-001'"
                            v-model="currentAccount"
                            required
                          />
                          <div class="invalid-feedback">帳號不得為空</div>
                        </div>
                        <div
                          v-if="subId == '001-001' || subId == '001-004'"
                          class="col-lg-6 col-12"
                        >
                          <label for="validationNewPassword2" class="form-label"
                            >密碼</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="validationNewPassword2"
                            v-model="currentPassword"
                            required
                          />
                          <div class="invalid-feedback">密碼不得為空</div>
                        </div>
                      </form>
                      <div class="row col-12">
                        <div class="col-md-6 col-12">
                          <label
                            for="validationCustomerLevel"
                            class="form-label"
                            >客戶分級</label
                          >
                          <select
                            class="form-select"
                            id="validationCustomerLevel"
                            :disabled="subId == '001-003' || subId == '001-004'"
                            v-model="currentCustomerLevelItem"
                            required
                          >
                            <option
                              v-for="(value, key) in currentCustomerLevelList"
                              :key="key"
                              :value="value"
                            >
                              <a class="dropdown-item">{{
                                value.customerLevelCname
                              }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇客戶分級</div>
                          <div class="col-12" style="margin-top: 10px">
                            <span v-if="currentCustomerLevelItem != null"
                              >&ensp;交易方式:
                              {{ currentCustomerLevelItem.payMethod }}</span
                            >
                          </div>
                          <div class="col-12">
                            <span v-if="currentCustomerLevelItem != null"
                              >&ensp;稅別:
                              {{ currentCustomerLevelItem.tax }}&ensp;%</span
                            >
                          </div>
                        </div>

                        <div class="col-md-6 col-12">
                          <label for="validationUser" class="form-label"
                            >負責業務</label
                          >
                          <select
                            class="form-select"
                            id="validationUser"
                            :disabled="subId == '001-003' || subId == '001-004'"
                            v-model="currentUserItem"
                            required
                          >
                            <option
                              v-for="(value, key) in currentUserList"
                              :key="key"
                              :value="value"
                            >
                              <a class="dropdown-item">{{ value.cname }}</a>
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇負責業務</div>
                        </div>
                      </div>
                    </div>
                    <!--end of customer name-->

                    <span v-if="subId != '001-004'"> 客戶資料: </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="subId != '001-004'">
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationNewCustomName" class="form-label"
                        >客戶名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewCustomName"
                        :disabled="subId == '001-003'"
                        v-model="currentCustomerCname"
                        required
                      />
                      <div class="invalid-feedback">客戶名稱不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewTaxIdNo" class="form-label"
                        >統一編號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewTaxIdNo"
                        v-model="currentTaxIdNo"
                        :disabled="subId != '001-001'"
                        required
                      />
                      <div class="invalid-feedback">統一編號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewHead" class="form-label"
                        >公司負責人</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewHead"
                        v-model="currentHead"
                        :disabled="subId == '001-003'"
                        required
                      />
                      <div class="invalid-feedback">公司負責人不得為空</div>
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationNewContactPerson" class="form-label"
                        >聯絡人</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewContactPerson"
                        :disabled="subId == '001-003'"
                        v-model="currentContactPerson"
                        required
                      />
                      <div class="invalid-feedback">聯絡人不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone1" class="form-label"
                        >聯絡電話1</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone1"
                        :disabled="subId == '001-003'"
                        v-model="currentPhone1"
                        required
                      />
                      <div class="invalid-feedback">聯絡電話1不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone2" class="form-label"
                        >聯絡電話2</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone2"
                        :disabled="subId == '001-003'"
                        v-model="currentPhone2"
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone3" class="form-label"
                        >聯絡電話3</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone3"
                        :disabled="subId == '001-003'"
                        v-model="currentPhone3"
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewFax" class="form-label"
                        >傳真</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewFax"
                        :disabled="subId == '001-003'"
                        v-model="currentFax"
                      />
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationNewPayPerson" class="form-label"
                        >帳款客戶</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPayPerson"
                        :disabled="subId == '001-003'"
                        v-model="currentPayPerson"
                        required
                      />
                      <div class="invalid-feedback">帳款客戶不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewRecivePerson" class="form-label"
                        >收貨客戶</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewRecivePerson"
                        :disabled="subId == '001-003'"
                        v-model="currentRecivePerson"
                        required
                      />
                      <div class="invalid-feedback">收貨客戶不得為空</div>
                    </div>
                    <div class="col-12">
                      <label
                        for="validationNewCompanyAddress"
                        class="form-label"
                        >公司地址</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewCompanyAddress"
                        :disabled="subId == '001-003'"
                        v-model="currentCompanyAddress"
                        required
                      />
                      <div class="invalid-feedback">公司地址不得為空</div>
                    </div>
                    <div class="col-12">
                      <label
                        for="validationNewDeliveryAddress1"
                        class="form-label"
                        >收貨地址1</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewDeliveryAddress1"
                        :disabled="subId == '001-003'"
                        v-model="currentDeliveryAddress1"
                        required
                      />
                      <div class="invalid-feedback">收貨地址1不得為空</div>
                    </div>
                    <div class="col-12">
                      <label
                        for="validationNewDeliveryAddress2"
                        class="form-label"
                        >收貨地址2</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewDeliveryAddress2"
                        :disabled="subId == '001-003'"
                        v-model="currentDeliveryAddress2"
                      />
                    </div>
                    <div class="col-12">
                      <label for="validationNewNote" class="form-label"
                        >客戶需求備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewNote"
                        :disabled="subId == '001-003'"
                        v-model="currentNote"
                      />
                    </div>
                    <div class="col-12">
                      <label for="validationNewSpecialNote" class="form-label"
                        >特殊備註事項(不會顯示在紙本文件)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewSpecialNote"
                        :disabled="subId == '001-003'"
                        v-model="currentSpecialNote"
                      />
                    </div>
                  </form>
                  <hr />
                  <h5 style="font-weight: bold; padding: 20px 0px">
                    未結單記錄:
                  </h5>
                  <table
                    class="table table-filter"
                    style="border: 1px solid #ccc"
                    v-if="currentNotFinishOrderList.length > 0"
                  >
                    <thead>
                      <tr style="font-size: 16px; font-weight: bold">
                        <th style="width: 10px"></th>
                        <th>#</th>
                        <th>訂單日期</th>
                        <th>客戶名稱</th>
                        <th>客戶公司抬頭</th>
                        <th>訂單狀態</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, key) in currentNotFinishOrderList"
                        :key="key"
                      >
                        <td style="width: 10px"></td>
                        <td>{{ key + 1 }}</td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{
                                item["createAt"]
                                  .split(".")[0]
                                  .replaceAll("T", " ")
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ customerIdNameMap[item["customerId"]] }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ item["companyTitle"] }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ orderStausConvert[item["status"]] }}
                            </div>
                          </div>
                        </td>
                        <td style="text-align: right">
                          <button
                            class="btn btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#orderModal"
                            v-if="subId == '001-002'"
                            @click="showOrderModal(item, '訂單檢視', true)"
                            style="margin-right: 10px"
                          >
                            <i class="fa-solid fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h5 style="font-weight: bold; padding: 20px 0px">
                    歷史交易記錄:
                  </h5>
                  <table
                    class="table table-filter"
                    style="border: 1px solid #ccc"
                    v-if="currentFinishOrderList.length > 0"
                  >
                    <thead>
                      <tr style="font-size: 16px; font-weight: bold">
                        <th style="width: 10px"></th>
                        <th>#</th>
                        <th>訂單日期</th>
                        <th>客戶名稱</th>
                        <th>客戶公司抬頭</th>
                        <th>訂單狀態</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, key) in currentFinishOrderList"
                        :key="key"
                      >
                        <td style="width: 10px"></td>
                        <td>{{ key + 1 }}</td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{
                                item["createAt"]
                                  .split(".")[0]
                                  .replaceAll("T", " ")
                              }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ customerIdNameMap[item["customerId"]] }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ item["companyTitle"] }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="media formContent">
                            <div class="media-body">
                              {{ orderStausConvert[item["status"]] }}
                            </div>
                          </div>
                        </td>
                        <td style="text-align: right">
                          <button
                            class="btn btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#orderModal"
                            v-if="subId == '001-002'"
                            @click="showOrderModal(item, '訂單檢視', true)"
                            style="margin-right: 10px"
                          >
                            <i class="fa-solid fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteCustomerBtn"
              v-if="subId == '001-003'"
              @click="doDeleteCustomer()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editCustomerBtn"
              v-if="subId == '001-001'"
              @click="doNewAndEditCustomer(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newCustomerBtn"
              v-if="subId == '001-002'"
              @click="doNewAndEditCustomer(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-primary"
              id="changePasswordBtn"
              v-if="subId == '001-004'"
              @click="doChangePassword()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="subId != '001-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["cname"] }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '001-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentCustomerList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '001-002'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '001-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '001-004'"
                @click="showConfirmModal(item, '變更密碼', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-key"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "客戶名稱",
      nameColTitle: "客戶名稱",
      confirmModalTitle: "",
      orderModalTitle: "",

      orderStausConvert: {
        NOTREVIEW: "未覆核",
        NOTCONFIRM: "未確認",
        FINISH: "結單",
      },
      // API
      customerList: [],
      currentCustomerList: [],
      currentCustomerLevelItem: null,
      customerLevelList: [],
      currentCustomerLevelList: [],
      currentUserItem: null,
      userList: [],
      currentUserList: [],

      companyTitleList: [],
      currentCompanyTitleList: [],
      currentCompanyTitleItem: null,
      orderList: [],
      currentOrderList: [],
      currentOrderItem: null,
      currentNotFinishOrderList: [],
      currentFinishOrderList: [],
      tmpSelectComponentList: [],
      tmpSelectVehicleList: [],
      tmpComponentMap: null,
      tmpVehicleMap: null,

      // params
      currentCustomerItem: null,
      currentCustomerNo: "",
      currentCustomerCname: "",
      currentAccount: "",
      currentPassword: "",
      currentTaxIdNo: "",
      currentHead: "",
      currentContactPerson: "",
      currentPhone1: "",
      currentPhone2: "",
      currentPhone3: "",
      currentFax: "",
      currentPayPerson: "",
      currentRecivePerson: "",
      currentCompanyAddress: "",
      currentDeliveryAddress1: "",
      currentDeliveryAddress2: "",
      currentNote: "",
      currentSpecialNote: "",

      currentShipmentNo: "",
      currentPhone: "",
      currentShipmentDate: "",
      currentReceiveCustomer: "",
      currentClearanceDate: "",
      currentDeliveryAddress: "",
      currentDetp: "",
      currentOrderNo: "",
      currentInvoiceNo: "",
      currentTax: "",
      currentSales: "",
      currentDeliveryMethod: "",
      currentProductTypeList: ["零件", "完成車"],
      currentComponentTypeItem: null,
      currentProductList: [],
      currentProductItem: null,
      currentPreviewItem: null,
      currentProductQuantity: 1,
      currentUnitPrice: 1,

      //
      // search
      search: "",
      autoComplete: false,
    };
  },

  setup() {},
  mounted() {
    this.doGetCustomerList();
  },
  methods: {
    showOrderModal(item, modalTitle, isEdit) {
      this.orderModalTitle = modalTitle;
      this.doGetCompanyTitleList();

      if (item != "") {
        this.currentOrderItem = item;
        this.currentCompanyTitleItem = item.companyTitle;

        let customerId = item.customerId;
        this.doGetCustomerList(customerId);

        if (this.subId == "001-002") {
          this.doGetOrderList(item.id);
        }
        this.currentShipmentNo = item.shipmentNo;
        this.currentPhone = item.phone;
        this.currentShipmentDate = item.shipmentAt;
        this.currentReceiveCustomer = item.recivePerson;
        this.currentClearanceDate = item.clearanceAt;
        this.currentDeliveryAddress = item.deliveryAddress;
        this.currentPayPerson = item.payPerson;
        this.currentFax = item.fax;
        this.currentDetp = item.dept;
        this.currentOrderNo = item.orderNo;
        this.currentInvoiceNo = item.invoiceNo;
        this.currentTax = item.tax;
        this.currentSales = item.salesman;
        this.currentDeliveryMethod = item.deliveryMethod;
      }
    },
    showConfirmModal(customerItem, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      this.doGetCustomerLevelList(customerItem.customerLevelId);
      this.doGetUserList(customerItem.salesmanId);

      if (customerItem != "") {
        this.currentCustomerItem = customerItem;
        this.currentCustomerNo = customerItem.customerNo;
        this.currentAccount = customerItem.account;
        this.currentCustomerCname = customerItem.cname;
        this.currentTaxIdNo = customerItem.taxIdNo;
        this.currentHead = customerItem.head;
        this.currentContactPerson = customerItem.contactPerson;
        this.currentPhone1 = customerItem.phone1;
        this.currentPhone2 = customerItem.phone2;
        this.currentPhone3 = customerItem.phone3;
        this.currentFax = customerItem.fax;
        this.currentPayPerson = customerItem.payPerson;
        this.currentRecivePerson = customerItem.recivePerson;
        this.currentCompanyAddress = customerItem.companyAddress;
        this.currentDeliveryAddress1 = customerItem.deliveryAddress1;
        this.currentDeliveryAddress2 = customerItem.deliveryAddress2;
        this.currentNote = customerItem.note;
        this.currentSpecialNote = customerItem.specialNote;

        this.doGetOrderList("");
      }
    },
    resetConfirmModal() {
      this.currentCustomerItem = null;
      this.currentCustomerNo = "";
      this.currentAccount = "";
      this.currentPassword = "";
      this.currentCustomerLevelItem = null;
      this.currentUserItem = null;
      this.currentCustomerCname = "";
      this.currentTaxIdNo = "";
      this.currentHead = "";
      this.currentContactPerson = "";
      this.currentPhone1 = "";
      this.currentPhone2 = "";
      this.currentPhone3 = "";
      this.currentFax = "";
      this.currentPayPerson = "";
      this.currentRecivePerson = "";
      this.currentCompanyAddress = "";
      this.currentDeliveryAddress1 = "";
      this.currentDeliveryAddress2 = "";
      this.currentNote = "";
      this.currentSpecialNote = "";
    },
    getCustomerLevelItem(customerId) {
      for (var i = 0; i < this.currentCustomerLevelList.length; i++) {
        var customerObj = this.currentCustomerLevelList[i];
        if (customerId == customerObj.id) {
          this.currentCustomerLevelItem = customerObj;
          return;
        }
      }
    },
    getUserItem(userId) {
      for (var i = 0; i < this.currentUserList.length; i++) {
        var userObj = this.currentUserList[i];
        if (userId == userObj.id) {
          this.currentUserItem = userObj;
          return;
        }
      }
    },
    doGetCustomerList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerList = [];
            this.currentCustomerList = [];

            result = response.data;
            this.customerList = result.result["customerList"];
            this.currentCustomerList = this.customerList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewAndEditCustomer(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentCustomerNo == "") {
        Toast_Lib.errorToast("客戶編號不得為空");
        return;
      }

      if (this.currentAccount == "") {
        Toast_Lib.errorToast("帳號名稱不得為空");
        return;
      }

      if (!isEdit && this.currentPassword == "") {
        Toast_Lib.errorToast("密碼不得為空");
        return;
      }

      if (this.currentCustomerLevelItem == null) {
        Toast_Lib.errorToast("客戶分級不得為空");
        return;
      }

      if (this.currentUserItem == null) {
        Toast_Lib.errorToast("負責業務不得為空");
        return;
      }

      if (this.currentCustomerCname == "") {
        Toast_Lib.errorToast("客戶名稱不得為空");
        return;
      }

      if (this.currentTaxIdNo == "") {
        Toast_Lib.errorToast("統一編號不得為空");
        return;
      }

      if (this.currentHead == "") {
        Toast_Lib.errorToast("公司負責人不得為空");
        return;
      }

      if (this.currentContactPerson == "") {
        Toast_Lib.errorToast("聯絡人不得為空");
        return;
      }

      if (this.currentPhone1 == "") {
        Toast_Lib.errorToast("聯絡電話不得為空");
        return;
      }

      if (this.currentPayPerson == "") {
        Toast_Lib.errorToast("帳款客戶不得為空");
        return;
      }

      if (this.currentRecivePerson == "") {
        Toast_Lib.errorToast("收貨客戶不得為空");
        return;
      }

      if (this.currentCompanyAddress == "") {
        Toast_Lib.errorToast("公司地址不得為空");
        return;
      }

      if (this.currentDeliveryAddress1 == "") {
        Toast_Lib.errorToast("收貨地址不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newCustomer";
      var toastMsg = "新增客戶";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateCustomer";
        toastMsg = "編輯客戶";
      }

      // payload
      var jsonObj = {};

      if (!isEdit) {
        jsonObj.account = this.currentAccount;
        jsonObj.password = this.currentPassword;
        jsonObj.taxIdNo = this.currentTaxIdNo;
        jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      } else {
        jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
        jsonObj.customerId = this.currentCustomerItem.id;
      }

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.customerLevelId = this.currentCustomerLevelItem.id;
      jsonObj.salesmanId = this.currentUserItem.id;
      jsonObj.customerNo = this.currentCustomerNo;
      jsonObj.cname = this.currentCustomerCname;
      jsonObj.head = this.currentHead;
      jsonObj.contactPerson = this.currentContactPerson;
      jsonObj.phone1 = this.currentPhone1;
      jsonObj.phone2 = this.currentPhone2;
      jsonObj.phone3 = this.currentPhone3;
      jsonObj.fax = this.currentFax;
      jsonObj.companyAddress = this.currentCompanyAddress;
      jsonObj.deliveryAddress1 = this.currentDeliveryAddress1;
      jsonObj.deliveryAddress2 = this.currentDeliveryAddress2;
      jsonObj.payPerson = this.currentPayPerson;
      jsonObj.recivePerson = this.currentRecivePerson;
      jsonObj.note = this.currentNote;
      jsonObj.specialNote = this.currentSpecialNote;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetCustomerList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doChangePassword() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentPassword == "") {
        Toast_Lib.errorToast("密碼不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/changeCustomerPassword";

      var jsonObj = {};
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.customerId = this.currentCustomerItem.id;
      jsonObj.newPassword = this.currentPassword;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("變更密碼失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("變更密碼成功", "");

              this.doGetCustomerList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteCustomer() {
      var url = Common_Lib.getServerUrl() + "/deleteCustomer";

      var jsonObj = {};
      jsonObj.customerId = this.currentCustomerItem.id;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除客戶失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除客戶成功", "");

              this.doGetCustomerList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetOrderList(id) {
      var url = Common_Lib.getServerUrl() + "/getCustomerOrderList";
      let result = null;

      var jsonObj = {};
      jsonObj.customerId = this.currentCustomerItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.orderList = result.result["orderList"];
            this.currentOrderList = this.orderList;

            this.tmpComponentMap = result.result["tmpComponentMap"];
            this.tmpVehicleMap = result.result["tmpVehicleMap"];
            this.customerIdNameMap = result.result["customerIdNameMap"];

            this.currentNotFinishOrderList = [];
            this.currentFinishOrderList = [];
            for (let i = 0; i < this.currentOrderList.length; i++) {
              let obj = this.currentOrderList[i];
              if (obj.status != "FINISH") {
                this.currentNotFinishOrderList.push(obj);
              } else {
                this.currentFinishOrderList.push(obj);
              }
            }

            if (id != "") {
              this.tmpSelectComponentList = this.tmpComponentMap[id];
              this.tmpSelectVehicleList = this.tmpVehicleMap[id];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // get combobox list
    doGetCompanyTitleList(customerLevelId) {
      var url = Common_Lib.getServerUrl() + "/getCompanyTitleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.companyTitleList = [];
            this.currentCompanyTitleList = [];

            result = response.data;
            this.companyTitleList = result.result["companyTitleList"];
            this.currentCompanyTitleList = this.companyTitleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList(customerLevelId) {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerLevelList = [];
            this.currentCustomerLevelList = [];

            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;

            this.getCustomerLevelItem(customerLevelId);
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetUserList(salesmanId) {
      var url = Common_Lib.getServerUrl() + "/getUserList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.userList = [];
            this.currentUserList = [];

            result = response.data;
            this.userList = result.result["userList"];
            this.currentUserList = this.userList;

            this.getUserItem(salesmanId);
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // for search bar function
    clickSearchItem(customerItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "001-002") {
        modalTitle = "編輯";
        isEdit = true;
      } else {
        modalTitle = "確認刪除?";
        isEdit = false;
      }
      this.showConfirmModal(customerItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentCustomerList = [];
      if (this.search == "") {
        this.currentCustomerList = this.customerList;
      } else {
        for (var i = 0; i < this.customerList.length; i++) {
          var customerItem = this.customerList[i];
          if (customerItem["cname"].includes(this.search)) {
            this.currentCustomerList.push(customerItem);
          }
        }
      }
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.customerList.forEach((item) => {
        if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
