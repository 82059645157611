<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px">
                    <div class="offset-lg-8 col-lg-4 offset-5 col-7">
                      <div v-if="subId != '003-001'" style="">
                        人員編號:&nbsp;{{ currentEmployeeNo }}
                      </div>
                      <hr v-if="subId != '003-001'" />
                      <div v-if="subId != '003-001'">
                        入職日期:&nbsp;{{ currentStartWorkingDateFormat }}
                      </div>
                      <div v-if="subId != '003-001'">
                        累計在職時間:&nbsp;{{ currentWorkingPeriod }}
                      </div>
                      <div v-if="subId != '003-001'">
                        應有特休:&nbsp;{{ currentAnnualLeave }}
                      </div>
                      <div v-if="subId != '003-001'">
                        入職職稱:&nbsp;{{ currentFirstJobTitle }}
                      </div>
                      <div v-if="subId != '003-001'">
                        目前職稱:&nbsp;{{ currentJobTitle }}
                      </div>
                    </div>
                    <hr v-if="subId != '003-001'" />
                    <!--user name-->

                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-lg-6 col-12">
                          <label for="validationNewUserName3" class="form-label"
                            >員工名稱</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationNewUserName3"
                            :disabled="subId == '003-003'"
                            v-model="currentUser"
                            required
                          />
                          <div class="invalid-feedback">員工名稱不得為空</div>
                        </div>
                        <div class="col-lg-6 col-12">
                          <label for="validationNewJobTitle" class="form-label"
                            >職稱</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationNewJobTitle"
                            v-model="currentJobTitle"
                            :disabled="subId == '003-003' || subId == '003-004'"
                            required
                          />
                          <div class="invalid-feedback">職稱不得為空</div>
                        </div>
                        <div class="col-lg-6 col-12">
                          <label for="validationNewAccount2" class="form-label"
                            >帳號</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationNewAccount2"
                            :disabled="subId != '003-001'"
                            v-model="currentAccount"
                            required
                          />
                          <div class="invalid-feedback">帳號不得為空</div>
                        </div>
                        <div
                          v-if="subId == '003-001' || subId == '003-004'"
                          class="col-lg-6 col-12"
                        >
                          <label for="validationNewPassword3" class="form-label"
                            >密碼</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="validationNewPassword3"
                            v-model="currentPassword"
                            required
                          />
                          <div class="invalid-feedback">密碼不得為空</div>
                        </div>
                        <div v-if="subId == '003-001'" class="col-lg-6 col-12">
                          <label
                            for="validationNewStartWorkingDate"
                            class="form-label"
                            >入職日期</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="validationNewStartWorkingDate"
                            v-model="currentStartWorkingDate"
                            required
                          />
                          <div class="invalid-feedback">入職日期不得為空</div>
                        </div>
                      </form>

                      <div class="col-md-3">
                        <label for="validationRole" class="form-label"
                          >角色</label
                        >
                        <select
                          class="form-select"
                          id="validationRole"
                          :disabled="subId == '003-003' || subId == '003-004'"
                          v-model="currentRoleItem"
                          required
                        >
                          <option
                            v-for="(value, key) in currentRoleList"
                            :key="key"
                            :value="value"
                          >
                            <a class="dropdown-item">{{ value.roleCname }}</a>
                          </option>
                        </select>
                        <div class="invalid-feedback">請選擇角色</div>
                      </div>
                    </div>
                    <!--end of user name-->

                    <span v-if="subId != '003-004'"> 員工資料: </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="subId != '003-004'">
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationNewIdCardNo" class="form-label"
                        >身份證字號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewIdCardNo"
                        :disabled="subId != '003-001'"
                        v-model="currentIdCardNo"
                        required
                      />
                      <div class="invalid-feedback">身份證字號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewBirthday" class="form-label"
                        >出生日期</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        id="validationNewBirthday"
                        :disabled="subId != '003-001'"
                        v-model="currentBirthday"
                        required
                      />
                      <div class="invalid-feedback">出生日期不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone1" class="form-label"
                        >聯絡電話1</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone1"
                        :disabled="subId == '003-003'"
                        v-model="currentPhone1"
                        required
                      />
                      <div class="invalid-feedback">聯絡電話1不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone2" class="form-label"
                        >聯絡電話2</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone2"
                        :disabled="subId == '003-003'"
                        v-model="currentPhone2"
                      />
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationNewContactPerson" class="form-label"
                        >緊急聯絡人</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewContactPerson"
                        :disabled="subId == '003-003'"
                        v-model="currentContacePerson1"
                        required
                      />
                      <div class="invalid-feedback">緊急聯絡人不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationNewContactPersonPhone"
                        class="form-label"
                        >緊急聯絡人電話</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewContactPersonPhone"
                        :disabled="subId == '003-003'"
                        v-model="currentContacePerson1Phone"
                        required
                      />
                      <div class="invalid-feedback">緊急聯絡人電話不得為空</div>
                    </div>
                    <div class="col-12">
                      <label for="validationNewAddress" class="form-label"
                        >地址</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewAddress"
                        :disabled="subId == '003-003'"
                        v-model="currentAddress"
                        required
                      />
                      <div class="invalid-feedback">地址不得為空</div>
                    </div>
                    <div class="col-12">
                      <label for="validationNewEducation" class="form-label"
                        >最高學歷</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewEducation"
                        :disabled="subId == '003-003'"
                        v-model="currentEducation"
                        required
                      />
                      <div class="invalid-feedback">最高學歷不得為空</div>
                    </div>
                    <div class="col-12">
                      <label for="validationNewcertificate" class="form-label"
                        >證照及教育訓練</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewcertificate"
                        :disabled="subId == '003-003'"
                        v-model="currentCertificate"
                      />
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '003-001' ||
                      subId == '003-002' ||
                      subId == '003-003'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>電子簽名:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentSignatureSrcList"
                        :key="k"
                      >
                        <img
                          class="col-9"
                          style="width: 100px; height: 50px"
                          :src="src.currentSignatureSrc"
                        />
                        <span
                          class="col-2"
                          v-if="subId == '003-002'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteSignatureImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                    <!--                     
                    <img
                      v-if="currentSignature != null"
                      :src="currentSignature"
                      
                    /> -->
                  </div>
                  <div
                    v-if="subId == '003-001' || subId == '003-002'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addSignatureBtn"
                    >
                      上傳電子簽名
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteUserBtn"
              v-if="subId == '003-003'"
              @click="doDeleteUser()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '003-001'"
              @click="doNewAndEditUser(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '003-002'"
              @click="doNewAndEditUser(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-primary"
              id="changePasswordBtn"
              v-if="subId == '003-004'"
              @click="doChangePassword()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <!-- <SearchBar
      v-model="keyword"
      :clickSearchBtn="searchBtn"
      :roleList="roleList"
    ></SearchBar> -->
    <form
      v-if="subId != '003-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          ({{ item["employeeNo"] }})&ensp;{{ item["cname"] }}&ensp;({{
            item["phone1"]
          }})
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '003-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentUserList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  ({{ item["employeeNo"] }})&ensp;{{ item["cname"] }}&ensp;({{
                    item["phone1"]
                  }})
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '003-002'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '003-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '003-004'"
                @click="showConfirmModal(item, '變更密碼', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-key"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "員工名稱",
      nameColTitle: "員工名稱",
      confirmModalTitle: "",
      // API
      userList: [],
      currentUserList: [],
      isEdit: true,
      currentRoleList: [],

      // params
      currentEmployeeNo: "",
      currentFirstJobTitle: "",
      currentJobTitle: "",
      currentStartWorkingDate: "",
      currentStartWorkingDateFormat: "",
      currentWorkingPeriod: "",
      currentAnnualLeave: "",
      currentUser: "",
      currentUserId: "",
      currentAccount: "",
      currentPassword: "",
      currentRoleItem: "",
      currentIdCardNo: "",
      currentBirthday: "",
      currentPhone1: "",
      currentPhone2: "",
      currentContacePerson1: "",
      currentContacePerson1Phone: "",
      currentAddress: "",
      currentEducation: "",
      currentCertificate: "",
      currentFiles: null,
      currentSignatureSrcList: [],
      tmpDeleteImgList: [],

      //
      // search
      search: "",
      autoComplete: false,
    };
  },

  setup() {},
  mounted() {
    this.doGetUserList();
  },
  methods: {
    showConfirmModal(userItem, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.isEdit = isEdit;
      this.resetConfirmModal();
      this.doGetRoleList(userItem.roleId);
      if (userItem != "") {
        this.currentUserId = userItem.id;
        this.getExtraUserInfo();
        this.getUserSignatureFileObjs();
        this.currentUser = userItem.cname;
        this.currentAccount = userItem.account;
        this.currentEmployeeNo = userItem.employeeNo;
        this.currentFirstJobTitle = userItem.jobTitleFirst;
        this.currentJobTitle = userItem.jobTitleCurrent;
        this.currentIdCardNo = userItem.idCardNo;
        this.currentBirthday = userItem.birthday;
        this.currentPhone1 = userItem.phone1;
        this.currentPhone2 = userItem.phone2;
        this.currentContacePerson1 = userItem.contactPerson1;
        this.currentContacePerson1Phone = userItem.contactPerson1Phone;
        this.currentAddress = userItem.address;
        this.currentEducation = userItem.education;
        this.currentCertificate = userItem.certificate;
      }
    },
    resetConfirmModal() {
      this.currentUser = "";
      this.currentUserId = "";
      this.currentAccount = "";
      this.currentPassword = "";
      this.currentStartWorkingDate = "";
      this.currentEmployeeNo = "";
      this.currentFirstJobTitle = "";
      this.currentJobTitle = "";
      this.currentIdCardNo = "";
      this.currentBirthday = "";
      this.currentPhone1 = "";
      this.currentPhone2 = "";
      this.currentContacePerson1 = "";
      this.currentContacePerson1Phone = "";
      this.currentAddress = "";
      this.currentEducation = "";
      this.currentCertificate = "";
      this.currentFiles = null;
      this.currentSignatureSrcList = [];
      this.tmpDeleteImgList = [];
    },
    getRoleItem(roleId) {
      for (var i = 0; i < this.currentRoleList.length; i++) {
        var roleObj = this.currentRoleList[i];
        if (roleId == roleObj.id) {
          this.currentRoleItem = roleObj;
          return;
        }
      }
    },
    doGetRoleList(roleId) {
      var url = Common_Lib.getServerUrl() + "/getRoleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.roleList = [];
            this.currentRoleList = [];

            result = response.data;
            this.currentRoleList = result.result["roleList"];
            this.getRoleItem(roleId);
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewAndEditUser(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentUser == "") {
        Toast_Lib.errorToast("員工名稱不得為空");
        return;
      }

      if (this.currentJobTitle == "") {
        Toast_Lib.errorToast("職稱不得為空");
        return;
      }

      if (this.currentAccount == "") {
        Toast_Lib.errorToast("帳號名稱不得為空");
        return;
      }

      if (!isEdit && this.currentPassword == "") {
        Toast_Lib.errorToast("密碼不得為空");
        return;
      }

      if (!isEdit && this.currentStartWorkingDate == "") {
        Toast_Lib.errorToast("入職日期不得為空");
        return;
      }

      if (this.currentRoleItem == "") {
        Toast_Lib.errorToast("角色不得為空");
        return;
      }

      if (this.currentIdCardNo == "") {
        Toast_Lib.errorToast("身份證字號不得為空");
        return;
      }

      if (this.currentBirthday == "") {
        Toast_Lib.errorToast("生日不得為空");
        return;
      }

      if (this.currentPhone1 == "") {
        Toast_Lib.errorToast("聯絡電話1不得為空");
        return;
      }

      if (this.currentContacePerson1 == "") {
        Toast_Lib.errorToast("緊急聯絡人不得為空");
        return;
      }

      if (this.currentContacePerson1Phone == "") {
        Toast_Lib.errorToast("緊急聯絡人電話不得為空");
        return;
      }

      if (this.currentAddress == "") {
        Toast_Lib.errorToast("地址不得為空");
        return;
      }

      if (this.currentEducation == "") {
        Toast_Lib.errorToast("最高學歷不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newUser";
      var toastMsg = "新增員工";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateUser";
        toastMsg = "編輯員工";
      }

      // payload
      var jsonObj = {};
      jsonObj.files = this.currentFiles;

      const formData = new FormData();
      if (this.currentFiles != null) {
        for (var i = 0; i < this.currentFiles.length; i++) {
          formData.append("files", this.currentFiles[i]);
        }
      }

      if (isEdit) {
        formData.append("updateUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("userId", this.currentUserId);
        formData.append("deleteFileIds", this.tmpDeleteImgList);
      } else {
        formData.append("createUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("newUserCname", this.currentUser);
        formData.append("newAccount", this.currentAccount);
        formData.append("newPassword", this.currentPassword);
        formData.append("newStartWorkingDate", this.currentStartWorkingDate);
        formData.append("newIdCardNo", this.currentIdCardNo);
        formData.append("newBirthday", this.currentBirthday);
      }

      formData.append("modifyUserId", Common_Lib.getLocal("userId"));

      formData.append("newJobTitle", this.currentJobTitle);
      formData.append("newRoleId", this.currentRoleItem.id);
      formData.append("newPhone1", this.currentPhone1);
      formData.append("newPhone2", this.currentPhone2);
      formData.append("newContactPerson", this.currentContacePerson1);
      formData.append("newContactPersonPhone", this.currentContacePerson1Phone);
      formData.append("newAddress", this.currentAddress);
      formData.append("newEducation", this.currentEducation);
      formData.append("newCertificate", this.currentCertificate);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetUserList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doChangePassword() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentPassword == "") {
        Toast_Lib.errorToast("密碼不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/changeUserPassword";

      var jsonObj = {};
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = this.currentUserId;
      jsonObj.newPassword = this.currentPassword;
      var secretKey = "k" + Common_Lib.getLocal("token") + "k";
      jsonObj.roleId = Common_Lib.doDecrypt(
        Common_Lib.getLocal("roleId"),
        secretKey
      ).toString(this.$CryptoJS.enc.Utf8);

      jsonObj.modifyUserId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("變更密碼失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("變更密碼成功", "");

              this.doGetUserList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteUser() {
      var url = Common_Lib.getServerUrl() + "/deleteUser";

      var userId = localStorage.getItem("userId");
      if (userId == this.currentUserId) {
        Toast_Lib.errorToast("刪除角色失敗", "無法刪除自己");
        return;
      }
      var jsonObj = {};
      jsonObj.userId = this.currentUserId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.updateUserId = userId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除角色失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除角色成功", "");

              this.doGetUserList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    getExtraUserInfo() {
      var url = Common_Lib.getServerUrl() + "/getExtraUserInfo";
      var jsonObj = {};
      jsonObj.userId = this.currentUserId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得員工資料失敗", result.errorMsg);
              this.doGetUserList();
              $("#closeConfirmModalBtn").click();
            } else {
              result = response.data;

              this.currentStartWorkingDateFormat =
                result.result["formattedWorkingDate"];
              this.currentWorkingPeriod = result.result["workingPeriod"];
              this.currentAnnualLeave = result.result["annualLeave"];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    deleteSignatureImg(fileId) {
      this.tmpDeleteImgList.push(fileId);
      this.getUserSignatureFileObjs();
    },
    getUserSignatureFileObjs() {
      var url = Common_Lib.getServerUrl() + "/getUserSignatureFileObjs";
      var jsonObj = {};
      jsonObj.userId = this.currentUserId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(
                "取得使用者電子簽名檔案失敗",
                result.errorMsg
              );
            } else {
              result = response.data;

              this.currentSignatureSrcList = [];
              this.currentSignatureFileList =
                result.result["userSignatureFileObjs"];

              var fileIds = [];
              for (var i = 0; i < this.currentSignatureFileList.length; i++) {
                var fileObj = this.currentSignatureFileList[i];
                if (!this.tmpDeleteImgList.includes(fileObj.id)) {
                  fileIds.push(fileObj.id);
                }
              }
              this.getUserSignature(fileIds, 0);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    getUserSignature(fileIds, idx) {
      // 等一張完成再做下一張, 確保順序
      if (idx >= fileIds.length) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getFileResouce";
      var fileId = fileIds[idx];
      var jsonObj = {};
      jsonObj.fileId = fileId;

      axios
        .post(url, jsonObj, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得員工簽名失敗", result.errorMsg);
            } else {
              result = response.data;

              var data = new Uint8Array(result);
              // var raw = String.fromCharCode.apply(null, data);
              var raw = "";

              for (let i = 0, l = data.length; i < l; i++) {
                raw += String.fromCharCode(data[i]);
              }

              var base64 = btoa(raw);
              // if (base64 == "") {
              //   do nothing
              // }
              var src = "data:image/jpeg;base64," + base64;
              var tmpObj = {};
              tmpObj.fileId = fileId;
              tmpObj.currentSignatureSrc = src;
              this.currentSignatureSrcList.push(tmpObj);

              this.getUserSignature(fileIds, idx + 1);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetUserList() {
      var url = Common_Lib.getServerUrl() + "/getUserList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.userList = [];
            this.currentUserList = [];

            result = response.data;
            this.userList = result.result["userList"];
            this.currentUserList = this.userList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(userItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "003-002") {
        modalTitle = "編輯";
        isEdit = true;
      } else {
        modalTitle = "確認刪除?";
        isEdit = false;
      }
      this.showConfirmModal(userItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentUserList = [];
      if (this.search == "") {
        this.currentUserList = this.userList;
      } else {
        for (var i = 0; i < this.userList.length; i++) {
          var userItem = this.userList[i];
          if (
            userItem["cname"].includes(this.search) ||
            userItem["employeeNo"].includes(this.search) ||
            userItem["phone1"].includes(this.search)
          ) {
            this.currentUserList.push(userItem);
          }
        }
      }
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.userList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.cname.includes(searchKeyword) ||
            item.phone1.includes(searchKeyword) ||
            item.employeeNo.includes(searchKeyword))
        ) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
