<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px">
                    <!--customer info-->
                    <div
                      class="wrap-input100 validate-input"
                      v-if="isFromOrder"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-md-6 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >已覆核訂單</label
                          >
                          <select
                            class="form-select"
                            id="validationCustomer"
                            v-model="currentOrderItem"
                            @change="orderOnChange"
                            required
                          >
                            <option
                              v-for="(value, key) in currentOrderList"
                              :key="key"
                              :value="value"
                            >
                              <a class="dropdown-item"
                                >{{ value.orderNo }}_{{ value.companyTitle }}</a
                              >
                            </option>
                          </select>
                          <div class="invalid-feedback">請選擇訂單</div>
                        </div>
                        <div class="col-md-6 col-12"></div>
                        <div class="col-md-4 col-12">
                          <label for="validationCompanyTitle" class="form-label"
                            >公司抬頭</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationCompanyTitle"
                            v-model="currentCompanyTitle"
                            disabled="true"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label for="validationOrderNo" class="form-label"
                            >訂單單號</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationOrderNo"
                            disabled="true"
                            v-model="currentOrderNo"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label for="validationInvoiceNo" class="form-label"
                            >發票號碼</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationInvoiceNo"
                            disabled="true"
                            v-model="currentInvoiceNo"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label for="validationShipmentNo" class="form-label"
                            >出貨單號</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationShipmentNo"
                            v-model="currentShipmentNo"
                            disabled="true"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label for="validationShipmentDate" class="form-label"
                            >出貨日期</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="validationShipmentDate"
                            disabled="true"
                            v-model="currentShipmentDate"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label
                            for="validationClearanceDate"
                            class="form-label"
                            >結關日期</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="validationClearanceDate"
                            disabled="true"
                            v-model="currentClearanceDate"
                          />
                        </div>
                        <div class="col-lg-4 col-12">
                          <label for="validationAR" class="form-label"
                            >應收款項</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationAR"
                            v-model="currentAR"
                            disabled="true"
                          />
                        </div>
                      </form>
                    </div>
                    <!--end of customer name-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <div class="row">
                        <div class="row col-12">
                          <div class="col-lg-6 col-12">
                            <label for="validationAccuntDate" class="form-label"
                              >帳目日期</label
                            >
                            <input
                              type="date"
                              class="form-control"
                              id="validationAccuntDate"
                              v-model="currentAccuntDate"
                              required
                            />
                            <div class="invalid-feedback">請選擇帳目日期</div>
                          </div>
                          <div class="col-lg-6"></div>
                          <div class="col-md-6 col-12" style="margin-top: 20px">
                            <label for="validationAudit_A" class="form-label"
                              >會計科目1</label
                            >
                            <select
                              class="form-select"
                              id="validationAudit_A"
                              v-model="currentAudit_AItem"
                              @change="audit_AOnChange"
                              required
                            >
                              <option
                                v-for="(k, v) in currentAudit_AList['root']"
                                :key="v"
                                :value="k"
                              >
                                <a class="dropdown-item">{{ k.cname }}</a>
                              </option>
                            </select>
                            <div class="invalid-feedback">請選擇會計科目1</div>
                          </div>
                          <div class="col-md-6 col-12" style="margin-top: 20px">
                            <label for="validationAudit_B" class="form-label"
                              >會計科目2</label
                            >
                            <select
                              class="form-select"
                              id="validationAudit_B"
                              v-model="currentAudit_BItem"
                              @change="audit_BOnChange"
                              required
                            >
                              <option
                                v-for="(k, v) in currentAudit_BList"
                                :key="v"
                                :value="k"
                              >
                                <a class="dropdown-item">{{ k.cname }}</a>
                              </option>
                            </select>
                            <div class="invalid-feedback">請選擇會計科目2</div>
                          </div>
                          <div class="col-md-6 col-12" style="margin-top: 20px">
                            <label for="validationAudit_C" class="form-label"
                              >會計科目3</label
                            >
                            <select
                              class="form-select"
                              id="validationAudit_C"
                              v-model="currentAudit_CItem"
                              @change="audit_COnChange"
                              required
                            >
                              <option
                                v-for="(k, v) in currentAudit_CList"
                                :key="v"
                                :value="k"
                              >
                                <a class="dropdown-item">{{ k.cname }}</a>
                              </option>
                            </select>
                            <div class="invalid-feedback">請選擇會計科目3</div>
                          </div>
                          <div class="col-md-6 col-12" style="margin-top: 20px">
                            <label for="validationAudit_D" class="form-label"
                              >會計科目4</label
                            >
                            <select
                              class="form-select"
                              id="validationAudit_D"
                              v-model="currentAudit_DItem"
                              required
                            >
                              <option
                                v-for="(k, v) in currentAudit_DList"
                                :key="v"
                                :value="k"
                              >
                                <a class="dropdown-item">{{ k.cname }}</a>
                              </option>
                            </select>
                            <div class="invalid-feedback">請選擇會計科目4</div>
                          </div>
                          <div class="col-lg-4 col-12" style="margin-top: 20px">
                            <label for="validationValue" class="form-label"
                              >值</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationValue"
                              v-model="currentValue"
                              required
                            />
                            <div class="invalid-feedback">值不得為空</div>
                          </div>

                          <div class="col-lg-4 col-12" style="margin-top: 20px">
                            <label
                              for="validationDocumentType"
                              class="form-label"
                              >單據類別</label
                            >
                            <select
                              class="form-select"
                              id="validationDocumentType"
                              v-model="currentDocumentType"
                              required
                            >
                              <option
                                v-for="v in currentDocumentTypeList"
                                :key="v"
                              >
                                <a class="dropdown-item">{{ v }}</a>
                              </option>
                            </select>
                            <div class="invalid-feedback">請選擇單據類別</div>
                          </div>
                          <div class="col-lg-4 col-12" style="margin-top: 20px">
                            <label for="validationDocumentNo" class="form-label"
                              >單據編號</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationDocumentNo"
                              v-model="currentDocumentNo"
                              required
                            />
                            <div class="invalid-feedback">請選擇單據編號</div>
                          </div>
                          <div class="col-12" style="margin-top: 20px">
                            <label for="validationNote" class="form-label"
                              >備註</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="validationNote"
                              v-model="currentNote"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- confirm modal end -->
  <div v-if="subId == '017-001'" class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 18px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>日期</th>
            <th>科目1</th>
            <th>科目2</th>
            <th>科目3</th>
            <th>科目4</th>
            <th>值</th>
            <th>單據編號</th>
            <th>備註</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '017-001'"
                @click="showConfirmModal('', '新增帳目', true)"
              >
                <i class="fa-solid fa-plus"></i>&ensp;新增
              </button>
            </th>
            <th style="text-align: right; width: 165px">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '017-001'"
                @click="showConfirmModal('', '從訂單新增帳目', true)"
              >
                <i class="fa-solid fa-folder-open"></i>&ensp;從訂單新增
              </button>
            </th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>

  <div v-if="subId == '017-002'" class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>網路訂單日期</th>
            <th>客戶名稱</th>
            <th>訂單狀態</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentNetworkOrderList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createAt"].split(".")[0].replaceAll("T", " ") }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ customerIdNameMap[item["customerId"]] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ orderStausConvert[item["status"]] }}
                </div>
              </div>
            </td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                @click="showConfirmModal(item, '訂單新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-list-check"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      confirmModalTitle: "",
      isFromOrder: false,

      // API
      orderList: [],
      currentOrderList: [],
      currentOrderItem: null,
      customerIdNameMap: null,

      // params
      currentCustomerItem: null,
      currentCompanyTitle: "",
      currentAR: "",
      currentClearanceDate: "",
      currentShipmentDate: "",
      currentShipmentNo: "",
      currentInvoiceNo: "",
      currentOrderNo: "",
      orderTotalPriceMap: {},

      currentDocumentNo: "",
      currentNote: "",
      currentAccuntDate: "",
      currentValue: 0,
      currentDocumentType: "",

      currentDocumentTypeList: ["發票", "報關單", "請款單", "其他備註說明"],
      currentAudit_AItem: null,
      currentAudit_BItem: null,
      currentAudit_CItem: null,
      currentAudit_DItem: null,
      currentAudit_AList: [],
      currentAudit_BList: [],
      currentAudit_BMap: {},
      currentAudit_CList: [],
      currentAudit_CMap: {},
      currentAudit_DList: [],
      currentAudit_DMap: {},
      currentAuditCategoryMap: {},
      currentAuditCnameMap: {},

      //
      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchProductText: "",
      autoCompleteProduct: false,
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  mounted() {
    if (this.subId == "007-001") {
      //this.doGetFinishOrderList("");
    } else if (this.subId == "007-003") {
      this.doGetOrderListByTimeRange("");
    } else if (this.subId == "007-004") {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      this.currentStartDate = yyyy + "-" + mm + "-" + dd;
      this.currentEndDate = yyyy + "-" + mm + "-" + dd;
      this.doGetOrderListByTimeRange("");
    } else if (this.subId == "007-005") {
      this.doGetCustomerNetworkOrderList("");
    }
  },
  methods: {
    showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();
      if (modalTitle == "從訂單新增帳目") {
        this.isFromOrder = true;
      } else {
        this.isFromOrder = false;
      }
      if (this.subId == "017-001") {
        this.doGetFinishOrderList();
        this.doGetAuditCategory();
      }

      // this.doGetCompanyTitleList();
      // this.doGetCustomerList("");

      // this.doGetCustomerLevelList();
      // this.doGetUserList();
      // this.doGetComponentList();
      // this.doGetVehicleList();

      if (item != "") {
        this.currentOrderItem = item;
        this.currentCompanyTitleItem = item.companyTitle;

        let customerId = item.customerId;
        this.doGetCustomerList(customerId);

        if (this.subId == "007-001" || this.subId == "007-002") {
          this.doGetOrderList(item.id);
        } else if (this.subId == "007-003") {
          this.doGetOrderListByTimeRange(item.id);
        } else if (this.subId == "007-004") {
          this.doGetOrderListByTimeRange(item.id);
        } else if (this.subId == "007-005") {
          this.doGetCustomerNetworkOrderList(item.id);
          this.getCustomerItem(item.customerId);
        }

        if (this.subId != "007-005") {
          this.currentShipmentNo = item.shipmentNo;
          this.currentPhone = item.phone;
          this.currentShipmentDate = item.shipmentAt;
          this.currentReceiveCustomer = item.recivePerson;
          this.currentClearanceDate = item.clearanceAt;
          this.currentDeliveryAddress = item.deliveryAddress;
          this.currentPayPerson = item.payPerson;
          this.currentFax = item.fax;
          this.currentDetp = item.dept;
          this.currentOrderNo = item.orderNo;
          this.currentInvoiceNo = item.invoiceNo;
          this.currentTax = item.tax;
          this.currentSales = item.salesman;
          this.currentDeliveryMethod = item.deliveryMethod;
        }
      }
    },
    resetConfirmModal() {
      // this.currentShipmentNo = "";
      // this.currentPhone = "";
      // this.currentShipmentDate = "";
      // this.currentReceiveCustomer = "";
      // this.currentClearanceDate = "";
      // this.currentDeliveryAddress = "";
      // this.currentPayPerson = "";
      // this.currentFax = "";
      // this.currentDetp = "";
      // this.currentOrderNo = "";
      // this.currentInvoiceNo = "";
      // this.currentTax = "";
      // this.currentSales = "";
      // this.currentDeliveryMethod = "";
      // this.currentComponentTypeItem = null;
      // this.currentProductList = [];
      // this.currentProductItem = null;
      // this.currentPreviewItem = null;
      // this.currentProductQuantity = 1;
      // this.currentUnitPrice = 1;
      // this.tmpSelectComponentList = [];
      // this.tmpSelectVehicleList = [];
      // this.currentUserItem = null;
      // this.currentCustomerItem = null;
      // this.currentCompanyTitleItem = null;
    },
    orderOnChange() {
      this.currentCompanyTitle = this.currentOrderItem.companyTitle;

      this.currentAR = this.orderTotalPriceMap[this.currentOrderItem.id];
      this.currentClearanceDate = this.currentOrderItem.clearanceAt;
      this.currentShipmentDate = this.currentOrderItem.shipmentAt;
      this.currentShipmentNo = this.currentOrderItem.shipmentNo;
      this.currentInvoiceNo = this.currentOrderItem.invoiceNo;
      this.currentOrderNo = this.currentOrderItem.orderNo;
    },
    audit_AOnChange() {
      this.currentAudit_BList =
        this.currentAudit_BMap[this.currentAudit_AItem.id];
    },
    audit_BOnChange() {
      this.currentAudit_CList =
        this.currentAudit_CMap[this.currentAudit_BItem.id];
    },
    audit_COnChange() {
      this.currentAudit_DList =
        this.currentAudit_DMap[this.currentAudit_CItem.id];
    },

    customerOnChange() {
      if (this.currentCustomerItem != null) {
        this.currentFax = this.currentCustomerItem.fax;
        this.currentPhone = this.currentCustomerItem.phone1;
        this.currentReceiveCustomer = this.currentCustomerItem.recivePerson;
        this.currentPayPerson = this.currentCustomerItem.payPerson;
        this.currentDeliveryAddress = this.currentCustomerItem.deliveryAddress1;

        let customerLevelId = this.currentCustomerItem.customerLevelId;
        for (let i = 0; i < this.currentCustomerLevelList.length; i++) {
          let obj = this.currentCustomerLevelList[i];
          if (customerLevelId == obj.id) {
            this.currentTax = obj.tax;
            // this.currentDeliveryMethod = obj.payMethod;
          }
        }

        let salesId = this.currentCustomerItem.salesmanId;
        for (let i = 0; i < this.currentUserList.length; i++) {
          let obj = this.currentUserList[i];
          if (salesId == obj.id) {
            this.currentSales = obj.cname;
          }
        }

        this.doGetAllCustomerLevelPriceMap();
      }
    },
    componentTypeOnChange() {
      if (this.currentComponentTypeItem == "零件") {
        this.currentProductList = this.currentComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        this.currentProductList = this.currentVehicleList;
      }
    },

    getCustomerItem(id) {
      for (var i = 0; i < this.currentCustomerList.length; i++) {
        var obj = this.currentCustomerList[i];
        if (id == obj.id) {
          this.currentCustomerItem = obj;
          this.currentDeliveryAddress = obj.deliveryAddress1;
          this.currentPayPerson = obj.payPerson;
          this.currentReceiveCustomer = obj.recivePerson;
          this.currentFax = obj.fax;
          this.currentPhone = obj.phone1;
          this.getCustomerLevelItem(obj.customerLevelId);
          this.getUserItem(obj.salesmanId);
          return;
        }
      }
    },
    getCustomerLevelItem(id) {
      for (var i = 0; i < this.currentCustomerLevelList.length; i++) {
        var obj = this.currentCustomerLevelList[i];
        if (id == obj.id) {
          this.currentCustomerLevelItem = obj;
          this.currentTax = obj.tax;
          return;
        }
      }
    },

    getUserItem(id) {
      for (var i = 0; i < this.currentUserList.length; i++) {
        var obj = this.currentUserList[i];
        if (id == obj.id) {
          this.currentSales = obj.cname;
          return;
        }
      }
    },

    doGetFinishOrderList() {
      var url = Common_Lib.getServerUrl() + "/getFinishOrderList";
      let result = null;
      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.orderList = result.result["orderList"];
            this.currentOrderList = this.orderList;
            this.customerIdNameMap = result.result["customerIdNameMap"];
            this.orderTotalPriceMap = result.result["orderTotalPriceMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // get combobox list
    doGetCompanyTitleList(customerLevelId) {
      var url = Common_Lib.getServerUrl() + "/getCompanyTitleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.companyTitleList = [];
            this.currentCompanyTitleList = [];

            result = response.data;
            this.companyTitleList = result.result["companyTitleList"];
            this.currentCompanyTitleList = this.companyTitleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerList(id) {
      var url = Common_Lib.getServerUrl() + "/getCustomerList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerList = [];
            this.currentCustomerList = [];

            result = response.data;
            this.customerList = result.result["customerList"];
            this.currentCustomerList = this.customerList;

            if (id != "") {
              this.getCustomerItem(id);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetUserList() {
      var url = Common_Lib.getServerUrl() + "/getUserList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.userList = result.result["userList"];
            this.currentUserList = this.userList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            // this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetAllCustomerLevelPriceMap() {
      if (this.currentCustomerItem == null) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getAllCustomerLevelPriceMap";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerItem.customerLevelId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶分類價格失敗", result.errorMsg);
            } else {
              this.allCustomerLevelPriceMap =
                result.result["allCustomerLevelPriceMap"];
              if (this.currentProductItem != null) {
                this.currentUnitPrice =
                  this.allCustomerLevelPriceMap[this.currentProductItem.id];
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // audit
    doGetAuditCategory() {
      var url = Common_Lib.getServerUrl() + "/getAuditCategory";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentAuditCategoryMap = result.result["resultAuditCategory"];
            this.currentAuditCnameMap = result.result["auditCnameMap"];

            this.currentAudit_AList = this.currentAuditCategoryMap["1"];
            this.currentAudit_BMap = this.currentAuditCategoryMap["2"];
            this.currentAudit_CMap = this.currentAuditCategoryMap["3"];
            this.currentAudit_DMap = this.currentAuditCategoryMap["4"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // for search bar function
    clickSearchItem(customerItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "007-002") {
        modalTitle = "訂單編輯";
        isEdit = true;
      } else if (this.subId == "007-003" || this.subId == "007-004") {
        modalTitle = "訂單檢視";
        isEdit = false;
      }
      //  else {
      //   modalTitle = "確認刪除?";
      //   isEdit = false;
      // }
      this.showConfirmModal(customerItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentOrderList = [];
      if (this.search == "") {
        this.currentOrderList = this.orderList;
      } else {
        for (var i = 0; i < this.orderList.length; i++) {
          var orderItem = this.orderList[i];
          if (
            orderItem["companyTitle"].includes(this.search) ||
            orderItem["orderNo"].includes(this.search) ||
            this.customerIdNameMap[orderItem["customerId"]].includes(
              this.search
            )
          ) {
            this.currentOrderList.push(orderItem);
          }
        }
      }
    },
    clickSearchProduct(item) {
      this.currentProductItem = item;
      this.currentPreviewItem = item;
      this.autoCompleteProduct = false;
      this.searchProductText = "";
      this.doGetAllCustomerLevelPriceMap();
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      // if (this.searchProductText) {
      //   console.log("tt");
      //   this.autoCompleteProduct = true;
      // } else {
      //   console.log("ff");
      //   this.autoCompleteProduct = false;
      // }
    },
  },

  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
    searchProductText() {
      if (this.searchProductText) {
        this.autoCompleteProduct = true;
      } else {
        this.autoCompleteProduct = false;
      }
    },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.orderList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.companyTitle.includes(searchKeyword) ||
            item.orderNo.includes(searchKeyword) ||
            this.customerIdNameMap[item["customerId"]].includes(this.search))
        ) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchProductText;
      // 比對字串
      this.currentProductList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.cname.includes(searchKeyword) ||
            item.partNo.includes(searchKeyword))
        ) {
          arr.push(item);
        }
      });
      // if (arr.length > 0) {
      //   this.autoComplete = true;
      // } else {
      //   this.autoComplete = false;
      // }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
