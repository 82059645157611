<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead></thead>
            <tbody>
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-7">
                      <label for="validationVender8" class="form-label"
                        ><span v-if="subId == '006-001'"> 請選擇</span
                        >供應商</label
                      >
                      <select
                        class="form-select"
                        id="validationVende8r"
                        :disabled="subId != '008-001'"
                        v-model="currentVenderItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVenderList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                    </div>
                    <div v-if="subId != '008-001'" class="col-7">
                      <label for="validationCreateAt" class="form-label"
                        >建立日期</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationCreateAt"
                        :disabled="subId != '008-001'"
                        v-model="currentCreateAt"
                        required
                      />
                    </div>
                    <div class="col-7">
                      <label for="validationTitle" class="form-label"
                        >標題</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationTitle"
                        :disabled="subId != '008-001'"
                        v-model="currentTitle"
                        required
                      />
                      <div class="invalid-feedback">標題不得為空</div>
                    </div>
                    <div class="col-12">
                      <label for="validationFeedback" class="form-label"
                        >意見內容</label
                      >
                      <textarea
                        type="text"
                        class="form-control"
                        id="validationFeedback"
                        :disabled="subId != '008-001'"
                        v-model="currentFeedback"
                        required
                      ></textarea>
                      <div class="invalid-feedback">意見內容不得為空</div>
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '008-001' ||
                      subId == '008-002' ||
                      subId == '008-003'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>附件:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentFeedbackSrcList"
                        :key="k"
                      >
                        <img class="col-9 objImg" :src="src.currentPicSrc" />
                        <span
                          class="col-2"
                          v-if="subId == '008-001'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteCustomerFeedbackImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="subId == '008-001'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addFeedbackBtn"
                    >
                      上傳附件
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      multiple
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '008-001'"
              @click="doNewFeedback()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="subId != '008-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["title"] }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>建立者</th>
            <th>{{ nameColTitle }}</th>
            <th>建立日期</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '008-001'"
                @click="showConfirmModal('', '新增意見', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentCustomerFeedbackList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createUser"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["title"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createAt"].split(".")[0].replaceAll("T", " ") }}
                </div>
              </div>
            </td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '008-002' || subId == '008-003'"
                @click="showConfirmModal(item, '檢視意見', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const Common_API_Lib = require("../../js/Common_API_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "客戶意見",
      nameColTitle: "標題",
      confirmModalTitle: "",
      // API
      venderList: [],
      currentVenderList: [],
      customerFeedbackList: [],
      currentCustomerFeedbackList: [],
      currentVenderItem: null,
      currentTitle: "",
      currentFeedback: "",
      currentCreateAt: "",

      // params
      currentCustomerFeedbackItem: null,

      // pic
      currentFiles: null,
      currentFeedbackSrcList: [],
      currentFeedbackFileList: [],
      tmpDeleteFeedbackImgList: [],

      // search
      search: "",
      autoComplete: false,
    };
  },

  setup() {},
  async mounted() {
    if (this.subId == "008-001" || this.subId == "008-002") {
      this.doGetFeedbackList();
    } else if (this.subId == "008-003") {
      this.doGetTodayFeedbackList();
    }
  },
  methods: {
    async showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      // this.resetConfirmModal();
      // get vender
      let [venderList, venderIdNameMap] =
        await Common_API_Lib.handleVenderObjs();

      this.currentVenderList = venderList;

      if (item != "") {
        this.currentCustomerFeedbackItem = item;
        this.currentTitle = item.title;
        this.currentFeedback = item.feedback;
        this.currentCreateAt = item.createAt;

        let res = await this.getVenderObj();
        Common_API_Lib.checkResp(res, "取得供應商資訊");
        this.currentVenderItem = res.data.result["vendersObj"];

        // pic
        // get file objs
        // 1. get fileList by objId
        var fileIds = await Common_API_Lib.handleFileObjs(
          item.id,
          this.tmpDeleteFeedbackImgList,
          "feedback"
        );

        // 2. get file resouce
        let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
          fileIds
        );

        this.src = respSrc;
        this.currentFeedbackSrcList = respPicSrcList;
      }
    },
    resetConfirmModal() {
      this.currentVenderItem = null;
    },
    doGetTodayFeedbackList() {
      var url = Common_Lib.getServerUrl() + "/getTodayCustomerFeedbackList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.customerFeedbackList =
              result.result["todayCustomerFeedbackList"];
            this.currentCustomerFeedbackList = this.customerFeedbackList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetFeedbackList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerFeedbackList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.customerFeedbackList = result.result["customerFeedbackList"];
            this.currentCustomerFeedbackList = this.customerFeedbackList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewFeedback() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentVenderItem == null) {
        Toast_Lib.errorToast("供應商不得為空");
        return;
      }

      if (this.currentTitle == "") {
        Toast_Lib.errorToast("標題不得為空");
        return;
      }

      if (this.currentFeedback == "") {
        Toast_Lib.errorToast("意見不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newCustomerFeedback";
      var toastMsg = "新增意見";

      // payload
      const formData = new FormData();
      if (this.currentFiles != null) {
        for (var i = 0; i < this.currentFiles.length; i++) {
          formData.append("files", this.currentFiles[i]);
        }
      }

      formData.append("createUser", Common_Lib.getLocalAndDecrypt("account"));
      formData.append("venderId", this.currentVenderItem.id);
      formData.append("title", this.currentTitle);

      formData.append("feedback", this.currentFeedback);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetFeedbackList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    getVenderObj() {
      var url = Common_Lib.getServerUrl() + "/getVenderObj";
      var jsonObj = {};
      jsonObj.venderId = this.currentCustomerFeedbackItem.venderId;

      return new Promise((resolve, reject) => {
        axios
          .post(url, jsonObj)
          .then((response) => {
            if (response.status != 200) {
              var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
              Toast_Lib.errorToast("連接錯誤", msg);
            } else {
              resolve(response);
            }
          })
          .catch((err) => {
            // axios的錯誤都會跑到這邊
            Toast_Lib.errorToast("錯誤", err);
          });
      });
    },

    async deleteCustomerFeedbackImg(fileId) {
      this.tmpDeleteImgList.push(fileId);
      // 1. get fileList by objId
      var fileIds = await Common_API_Lib.handleFileObjs(
        this.currentCustomerFeedbackItem.id,
        this.tmpDeleteFeedbackImgList,
        "feedback"
      );

      // 2. get file resouce
      let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
        fileIds
      );

      this.src = respSrc;
      this.currentCustomerFeedbackFileList = respPicSrcList;
    },

    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(item) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      modalTitle = "檢視意見";
      isEdit = true;
      this.showConfirmModal(item, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentCustomerFeedbackList = [];
      if (this.search == "") {
        this.currentCustomerFeedbackList = this.customerFeedbackList;
      } else {
        for (var i = 0; i < this.customerFeedbackList.length; i++) {
          var item = this.customerFeedbackList[i];
          if (item["title"].includes(this.search)) {
            this.currentCustomerFeedbackList.push(item);
          }
        }
      }
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.customerFeedbackList.forEach((item) => {
        if (searchKeyword != "" && item.title.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
