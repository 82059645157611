<template>
  <!-- copy vehicle modal -->
  <div
    class="modal fade"
    id="userProfileModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            變更密碼
          </h4>
          <span
            id="closeUserProfileBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center">
                  <div
                    class="wrap-input100 validate-input"
                    style="padding-bottom: 20px"
                  >
                    <form
                      class="row g-3 needs-validation"
                      style="margin-bottom: 20px"
                      novalidate
                    >
                      <div class="col-lg-6 col-12">
                        <label for="validationNewUserName" class="form-label"
                          >用戶名稱</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="validationNewUserName"
                          disabled="true"
                          v-model="currentUser"
                        />
                      </div>
                      <div class="col-lg-6 col-12">
                        <label for="validationNewAccount" class="form-label"
                          >帳號</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="validationNewAccount"
                          disabled="true"
                          v-model="currentAccount"
                        />
                      </div>
                      <hr />
                      <div class="col-lg-7 col-12">
                        <label for="validationOldPassword" class="form-label"
                          >請輸入舊密碼</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="validationOldPassword"
                          v-model="oldPassword"
                          required
                        />
                        <div class="invalid-feedback">舊密碼不得為空</div>
                      </div>
                      <div class="col-lg-7 col-12">
                        <label for="validationNewPassword" class="form-label"
                          >請輸入新密碼</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="validationNewPassword"
                          v-model="newPassword"
                          required
                        />
                        <div class="invalid-feedback">新密碼不得為空</div>
                      </div>
                    </form>
                  </div>
                  <div
                    class="col-9 mx-3 setCenter"
                    style="
                      padding: 20px;
                      margin-left: 28% !important ;
                      justify-content: right;
                    "
                  >
                    <button
                      class="btn btn-outline-success"
                      id="updateUserProfileBtn"
                      @click="doChangePassword()"
                    >
                      <i class="fa-solid fa-copy"></i>&ensp;確定變更
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      id="cancelBtn"
                      data-bs-dismiss="modal"
                      style="margin-left: 10px"
                    >
                      <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <nav id="nav" class="nav row" style="padding: 0px; margin: 0px">
    <div class="row" style="margin: 0px">
      <div class="col-lg-cust-9 col-12 menuBar">
        <div
          class="setCenter col-3"
          style="width: 220px !important; height: 85px !important"
        >
          <div class="setCenter logoDiv">
            &nbsp;<i class="fa-solid fa-cube"></i>&nbsp;威勝ERP系統
          </div>
        </div>
        <div
          class="item setCenter"
          style="background-color: #f5f5f5 !important"
        >
          <router-link
            to="/dashboard"
            style="text-align: center"
            @click="handleHamburger"
            >主頁</router-link
          >
        </div>
        <div
          class="item setCenter"
          style="background-color: #f5f5f5 !important"
          v-if="mainText != ''"
        >
          <router-link
            :to="mainRouteTo"
            style="text-align: center"
            @click="handleHamburger"
            >{{ mainText }}</router-link
          >
        </div>
        <div
          class="item setCenter"
          style="background-color: #f5f5f5 !important"
          v-if="subText != ''"
        >
          <router-link
            :to="subRouteTo"
            style="text-align: center"
            @click="handleHamburger"
            >{{ subText }}</router-link
          >
        </div>
      </div>
      <div
        class="col-lg-cust-3 col-12"
        style="
          background-color: #f5f5f5;
          color: #000;
          font-size: 18px;
          height: 85px;
          display: flex;
          align-items: center;
          justify-content: right;
        "
      >
        <button
          class="btn btn-primary"
          style="margin-right: 10px"
          id="userProfileBtn"
          data-bs-toggle="modal"
          data-bs-target="#userProfileModal"
          @click="showChangePasswordModal"
        >
          <i class="fa-solid fa-user"></i>&nbsp;{{ cname }}&nbsp;&nbsp;
        </button>
        <button class="btn btn-secondary" @click="doLogout">登出</button>
      </div>
    </div>
  </nav>
  <div class="loginHeader">
    <div class="setCenter smallSizeLogo col-3" style="width: 220px !important">
      <div
        class="setCenter logoDiv"
        style="padding-right: 0px; padding-left: 10px; color: #000"
      >
        &nbsp;<i class="fa-solid fa-cube"></i>&nbsp;威勝ERP系統
      </div>
    </div>
    <div class="hamburger" @click="handleHamburger" style="z-index: 1000">
      <span id="line1"></span>
      <span id="line2"></span>
      <span id="line3"></span>
    </div>
  </div>
</template>

<script>
var myID = "Header";
import $ from "jquery";
const Toast_Lib = require("../js/Toast_Lib");
const Common_Lib = require("../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  data() {
    return {
      serverUrl: Common_Lib.getServerUrl(),
      role: "",
      cname: "",
      hardwarePermission: "",
      // header
      currentPath: [],
      mainText: "",
      mainRouteTo: "/dashboard",
      subText: "",
      subRouteTo: "/dashboard",
      permissionIdNameMap: [],
      // user profile
      currentUser: "",
      currentAccount: "",
      oldPassword: "",
      newPassword: "",
      currentRoldId: "",
    };
  },

  setup() {},
  mounted() {
    // header text
    this.doGetPermissionItemList();

    // user info
    var secretKey = "k" + Common_Lib.getLocal("token") + "k";
    this.role = this.$CryptoJS.AES.decrypt(
      Common_Lib.getLocal("role"),
      secretKey
    ).toString(this.$CryptoJS.enc.Utf8);
    this.cname = this.$CryptoJS.AES.decrypt(
      Common_Lib.getLocal("cname"),
      secretKey
    ).toString(this.$CryptoJS.enc.Utf8);
    this.hardwarePermission = this.$CryptoJS.AES.decrypt(
      Common_Lib.getLocal("hardwarePermission"),
      secretKey
    ).toString(this.$CryptoJS.enc.Utf8);

    // user profile
    this.currentUser = this.cname;
    this.currentAccount = this.hardwarePermission = this.$CryptoJS.AES.decrypt(
      Common_Lib.getLocal("account"),
      secretKey
    ).toString(this.$CryptoJS.enc.Utf8);
    this.currentRoldId = this.$CryptoJS.AES.decrypt(
      Common_Lib.getLocal("roleId"),
      secretKey
    ).toString(this.$CryptoJS.enc.Utf8);
  },
  methods: {
    handleHamburger() {
      if (window.innerWidth <= 1082) {
        document.getElementById("nav").classList.toggle("toggleHamburger");
        document.getElementById("line1").classList.toggle("line1Close");
        document.getElementById("line2").classList.toggle("line2Close");
        document.getElementById("line3").classList.toggle("line3Close");
        //document.getElementById("logoBar").classList.toggle("showLogoBar");
      }
    },
    doLogout() {
      this.$router.push("/");
    },
    showChangePasswordModal() {
      this.newPassword = "";
      this.oldPassword = "";
    },
    doChangePassword() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.oldPassword == "") {
        Toast_Lib.errorToast("舊密碼不得為空");
        return;
      }
      if (this.newPassword == "") {
        Toast_Lib.errorToast("新密碼不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/changeUserPassword";
      var jsonObj = {};
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.oldPassword = this.oldPassword;
      jsonObj.newPassword = this.newPassword;
      jsonObj.roleId = this.currentRoldId;
      jsonObj.modifyUserId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("變更密碼失敗", result.errorMsg);
              $("#closeUserProfileBtn").click();
            } else {
              Toast_Lib.infoToast("變更密碼成功", "");

              $("#closeUserProfileBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetPermissionItemList() {
      var url = Common_Lib.getServerUrl() + "/getPermissionItemList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得權限列表失敗", result.errorMsg);
            } else {
              this.permissionIdNameMap = result.result["permissionIdNameMap"];
              var currentPathList = window.location.pathname.split("/");
              var l = currentPathList.length;
              var tmpStr = this.permissionIdNameMap[currentPathList[l - 2]];
              if (tmpStr === undefined) {
                this.mainText =
                  this.permissionIdNameMap[currentPathList[l - 1]];
                this.mainRouteTo = "/subFunction/" + currentPathList[l - 1];
                if (this.mainText === undefined) {
                  this.mainText = "";
                  this.mainRouteTo = "/dashboard";
                }
                this.subText = "";
                this.subRouteTo = "/dashboard";
              } else {
                this.mainText = tmpStr;
                this.mainRouteTo = "/subFunction/" + currentPathList[l - 2];
                this.subText = this.permissionIdNameMap[currentPathList[l - 1]];
                this.subRouteTo =
                  "/functionPage/" +
                  currentPathList[l - 2] +
                  "/" +
                  currentPathList[l - 1];
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
  },
  computer: {},
};
</script>

<style>
@media (max-width: 769px) {
  .logoDiv {
    font-size: 22px;
    font-weight: bold;
    width: 220px;
    height: 85px;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .logoDiv {
    font-size: 26px;
    font-weight: bold;
    width: 220px;
    height: 85px;
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
}

@media screen and (min-width: 1082) {
  .hamburger {
    display: none !important;
  }
  .loginHeader {
    display: none !important;
  }
  .nav {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: 85px !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 3;
    margin-top: 0px !important;
    /* background-color: #fff; */
    background-color: #f5f5f5;
    transition: right 0.3s;
    border-top: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
  }
  .nav .item {
    border-bottom: 0px #d0d0d0 solid !important;
    width: 150px !important;
    height: 85px !important;
    float: left;
  }
  .nav .item:hover {
    /* background-color: #fff !important ; */
    background-color: #f5f5f5 !important ;
    border-top: 3px solid #548dd4 !important;
  }
  .nav a {
    padding: 15px 10px 10px 10px !important;
  }
  .nav span {
    padding: 15px 10px 10px 10px !important;
  }

  /*下拉選單*/
  .nav .itemGroup {
    position: relative;
  }
  .nav span:hover {
    display: block !important;
  }
  .nav > a {
    background-color: rgba(62, 71, 84, 0.9) !important;
    width: 200px;
    border-bottom: 1px #ccc solid !important;
    margin-right: 20px;
    color: #fff !important;
  }
  .line {
    border-left: 1px solid red;
  }
} /*end rwd 991*/

/*nav*/
.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  position: fixed;
  top: 0;
  right: -992px;
  width: 100%;
  z-index: 3;
  margin-top: 60px;
  /* background-color: #fff; */
  background-color: #f5f5f5 !important ;
  transition: right 0.3s;
  border-top: 2px solid #548dd4;
  box-shadow: 3px 3px 4px 6px #cccccc;
  opacity: 0;
}
.nav .item {
  border-bottom: 1px #d0d0d0 solid;
  width: 100%;
}
.nav .item {
  /*background-color: #CE0000 ;*/
  /* background-color: #fff; */
  background-color: #f5f5f5 !important ;
}

.nav .item > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  /* background-color: #fff; */
  background-color: #f5f5f5 !important ;
}
.nav .item > a:hover {
  color: #548dd4;
}
.nav > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 400;
  font-size: 18px;
  /* background-color: #fff; */
  background-color: #f5f5f5 !important ;
}
.nav .item :hover {
  color: #548dd4;
}
.nav a:hover {
  color: #548dd4;
}
.nav span:hover {
  cursor: pointer;
}

.nav .item span {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding: 10px;
  /*position: relative;*/
}
nav .item span {
  display: none;
}

.hamburger:hover {
  cursor: pointer;
}
.toggleHamburger {
  right: 0;
  opacity: 1;
}

#line1 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line1Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(11px) rotate(45deg);
}

#line2 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line2Close {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
#line3 {
  display: block;
  width: 80%;
  height: 3px;
  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line3Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(-9px) rotate(-45deg);
}
.showLogoBar {
  display: none;
  z-index: -1;
}
.menuBar {
  display: flex;
  justify-content: left !important;
}
.removeHrefStyle {
  color: #000;
  text-decoration: none !important;
}
.logoBar {
  position: absolute;
  padding: 0px;
  height: 80px;
  margin-top: 5px;
}

@media (max-width: 1082px) {
  .logoDiv {
    display: none;
    height: 100%;
  }
  .menuBar {
    display: block;
  }
  .logoBar {
    position: absolute;
    padding: 0px;
    height: 80%;
    margin-top: -2px;
  }
}
@media (max-width: 1082px) {
  .hamburger {
    background-color: #f5f5f5 !important ;
  }
}

@media (min-width: 1082px) {
  .col-lg-cust-3 .col-cust-9 {
    float: center;
  }
  .col-lg-cust-3 {
    width: 25% !important;
    justify-content: right !important;
  }
  .col-lg-cust-9 {
    width: 75% !important;
  }
  .smallSizeLogo {
    display: none !important;
    height: 85px !important;
  }
}
@media (max-width: 1082px) {
  .col-12 {
    justify-content: center !important;
  }
  .smallSizeLogo {
    display: flex !important;
    height: 85px !important;
    padding-top: 3px;
    padding-left: 13px;
  }
}
</style>
