import { createRouter, createWebHistory } from "vue-router";
import ChangePw from "../components/ChangePw.vue";
import Login from "../components/Login.vue";
import Dashboard from "../components/Dashboard.vue";
import SubFunctionList from "../components/SubFunctionList.vue";
import FunctionPage from "../components/FunctionPage.vue";
// import Settings from "../components/Settings.vue";
// import Account from "../components/Account.vue";
// import FilePathManager from "../components/FilePathManager.vue";

const routes = [
  {
    path: "/changePw",
    component: ChangePw,
  },
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/subFunction/:mainId",
    name: "subFunctionList",
    component: SubFunctionList,
    props: true,
  },
  {
    path: "/functionPage/:mainId/:subId",
    name: "functionPage",
    component: FunctionPage,
    props: true,
  },
  // {
  //   path: "/organize",
  //   component: Organize,
  // }, // 串檔
  // {
  //   path: "/settings",
  //   component: Settings,
  // }, // 工程設定
  // {
  //   path: "/account",
  //   component: Account,
  // }, // 帳戶權限管理
  // {
  //   path: "/filePathManager",
  //   component: FilePathManager,
  // }, // 檔案路徑管理
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/translation",
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
