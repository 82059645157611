<template>
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="subId == '016-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->
      <div class="row" style="padding: 10px 5px">
        <form
          class="row g-3 needs-validation"
          style="margin-bottom: 20px"
          novalidate
        >
          <div class="row">
            <div class="col-md-4" style="margin: 10px 0px">
              <label
                for="validationVender"
                class="form-label"
                style="font-weight: bold"
                >選擇客戶</label
              >

              <form
                class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
              >
                <!--     這裡是搜尋列 -->
                <div class="input-group w-md-50 me-2">
                  <input
                    class="form-control"
                    style="margin-bottom: 10px"
                    type="search"
                    v-model.trim="searchCustomer"
                    @keyup="keyboardEvent"
                    placeholder="快速搜尋"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                  />
                </div>
                <!--     這裡是搜尋列 -->

                <!--     這裡才是autoComplete的選單 -->
                <!--     控制autoComplete的開闔 -->
                <ul
                  class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
                  :class="autoCompleteForCustomer ? '' : 'd-none'"
                >
                  <!--     控制按鈕事件的選取背景 -->
                  <li
                    class="searchHover p-2 w-100"
                    v-for="item in filterCustomers"
                    :key="item"
                    @click="clickCustomerItem(item)"
                  >
                    {{ item["cname"] }}
                  </li>
                </ul>
                <!--     這裡才是autoComplete的選單 -->
              </form>
              <select
                class="form-select"
                v-model="currentCustomerItem"
                @change="customerChange"
              >
                <option
                  v-for="(value, key) in currentCustomerList"
                  :key="key"
                  :value="value"
                >
                  <a class="dropdown-item">{{ value.cname }}</a>
                </option>
              </select>
            </div>
            <div class="col-6">
              <div class="col-12" style="margin: 10px 0px">
                <span
                  class="w-100 p-b-20"
                  style="font-weight: bold; color: #666"
                >
                  客戶等級
                </span>
              </div>
              <div class="col-8">
                <select
                  class="form-select"
                  id="validationCopyVehicle"
                  v-if="subId == '016-001'"
                  v-model="currentCustomerLevelItem"
                  @change="customerLevelChange"
                >
                  <option
                    v-for="(value, key) in currentCustomerLevelList"
                    :key="key"
                    :value="value"
                  >
                    <a class="dropdown-item">{{ value.customerLevelCname }}</a>
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </form>

    <!-- table content of component(001)-->
    <div
      v-if="subId == '016-001'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold; text-align: center">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th>供應商</th>
            <th>料號</th>
            <th>型號</th>
            <th>顏色</th>
            <th>安全庫存</th>
            <th>現在庫存</th>
            <th>價錢</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentComponentList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ venderIdNameMap[item["venderId"]] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["partNo"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["model"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["color"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["safetyStock"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  <span
                    :class="item.stock < item.safetyStock ? 'redFont' : ''"
                    >{{ item["stock"] }}</span
                  >
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  <span v-if="allCustomerLevelPriceMap != null">
                    {{ allCustomerLevelPriceMap[item["id"]] }}</span
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of component(001)-->
  </div>
</template>

<script>
import $ from "jquery";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const Common_API_Lib = require("../../js/Common_API_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "零件名稱",
      nameColTitle: "零件名稱",
      confirmModalTitle: "",

      // API
      currentCustomerLevelItem: null,
      componentList: [],
      currentComponentList: [],
      venderList: [],
      venderIdNameMap: {},
      currentVenderList: [],
      currentVenderItem: null,
      customerLevelList: [],
      currentCustomerLevelList: [],
      componentTypeMap: {},
      customerList: [],
      currentCustomerList: [],
      currentCustomerItem: null,

      // params
      // components
      currentComponentId: "",
      currentVenderId: "",
      allCustomerLevelPriceMap: null,

      // search
      search: "",
      autoComplete: false,
      searchCustomer: "",
      autoCompleteForCustomer: false,
      searchComponentMap: {},
      autoCompleteMap: {},
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  async mounted() {
    this.doGetComponentList();
    this.doGetCustomerList();
    if (this.subId == "016-001") {
      this.doGetCustomerLevelList();

      // get vender
      let [venderList, venderIdNameMap] =
        await Common_API_Lib.handleVenderObjs();

      this.currentVenderList = venderList;
      this.venderIdNameMap = venderIdNameMap;

      // get customerLevelPriceMap
      this.doGetAllCustomerLevelPriceMap();
    }
  },
  methods: {
    // CRUD
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.componentList = [];
            this.currentComponentList = [];

            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.componentTypeMap = {};
            this.searchComponentMap = {};
            this.autoCompleteMap = {};
            this.tmpAddedComponentMap = {};
            this.vehicleComponentsCountMap = {};

            for (var i = 0; i < this.currentComponentList.length; i++) {
              var componentObj = this.currentComponentList[i];
              var componentType = componentObj.componentType;
              if (!(componentType in this.componentTypeMap)) {
                this.componentTypeMap[componentType] = [];
              }
              this.componentTypeMap[componentType].push(componentObj);
              this.searchComponentMap[componentType] = "";
              this.autoCompleteMap[componentType] = false;
              this.tmpAddedComponentMap[componentType] = [];
              this.vehicleComponentsCountMap[componentObj.id] = 1;
            }

            this.componentIdVehicleMap = result.result["componentIdVehicleMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // advance data (001-003)
    getVenderObj() {
      var url = Common_Lib.getServerUrl() + "/getVenderObj";
      var jsonObj = {};
      jsonObj.venderId = this.currentVenderId;

      return new Promise((resolve, reject) => {
        axios
          .post(url, jsonObj)
          .then((response) => {
            if (response.status != 200) {
              var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
              Toast_Lib.errorToast("連接錯誤", msg);
            } else {
              resolve(response);
            }
          })
          .catch((err) => {
            // axios的錯誤都會跑到這邊
            Toast_Lib.errorToast("錯誤", err);
          });
      });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerLevelList = [];
            this.currentCustomerLevelList = [];

            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetAllCustomerLevelPriceMap() {
      if (this.currentCustomerLevelItem == null) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getAllCustomerLevelPriceMap";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerLevelItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶分類價格失敗", result.errorMsg);
            } else {
              this.allCustomerLevelPriceMap =
                result.result["allCustomerLevelPriceMap"];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerList = [];
            this.currentCustomerList = [];

            result = response.data;
            this.customerList = result.result["customerList"];
            this.currentCustomerList = this.customerList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // end of (001-003) advance data
    customerLevelChange() {
      this.doGetAllCustomerLevelPriceMap();
      this.currentCustomerItem = null;
    },
    customerChange() {
      for (let i = 0; i < this.currentCustomerLevelList.length; i++) {
        let customerLevelObj = this.currentCustomerLevelList[i];
        if (customerLevelObj.id == this.currentCustomerItem.customerLevelId) {
          this.currentCustomerLevelItem = customerLevelObj;
          break;
        }
      }
      this.doGetAllCustomerLevelPriceMap();
    },

    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(componentItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "002-002") {
        modalTitle = "編輯";
        isEdit = true;
      } else {
        modalTitle = "確認刪除?";
        isEdit = false;
      }
      this.showConfirmModal(componentItem, modalTitle, isEdit);
    },
    searchBtn() {
      if (this.subId == "016-001") {
        this.currentComponentList = [];
        if (this.search == "") {
          this.currentComponentList = this.componentList;
        } else {
          for (var i = 0; i < this.componentList.length; i++) {
            var componentItem = this.componentList[i];
            if (
              componentItem["cname"].includes(this.search) ||
              componentItem["partNo"].includes(this.search)
            ) {
              this.currentComponentList.push(componentItem);
            }
          }
        }
      }
    },
    clickSearchComponent(t) {
      this.currentSearchColumn = t;
      this.autoCompleteMap = {};
      for (var i = 0; i < this.currentComponentList.length; i++) {
        this.autoCompleteMap[this.currentComponentList.componentType] = false;
      }
      this.autoCompleteMap[t] = true;
    },
    clickCustomerItem(item) {
      this.currentCustomerItem = item;
      this.customerChange();
      this.searchCustomer = "";
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    searchCustomer() {
      if (this.searchCustomer) {
        this.autoCompleteForCustomer = true;
      } else {
        this.autoCompleteForCustomer = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    // filterProducts() {
    //   const arr = [];
    //   var searchKeyword = this.search;
    //   // 比對字串
    //   if (this.subId == "016-001") {
    //     this.componentList.forEach((item) => {
    //       if (
    //         (searchKeyword != "" && item.cname.includes(searchKeyword)) ||
    //         item.partNo.includes(searchKeyword)
    //       ) {
    //         arr.push(item);
    //       }
    //     });
    //   }
    //   return [...new Set(arr)];
    // },

    // customer
    filterCustomers() {
      const arr = [];
      var searchKeyword = this.searchCustomer;
      // 比對字串
      if (this.subId == "016-001") {
        this.customerList.forEach((item) => {
          if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
            arr.push(item);
          }
        });
      }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
.redFont {
  color: red;
  font-weight: bold;
}
</style>
