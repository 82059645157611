const axios = require("axios").default;
const Toast_Lib = require("./Toast_Lib");
import CryptoJS from "crypto-js";

//const serverUrl = "http://127.0.0.1:8090";
const serverUrl = "http://97.74.90.52:8090";
// const serverUrl = "http://10.0.0.30:8090";
const version = "v0.0.1";

// await / sync
function checkResp(resp, toastMsg) {
  var result = resp.data;
  if (result.errorMsg != null) {
    Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
  }
  return false;
}

// API

/////////////////////////////////
// vender
/////////////////////////////////
function getVenderObjs() {
  var url = serverUrl + "/getVenderList";

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        // axios的錯誤都會跑到這邊
        Toast_Lib.errorToast("錯誤", err);
      });
  });
}
async function handleVenderObjs() {
  let res = await getVenderObjs();
  checkResp(res, "取得供應商列表");

  var venderList = res.data.result["venderList"];
  var venderIdNameMap = {};
  for (var i = 0; i < venderList.length; i++) {
    var venderObj = venderList[i];
    venderIdNameMap[venderObj.id] = venderObj.cname;
  }

  return [venderList, venderIdNameMap];
}

/////////////////////////////////
// file resouce
/////////////////////////////////
function getFileObjs(objId, targetType) {
  var url = "";
  var jsonObj = {};
  if (targetType == "component") {
    url = serverUrl + "/getComponentFileObjs";
    jsonObj.componentId = objId;
  } else if (targetType == "vehicle") {
    url = serverUrl + "/getVehicleFileObjs";
    jsonObj.vehicleId = objId;
  } else if (targetType == "feedback") {
    url = serverUrl + "/getCustomerFeedbackFileObjs";
    jsonObj.customerFeedbackId = objId;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, jsonObj)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        // axios的錯誤都會跑到這邊
        Toast_Lib.errorToast("錯誤", err);
      });
  });
}

async function handleFileObjs(objId, tmpDeleteFile, targetType) {
  let res = await getFileObjs(objId, targetType);
  let key = "";
  if (targetType == "component") {
    key = "componentFileObjs";
  } else if (targetType == "vehicle") {
    key = "vehicleFileObjs";
  } else if (targetType == "feedback") {
    key = "customerFeedbackFileObjs";
  }

  checkResp(res, "取得照片");
  var result = res.data;

  var fileList = result.result[key];
  var fileIds = [];
  for (var i = 0; i < fileList.length; i++) {
    var fileObj = fileList[i];
    if (!tmpDeleteFile.includes(fileObj.id)) {
      fileIds.push(fileObj.id);
    }
  }
  return fileIds;
}

function getFileResouce(fileId) {
  var url = serverUrl + "/getFileResouce";
  var jsonObj = {};
  jsonObj.fileId = fileId;
  return new Promise((resolve, reject) => {
    axios
      .post(url, jsonObj, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        // axios的錯誤都會跑到這邊
        Toast_Lib.errorToast("錯誤", err);
      });
  });
}

async function handleFileResouce(fileIds) {
  let picSrcList = [];
  let src = "";
  for (var i = 0; i < fileIds.length; i++) {
    let fileId = fileIds[i];
    let res = await getFileResouce(fileId);

    var result = res.data;
    var data = new Uint8Array(result);
    var raw = "";

    for (let i = 0, l = data.length; i < l; i++) {
      raw += String.fromCharCode(data[i]);
    }

    var base64 = btoa(raw);
    // if (base64 == "") {
    //   do nothing
    // }
    src = "data:image/jpeg;base64," + base64;
    var tmpObj = {};
    tmpObj.currentPicSrc = src;
    tmpObj.fileId = fileId;
    picSrcList.push(tmpObj);
  }

  return [src, picSrcList];
}

export {
  checkResp,
  handleVenderObjs,
  getFileObjs,
  handleFileObjs,
  getFileResouce,
  handleFileResouce,
};
