<template>
  <!-- audit modal -->
  <div
    class="modal fade"
    id="auditModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            新增/刪除會計科目
          </h4>

          <span
            id="closeAuditModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>
        <div class="modal-body mx-3">
          <h5 style="font-weight: bold; padding-bottom: 20px">
            {{ currentAuditPath }}
          </h5>
          <div
            class="wrap-input100 validate-input"
            style="margin-bottom: 20px"
            v-if="subId == '006-015'"
          >
            <form class="row g-3 needs-validation" novalidate>
              <div class="col-12">
                <label for="validationNewRoleName" class="form-label"
                  >新增會計科目名稱</label
                >
                <input
                  type="text"
                  class="form-control"
                  style="margin-bottom: 10px"
                  id="validationNewAuditName"
                  v-model="currentAuditCname"
                  required
                />
                <div class="invalid-feedback">會計科目名稱不得為空</div>
              </div>
            </form>
          </div>
          <div
            class="col-11 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 13% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteAuditBtn"
              v-if="subId == '006-015'"
              @click="doDeleteAudit()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;刪除此科目
            </button>
            <button
              class="btn btn-outline-primary"
              id="newAuditBtn"
              v-if="subId == '006-015'"
              style="margin-left: 10px"
              @click="doNewAudit()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- audit modal end -->
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>
        <!-------------------------->
        <!--role (006-001~006-003)-->
        <!-------------------------->
        <div
          v-if="subId == '006-001' || subId == '006-002' || subId == '006-003'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th
                  style="display: flex; align-items: center; min-width: 300px"
                >
                  <div style="margin: 5px 0 5px">
                    <span v-if="subId != '006-001'">
                      {{ listTableTitle }}:&ensp;{{ currentRoleCname }}
                      <hr />
                    </span>

                    <div
                      class="wrap-input100 validate-input"
                      style="margin-bottom: 20px"
                      v-if="subId == '006-001'"
                    >
                      <form class="row g-3 needs-validation" novalidate>
                        <div class="col-12">
                          <label for="validationNewRoleName" class="form-label"
                            >新角色名稱</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            style="margin-bottom: 10px"
                            id="validationNewRoleName"
                            v-model="currentRoleCname"
                            required
                          />
                          <div class="invalid-feedback">角色名稱不得為空</div>
                        </div>
                      </form>
                    </div>

                    <span> 權限: </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <td style="min-width: 100%; float: left; align-items: center">
                <div class="row media">
                  <tr>
                    <td
                      v-for="mainPermissionItem in allMainPermission"
                      :key="mainPermissionItem"
                      v-show="true"
                      style="
                        min-width: 100%;
                        float: left;
                        color: #333;
                        font-size: 20px;
                        border: 1px solid #ccc;
                        margin-top: -1px;
                        align-items: center;
                      "
                    >
                      <b
                        >{{
                          mainPermissionItem["permissionCname"]
                        }}&nbsp;&nbsp;</b
                      >

                      <span
                        v-for="subPermissionItem in allSubPermissionMap[
                          mainPermissionItem['id']
                        ]"
                        :key="subPermissionItem"
                        style="
                          min-width: 100%;
                          color: #444;
                          font-size: 16px;
                          align-items: center;
                        "
                      >
                        <div class="ckbox">
                          <input
                            type="checkbox"
                            :id="subPermissionItem['id']"
                            :disabled="!isEdit"
                            :checked="
                              currentRolePermission.includes(
                                subPermissionItem['id']
                              )
                            "
                          />
                          <label :for="subPermissionItem['id']"
                            >&nbsp;&nbsp;&nbsp;&nbsp;{{
                              subPermissionItem["permissionCname"].split(
                                "_"
                              )[1]
                            }}&nbsp;&nbsp;{{ subPermissionItem["id"] }}</label
                          >
                        </div>
                      </span>
                    </td>
                  </tr>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 33% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteRoleBtn"
              v-if="subId == '006-003'"
              @click="doDeleteRole()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editRoleBtn"
              v-if="subId == '006-001'"
              @click="doNewRole()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="editRoleBtn"
              v-if="subId == '006-002'"
              @click="doEditRole()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of role (006-001~006-003)-->
        <!-------------------------->

        <!-------------------------->
        <!--customerLevel (006-004~006-006)-->
        <!-------------------------->
        <div
          v-if="subId == '006-004' || subId == '006-005' || subId == '006-006'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th
                  style="display: flex; align-items: center; min-width: 300px"
                >
                  <div style="margin: 5px 0 5px">
                    <span v-if="subId == '006-005' || subId == '006-006'">
                      客戶分級名稱:&ensp;{{ currentCustomerLevelName }}
                      <hr />
                    </span>

                    <div
                      class="wrap-input100 validate-input"
                      style="margin-bottom: 20px"
                      v-if="subId == '006-004'"
                    >
                      <form class="row g-3 needs-validation" novalidate>
                        <div class="col-12">
                          <label
                            for="validationNewCustomerLevelName"
                            class="form-label"
                            >新客戶分級名稱</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            style="margin-bottom: 10px"
                            id="validationNewCustomerLevelName"
                            v-model="currentCustomerLevelName"
                            required
                          />
                          <div class="invalid-feedback">
                            客戶分級名稱不得為空
                          </div>
                        </div>
                      </form>
                    </div>

                    <span> 詳細資訊: </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <td style="min-width: 100%; float: left; align-items: center">
                <div class="row media">
                  <div style="margin: 5px 0 5px">
                    <!--customer level detail-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-lg-6 col-12">
                          <label for="validationTax" class="form-label"
                            >稅別(%)</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            id="validationTax"
                            :disabled="subId == '006-006'"
                            v-model="currentCustomerLevelTax"
                            required
                          />
                          <div class="invalid-feedback">稅別不得為空</div>
                        </div>
                      </form>

                      <div class="col-md-5">
                        <label for="validationPayMethod" class="form-label"
                          >交易方式</label
                        >
                        <select
                          class="form-select"
                          id="validationPayMethod"
                          :disabled="subId == '006-006'"
                          v-model="currentCustomerPayMethod"
                          required
                        >
                          <option
                            v-for="(value, key) in currentCustomerPayMethodList"
                            :key="key"
                            :value="value"
                          >
                            <a class="dropdown-item">{{ value }}</a>
                          </option>
                        </select>
                        <div class="invalid-feedback">請選擇交易方式</div>
                      </div>
                    </div>
                    <!--end of user name-->
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 33% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteCustomerLevelBtn"
              v-if="subId == '006-006'"
              @click="doDeleteCustomerLevel()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editCustomerLevelBtn"
              v-if="subId == '006-004'"
              @click="doNewCustomerLevel()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="editCustomerLevelBtn"
              v-if="subId == '006-005'"
              @click="doEditCustomerLevel()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of customerLevel (006-004~006-006)-->
        <!-------------------------->
        <!-------------------------->
        <!--vehicle category (006-007~006-009)-->
        <!-------------------------->
        <div
          v-if="subId == '006-007' || subId == '006-008' || subId == '006-009'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th
                  style="display: flex; align-items: center; min-width: 300px"
                >
                  <div style="margin: 5px 0 5px">
                    <span v-if="subId == '006-008' || subId == '006-009'">
                      車輛分類名稱:&ensp;{{ currentVehicleCategoryName }}
                      <hr />
                    </span>

                    <div
                      class="wrap-input100 validate-input"
                      style="margin-bottom: 20px"
                      v-if="subId == '006-007'"
                    >
                      <form class="row g-3 needs-validation" novalidate>
                        <div class="col-12">
                          <label
                            for="validationNewVehicleCategoryName"
                            class="form-label"
                            >新車輛分類名稱</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            style="margin-bottom: 10px"
                            id="validationNewVehicleCategoryName"
                            v-model="currentVehicleCategoryName"
                            required
                          />
                          <div class="invalid-feedback">
                            車輛分類名稱不得為空
                          </div>
                        </div>
                      </form>
                    </div>

                    <span> 詳細資訊: </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <td style="min-width: 100%; float: left; align-items: center">
                <div class="row media">
                  <div style="margin: 5px 0 5px">
                    <!--customer level detail-->
                    <div
                      class="wrap-input100 validate-input"
                      style="padding-bottom: 20px"
                    >
                      <form
                        class="row g-3 needs-validation"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-6">
                          <label
                            for="validationVehicleStyleText"
                            class="form-label"
                            >車輛款式</label
                          >
                          <input
                            type="text"
                            class="form-control col-6"
                            id="validationVehicleStyleText"
                            v-if="subId != '006-009'"
                            v-model="currentVehicleStyleText"
                          />
                          <div
                            v-if="!vehicleStyleValidObj.isValid"
                            style="color: #dc3545"
                          >
                            {{ vehicleStyleValidObj.text }}
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="subId != '006-009'"
                          @click="
                            clickAddSelectItem(
                              tmpVehicleStyleList,
                              vehicleStyleValidObj,
                              currentVehicleStyleText
                            )
                          "
                        >
                          <div style="padding-top: 40px">
                            <i
                              class="fa-regular fa-square-plus"
                              style="color: #0d6efd; font-size: 24px"
                            ></i
                            >&ensp;點擊增加款式
                          </div>
                        </div>
                        <table class="col-12">
                          <tbody v-for="t in tmpVehicleStyleList" :key="t">
                            <td class="row col-10 addedItem">
                              <span class="col-5 offset-1 vehicleCategoryItem"
                                >&ensp;{{ t }}&ensp;<span
                                  class="col-2"
                                  v-if="subId != '006-009'"
                                  id="deleteFileBtn"
                                  style="cursor: pointer; color: #c73f3f"
                                  @click="
                                    clickRemoveSelectItem(
                                      t,
                                      tmpVehicleStyleList
                                    )
                                  "
                                  ><i
                                    class="fa-regular fa-circle-xmark"
                                  ></i></span
                              ></span>
                            </td>
                          </tbody>
                        </table>
                        <div class="col-6">
                          <label
                            for="validationVehicleModelText"
                            class="form-label"
                            >車型</label
                          >
                          <input
                            type="text"
                            class="form-control col-6"
                            id="validationVehicleModelText"
                            v-if="subId != '006-009'"
                            v-model="currentVehicleModelText"
                          />
                          <div
                            v-if="!vehicleModelValidObj.isValid"
                            style="color: #dc3545"
                          >
                            {{ vehicleModelValidObj.text }}
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="subId != '006-009'"
                          @click="
                            clickAddSelectItem(
                              tmpVehicleModelList,
                              vehicleModelValidObj,
                              currentVehicleModelText
                            )
                          "
                        >
                          <div style="padding-top: 40px">
                            <i
                              class="fa-regular fa-square-plus"
                              style="color: #0d6efd; font-size: 24px"
                            ></i
                            >&ensp;點擊增加車型
                          </div>
                        </div>
                        <table class="col-12">
                          <tbody v-for="t in tmpVehicleModelList" :key="t">
                            <td class="row col-10 addedItem">
                              <span class="col-5 offset-1 vehicleCategoryItem"
                                >&ensp;{{ t }}&ensp;<span
                                  class="col-2"
                                  v-if="subId != '006-009'"
                                  id="deleteFileBtn"
                                  style="cursor: pointer; color: #c73f3f"
                                  @click="
                                    clickRemoveSelectItem(
                                      t,
                                      tmpVehicleModelList
                                    )
                                  "
                                  ><i
                                    class="fa-regular fa-circle-xmark"
                                  ></i></span
                              ></span>
                            </td>
                          </tbody>
                        </table>
                        <div class="col-6">
                          <label
                            for="validationVehicleColorText"
                            class="form-label"
                            >車輛顏色</label
                          >
                          <input
                            type="text"
                            class="form-control col-6"
                            id="validationVehicleColorText"
                            v-if="subId != '006-009'"
                            v-model="currentVehicleColorText"
                          />
                          <div
                            v-if="!vehicleColorValidObj.isValid"
                            style="color: #dc3545"
                          >
                            {{ vehicleColorValidObj.text }}
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="subId != '006-009'"
                          @click="
                            clickAddSelectItem(
                              tmpVehicleColorList,
                              vehicleColorValidObj,
                              currentVehicleColorText
                            )
                          "
                        >
                          <div style="padding-top: 40px">
                            <i
                              class="fa-regular fa-square-plus"
                              style="color: #0d6efd; font-size: 24px"
                            ></i
                            >&ensp;點擊增加顏色
                          </div>
                        </div>
                        <table class="col-12">
                          <tbody v-for="t in tmpVehicleColorList" :key="t">
                            <td class="row col-10 addedItem">
                              <span class="col-5 offset-1 vehicleCategoryItem"
                                >&ensp;{{ t }}&ensp;<span
                                  class="col-2"
                                  v-if="subId != '006-009'"
                                  id="deleteFileBtn"
                                  style="cursor: pointer; color: #c73f3f"
                                  @click="
                                    clickRemoveSelectItem(
                                      t,
                                      tmpVehicleColorList
                                    )
                                  "
                                  ><i
                                    class="fa-regular fa-circle-xmark"
                                  ></i></span
                              ></span>
                            </td>
                          </tbody>
                        </table>
                        <div class="col-6">
                          <label
                            for="validationVehicleCodeText"
                            class="form-label"
                            >車廠代碼</label
                          >
                          <input
                            type="text"
                            class="form-control col-6"
                            id="validationVehicleCodeText"
                            v-if="subId != '006-009'"
                            v-model="currentVehicleCodeText"
                          />
                          <div
                            v-if="!vehicleCodeValidObj.isValid"
                            style="color: #dc3545"
                          >
                            {{ vehicleCodeValidObj.text }}
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="subId != '006-009'"
                          @click="
                            clickAddSelectItem(
                              tmpVehicleCodeList,
                              vehicleCodeValidObj,
                              currentVehicleCodeText
                            )
                          "
                        >
                          <div style="padding-top: 40px">
                            <i
                              class="fa-regular fa-square-plus"
                              style="color: #0d6efd; font-size: 24px"
                            ></i
                            >&ensp;點擊增加車廠代碼
                          </div>
                        </div>
                        <table class="col-12">
                          <tbody v-for="t in tmpVehicleCodeList" :key="t">
                            <td class="row col-10 addedItem">
                              <span class="col-5 offset-1 vehicleCategoryItem"
                                >&ensp;{{ t }}&ensp;<span
                                  class="col-2"
                                  v-if="subId != '006-009'"
                                  id="deleteFileBtn"
                                  style="cursor: pointer; color: #c73f3f"
                                  @click="
                                    clickRemoveSelectItem(t, tmpVehicleCodeList)
                                  "
                                  ><i
                                    class="fa-regular fa-circle-xmark"
                                  ></i></span
                              ></span>
                            </td>
                          </tbody>
                        </table>
                      </form>
                    </div>
                    <!--end of user name-->
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 33% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVehicleCategoryBtn"
              v-if="subId == '006-009'"
              @click="doDeleteVehicleCategory()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editVehicleCategoryBtn"
              v-if="subId == '006-007'"
              @click="doNewVehicleCategory()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="editVehicleCategoryBtn"
              v-if="subId == '006-008'"
              @click="doEditVehicleCategory()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of vehicle category (006-007~006-009)-->
        <!-------------------------->
      </div>
    </div>
  </div>
  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="
        subId == '006-002' ||
        subId == '006-003' ||
        subId == '006-005' ||
        subId == '006-006' ||
        subId == '006-008' ||
        subId == '006-009'
      "
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        v-if="subId == '006-002' || subId == '006-003'"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item.roleCname }}
        </li>
      </ul>
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        v-if="subId == '006-005' || subId == '006-006'"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item.customerLevelCname }}
        </li>
      </ul>
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        v-if="subId == '006-008' || subId == '006-009'"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item.cname }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <!-- table content of role(001-003)-->
    <div
      v-if="subId == '006-001' || subId == '006-002' || subId == '006-003'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th></th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentRoleList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["roleCname"] }}
                </div>
              </div>
            </td>
            <td></td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-002'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of role(001-003)-->
    <!-- table content of customerLevel(004-006)-->
    <div
      v-if="subId == '006-004' || subId == '006-005' || subId == '006-006'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>客戶分級名稱</th>
            <th></th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-004'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentCustomerLevelList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["customerLevelCname"] }}
                </div>
              </div>
            </td>
            <td></td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-005'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-006'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of customerLevel(004-006)-->
    <!-- table content of vehicleCategory(007-009)-->
    <div
      v-if="subId == '006-007' || subId == '006-008' || subId == '006-009'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>車輛分類名稱</th>
            <th></th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-007'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentVehicleList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>
            <td></td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-008'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '006-009'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of vehicleCategory(007-009)-->
    <!-- table content of component unit(010)-->
    <div
      v-if="subId == '006-010'"
      class="table-container"
      style="margin-top: 40px"
    >
      <span style="font-size: 20px; font-weight: bold">零件單位</span>
      <div class="row col-12">
        <div class="col-md-6 col-4">
          <label for="validationComponentUnitText" class="form-label"></label>
          <input
            type="text"
            class="form-control col-6"
            id="validationComponentUnitText"
            v-model="currentComponentUnitText"
          />
          <div v-if="!componentUnitValidObj.isValid" style="color: #dc3545">
            {{ componentUnitValidObj.text }}
          </div>
        </div>
        <div
          class="col-md-6 col-8"
          @click="
            clickAddSelectItem(
              tmpComponentUnitList,
              componentUnitValidObj,
              currentComponentUnitText
            )
          "
        >
          <div style="padding-top: 40px">
            <i
              class="fa-regular fa-square-plus"
              style="color: #0d6efd; font-size: 24px"
            ></i
            >&ensp;點擊增加零件單位
          </div>
        </div>
      </div>
      <table class="col-12">
        <tbody v-for="t in tmpComponentUnitList" :key="t">
          <td class="row col-12 addedItem">
            <span class="col-2 offset-1 vehicleCategoryItem"
              >&ensp;{{ t }}&ensp;<span
                class="col-2"
                v-if="subId != '006-009'"
                id="deleteComponentUnitBtn"
                style="cursor: pointer; color: #c73f3f"
                @click="clickRemoveSelectItem(t, tmpComponentUnitList)"
                ><i class="fa-regular fa-circle-xmark"></i></span
            ></span>
          </td>
        </tbody>
      </table>
      <hr />
      <div style="text-align: right">
        <button
          class="btn btn-outline-success"
          id="editRoleBtn"
          @click="doEditComponentUnitList()"
        >
          <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
        </button>
      </div>
    </div>
    <!-- end of table content of component unit(010)-->
    <!-- table content of customer paythod(011)-->
    <div
      v-if="subId == '006-011'"
      class="table-container"
      style="margin-top: 40px"
    >
      <span style="font-size: 20px; font-weight: bold">客戶支付方式</span>
      <div class="row col-12">
        <div class="col-md-6 col-4">
          <label
            for="validationCustomerPaymethonText"
            class="form-label"
          ></label>
          <input
            type="text"
            class="form-control col-6"
            id="validationCustomerPaymethonText"
            v-model="currentCustomerPayMethodText"
          />
          <div v-if="!customerPayMethodValidObj.isValid" style="color: #dc3545">
            {{ customerPayMethodValidObj.text }}
          </div>
        </div>
        <div
          class="col-md-6 col-8"
          @click="
            clickAddSelectItem(
              tmpCustomerPayMethodList,
              customerPayMethodValidObj,
              currentCustomerPayMethodText
            )
          "
        >
          <div style="padding-top: 40px">
            <i
              class="fa-regular fa-square-plus"
              style="color: #0d6efd; font-size: 24px"
            ></i
            >&ensp;點擊增加客戶支付方式
          </div>
        </div>
      </div>
      <table class="col-12">
        <tbody v-for="t in tmpCustomerPayMethodList" :key="t">
          <td class="row col-12 addedItem">
            <span class="col-xl-3 offset-xl-1 col-12 vehicleCategoryItem"
              >&ensp;{{ t }}&ensp;<span
                class="col-2"
                v-if="subId == '006-011'"
                id="deletetmpCustomerPayMethodBtn"
                style="cursor: pointer; color: #c73f3f"
                @click="clickRemoveSelectItem(t, tmpCustomerPayMethodList)"
                ><i class="fa-regular fa-circle-xmark"></i></span
            ></span>
          </td>
        </tbody>
      </table>
      <hr />
      <div style="text-align: right">
        <button
          class="btn btn-outline-success"
          id="editRoleBtn"
          @click="doEditCustomerPayMethodList()"
        >
          <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
        </button>
      </div>
    </div>
    <!-- end of table content of customer paythod(011)-->
    <!-- table content of component category(012)-->
    <div
      v-if="subId == '006-012'"
      class="table-container"
      style="margin-top: 40px"
    >
      <span style="font-size: 20px; font-weight: bold">零件大類</span>
      <div class="row col-12">
        <div class="col-md-6 col-4">
          <label
            for="validationComponentCategoryText"
            class="form-label"
          ></label>
          <input
            type="text"
            class="form-control col-6"
            id="validationComponentCategoryText"
            v-model="currentComponentTypeText"
          />
          <div v-if="!componentTypeValidObj.isValid" style="color: #dc3545">
            {{ componentTypeValidObj.text }}
          </div>
        </div>
        <div
          class="col-md-6 col-8"
          @click="
            clickAddSelectItem(
              tmpComponentTypeList,
              componentTypeValidObj,
              currentComponentTypeText
            )
          "
        >
          <div style="padding-top: 40px">
            <i
              class="fa-regular fa-square-plus"
              style="color: #0d6efd; font-size: 24px"
            ></i
            >&ensp;點擊增加零件大類
          </div>
        </div>
      </div>
      <table class="col-12">
        <tbody v-for="t in tmpComponentTypeList" :key="t">
          <td class="row col-12 addedItem">
            <span class="col-xl-3 offset-xl-1 col-12 vehicleCategoryItem"
              >&ensp;{{ t }}&ensp;<span
                class="col-2"
                v-if="subId == '006-012'"
                id="deletetmpComponentCategoryBtn"
                style="cursor: pointer; color: #c73f3f"
                @click="clickRemoveSelectItem(t, tmpComponentTypeList)"
                ><i class="fa-regular fa-circle-xmark"></i></span
            ></span>
          </td>
        </tbody>
      </table>
      <hr />
      <div style="text-align: right">
        <button
          class="btn btn-outline-success"
          id="editRoleBtn"
          @click="doEditCompnentType()"
        >
          <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
        </button>
      </div>
    </div>
    <!-- end of table content of component category(012)-->
    <!-- table content of company title(013)-->
    <div
      v-if="subId == '006-013'"
      class="table-container"
      style="margin-top: 40px"
    >
      <span style="font-size: 20px; font-weight: bold">公司抬頭</span>
      <div class="row col-12">
        <div class="col-md-6 col-4">
          <label for="validationCompanyTitleText" class="form-label"></label>
          <input
            type="text"
            class="form-control col-6"
            id="validationCompanyTitleText"
            v-model="currentCompanyTitleText"
          />
          <div v-if="!companyTitleValidObj.isValid" style="color: #dc3545">
            {{ companyTitleValidObj.text }}
          </div>
        </div>
        <div
          class="col-md-6 col-8"
          @click="
            clickAddSelectItem(
              tmpCompanyTitleList,
              companyTitleValidObj,
              currentCompanyTitleText
            )
          "
        >
          <div style="padding-top: 40px">
            <i
              class="fa-regular fa-square-plus"
              style="color: #0d6efd; font-size: 24px"
            ></i
            >&ensp;點擊增加公司抬頭
          </div>
        </div>
      </div>
      <table class="col-12">
        <tbody v-for="t in tmpCompanyTitleList" :key="t">
          <td class="row col-12 addedItem">
            <span class="col-xl-3 offset-xl-1 col-12 vehicleCategoryItem"
              >&ensp;{{ t }}&ensp;<span
                class="col-2"
                v-if="subId == '006-013'"
                id="deletetmpCompanyTitleBtn"
                style="cursor: pointer; color: #c73f3f"
                @click="clickRemoveSelectItem(t, tmpCompanyTitleList)"
                ><i class="fa-regular fa-circle-xmark"></i></span
            ></span>
          </td>
        </tbody>
      </table>
      <hr />
      <div style="text-align: right">
        <button
          class="btn btn-outline-success"
          id="editRoleBtn"
          @click="doEditCompanyTitle()"
        >
          <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
        </button>
      </div>
    </div>
    <!-- end of table content of company title(013)-->
    <!-- table content of delivery(014)-->
    <div
      v-if="subId == '006-014'"
      class="table-container"
      style="margin-top: 40px"
    >
      <span style="font-size: 20px; font-weight: bold">送貨方式</span>
      <div class="row col-12">
        <div class="col-md-6 col-4">
          <label for="validationDeliveryText" class="form-label"></label>
          <input
            type="text"
            class="form-control col-6"
            id="validationDeliveryText"
            v-model="currentDeliveryText"
          />
          <div v-if="!deliveryValidObj.isValid" style="color: #dc3545">
            {{ deliveryValidObj.text }}
          </div>
        </div>
        <div
          class="col-md-6 col-8"
          @click="
            clickAddSelectItem(
              tmpDeliveryList,
              deliveryValidObj,
              currentDeliveryText
            )
          "
        >
          <div style="padding-top: 40px">
            <i
              class="fa-regular fa-square-plus"
              style="color: #0d6efd; font-size: 24px"
            ></i
            >&ensp;點擊增加運送方式
          </div>
        </div>
      </div>
      <table class="col-12">
        <tbody v-for="t in tmpDeliveryList" :key="t">
          <td class="row col-12 addedItem">
            <span class="col-xl-3 offset-xl-1 col-12 vehicleCategoryItem"
              >&ensp;{{ t }}&ensp;<span
                class="col-2"
                v-if="subId == '006-014'"
                id="deleteTmpDeliveryBtn"
                style="cursor: pointer; color: #c73f3f"
                @click="clickRemoveSelectItem(t, tmpDeliveryList)"
                ><i class="fa-regular fa-circle-xmark"></i></span
            ></span>
          </td>
        </tbody>
      </table>
      <hr />
      <div style="text-align: right">
        <button
          class="btn btn-outline-success"
          id="editRoleBtn"
          @click="doEditDelivery()"
        >
          <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
        </button>
      </div>
    </div>
    <!-- end of table content of delivery(014)-->
    <!-- table content of audit(015)-->
    <h3 style="font-weight: bold">目前會計科目列表</h3>
    <div
      v-if="subId == '006-015'"
      class="table-container"
      style="margin: 40px 0px"
    >
      <div v-for="(k1, v1) in currentAuditCategoryMap[1]" :key="v1">
        <div v-for="o in k1" :key="o">
          <div
            style="font-weight: bold; font-size: 24px; padding-top: 20px"
            data-bs-toggle="modal"
            data-bs-target="#auditModal"
            @click.stop.prevent="showAuditModal(o)"
          >
            {{ o.cname }}&ensp;<i
              class="fa-solid fa-notes-medical"
              style="cursor: pointer"
            ></i>
          </div>
          <hr />
          <div
            style="color: red; font-size: 20px; padding-top: 10px"
            v-for="o2 in currentAuditCategoryMap[2][o.id]"
            :key="o2"
            data-bs-toggle="modal"
            data-bs-target="#auditModal"
            @click.stop.prevent="showAuditModal(o2)"
          >
            &ensp;&ensp;{{ o2.cname }} &ensp;<i
              class="fa-solid fa-notes-medical"
              style="cursor: pointer"
            ></i>
            <div
              style="color: green; font-size: 16px; padding-top: 10px"
              v-for="o3 in currentAuditCategoryMap[3][o2.id]"
              :key="o3"
              data-bs-toggle="modal"
              data-bs-target="#auditModal"
              @click.stop.prevent="showAuditModal(o3)"
            >
              &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{{ o3.cname }}&ensp;<i
                class="fa-solid fa-notes-medical"
                style="cursor: pointer"
              ></i>
              <!-- <button
                class="btn btn-outline-primary"
                id="editCustomerLevelBtn"
                v-if="subId == '006-015'"
                @click="doNewCustomerLevel()"
              >
                <i class="fa-solid fa-notes-medical"></i>
              </button> -->
              <div
                style="color: blue; padding-top: 10px"
                v-for="o4 in currentAuditCategoryMap[4][o3.id]"
                :key="o4"
              >
                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                {{ o4.cname }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of table content of audit(015)-->
  </div>
</template>

<script>
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "角色名稱",
      nameColTitle: "角色名稱",
      isEdit: true,
      // role (001-003)
      currentRoleCname: "",
      currentRoleId: "",
      currentRolePermission: [],
      allMainPermission: [],
      allSubPermission: [],
      allSubPermissionMap: {},
      confirmModalTitle: "",
      roleList: [],
      currentRoleList: [],
      // customLevel (004-006)
      customerLevelList: [],
      currentCustomerLevelList: [],
      currentCustomerLevelName: "",
      currentCustomerLevelId: "",
      currentCustomerLevelTax: 5,
      currentCustomerPayMethodList: [],
      currentCustomerPayMethod: "",
      // vehicleCategory (007-009)
      currentVehicleCategoryName: "",
      currentVehicleCategoryItem: null,
      vehicleList: [],
      currentVehicleList: [],
      tmpVehicleStyleList: [],
      currentVehicleStyleText: "",
      vehicleStyleValidObj: {
        text: "",
        isValid: true,
      },
      tmpVehicleModelList: [],
      currentVehicleModelText: "",
      vehicleModelValidObj: {
        text: "",
        isValid: true,
      },
      tmpVehicleColorList: [],
      currentVehicleColorText: "",
      vehicleColorValidObj: {
        text: "",
        isValid: true,
      },
      tmpVehicleCodeList: [],
      currentVehicleCodeText: "",
      vehicleCodeValidObj: {
        text: "",
        isValid: true,
      },
      // component unit (010)
      componentUnitList: [],
      currentComponentUnitList: [],
      tmpComponentUnitList: [],
      currentComponentUnitText: "",
      componentUnitValidObj: {
        text: "",
        isValid: true,
      },
      // component unit (011)
      customerPayMethodList: [],
      tmpCustomerPayMethodList: [],
      currentCustomerPayMethodText: "",
      customerPayMethodValidObj: {
        text: "",
        isValid: true,
      },
      // component type (012)
      componentTypeList: [],
      tmpComponentTypeList: [],
      currentComponentTypeText: "",
      componentTypeValidObj: {
        text: "",
        isValid: true,
      },
      // company title (013)
      companyTitleList: [],
      tmpCompanyTitleList: [],
      currentCompanyTitleText: "",
      companyTitleValidObj: {
        text: "",
        isValid: true,
      },
      // delivery (014)
      deliveryList: [],
      tmpDeliveryList: [],
      currentDeliveryText: "",
      deliveryValidObj: {
        text: "",
        isValid: true,
      },
      // audit category (015)
      currentAuditCategoryMap: {},
      currentAuditCnameMap: {},
      currentAuditPath: "",
      currentAuditObj: null,
      currentAuditCname: "",
      // search
      search: "",
      autoComplete: false,
    };
  },

  setup() {},
  mounted() {
    if (
      this.subId == "006-001" ||
      this.subId == "006-002" ||
      this.subId == "006-003"
    ) {
      this.doGetRoleList();
      this.doGetPermissionFunctionName();
    } else if (
      this.subId == "006-004" ||
      this.subId == "006-005" ||
      this.subId == "006-006"
    ) {
      this.doGetCustomerLevelList();
    } else if (
      this.subId == "006-007" ||
      this.subId == "006-008" ||
      this.subId == "006-009"
    ) {
      this.doGetVehicleCategoryList();
    } else if (this.subId == "006-010") {
      this.doGetComponentUnitList();
    } else if (this.subId == "006-011") {
      this.doGetCustomerPayMethodList();
    } else if (this.subId == "006-012") {
      this.doGetComponentTypeList();
    } else if (this.subId == "006-013") {
      this.doGetCompanyTitleList();
    } else if (this.subId == "006-014") {
      this.doGetDeliveryList();
    } else if (this.subId == "006-015") {
      this.doGetAuditCategory();
    }
  },
  methods: {
    showAuditModal(auditObj) {
      this.currentAuditCname = "";

      this.currentAuditObj = auditObj;

      let id = auditObj.id;
      let parentFullPath = auditObj.parentAuditPath;

      this.currentAuditPath = "";
      let tmp = parentFullPath.split(",");
      for (let i = 0; i < tmp.length; i++) {
        let name = this.currentAuditCnameMap[tmp[i]];
        if (name === undefined) {
          // do nothing
        } else {
          this.currentAuditPath += this.currentAuditCnameMap[tmp[i]];
          this.currentAuditPath += " → ";
        }
      }
      this.currentAuditPath += this.currentAuditCnameMap[id];
    },
    showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();
      this.isEdit = isEdit;
      if (
        this.subId == "006-001" ||
        this.subId == "006-002" ||
        this.subId == "006-003"
      ) {
        this.doGetCustomerPayMethodList();
        this.doGetPermissionItemList();
        if (item != "") {
          this.currentRoleCname = item.roleCname;
          this.currentRoleId = item.id;
          this.getRoleItemPermission(item);
        }
      }
      if (
        this.subId == "006-004" ||
        this.subId == "006-005" ||
        this.subId == "006-006"
      ) {
        this.doGetCustomerPayMethodList();
        if (item != "") {
          this.currentCustomerLevelId = item.id;
          this.currentCustomerLevelName = item.customerLevelCname;
          this.currentCustomerPayMethod = item.payMethod;
          this.currentCustomerLevelTax = item.tax;
        }
      }
      if (
        this.subId == "006-007" ||
        this.subId == "006-008" ||
        this.subId == "006-009"
      ) {
        if (item != "") {
          this.currentVehicleCategoryItem = item;
          this.currentVehicleCategoryName = item.cname;
          this.doGetVehicleCategoryDetail(item);
        }
      }
    },
    resetConfirmModal() {
      // role (001-003)
      this.currentRoleCname = "";
      this.currentRoleId = "";
      this.currentRolePermission = [];
      this.allMainPermission = [];
      this.allSubPermission = [];
      this.allSubPermissionMap = {};
      this.confirmModalTitle = "";
      this.roleList = [];
      this.currentRoleList = [];
      // customLevel (004-006)
      this.currentCustomerLevelName = "";
      this.currentCustomerLevelId = "";
      this.currentCustomerLevelTax = 5;
      this.currentCustomerPayMethodList = [];
      this.currentCustomerPayMethod = "";
      // vehicleCategory (007-009)
      this.currentVehicleCategoryName = "";
      this.currentVehicleCategoryItem = null;
      this.tmpVehicleStyleList = [];
      this.currentVehicleStyleText = "";

      this.vehicleStyleValidObj = { text: "", isValid: true };
      this.tmpVehicleModelList = [];
      this.currentVehicleModelText = "";
      this.vehicleModelValidObj = { text: "", isValid: true };
      this.tmpVehicleColorList = [];
      this.currentVehicleColorText = "";
      this.vehicleColorValidObj = { text: "", isValid: true };
      this.tmpVehicleCodeList = [];
      this.currentVehicleCodeText = "";
      this.vehicleCodeValidObj = { text: "", isValid: true };
    },
    doNewRole() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      // var newRoleCname = $("#validationNewRoleName").val();
      if (this.currentRoleCname == "") {
        Toast_Lib.errorToast("角色名稱不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newRole";

      var jsonObj = {};
      jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.roleCname = this.currentRoleCname;
      jsonObj.permissionStr = this.getCheckedBoxesToPermissionStr();

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("新增角色失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("新增角色成功", "");

              this.doGetRoleList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditRole() {
      var url = Common_Lib.getServerUrl() + "/updateRole";
      var jsonObj = {};
      jsonObj.roleId = this.currentRoleId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.permissionStr = this.getCheckedBoxesToPermissionStr();
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            Toast_Lib.infoToast("變更權限成功", "");

            if (Common_Lib.getLocalAndDecrypt("roleId") == this.currentRoleId) {
              var token = localStorage.getItem("token");
              var secretKey = "k" + token + "k";
              var userPermission = this.$CryptoJS.AES.encrypt(
                jsonObj.permissionStr,
                secretKey
              ).toString();
              localStorage.setItem("userPermission", userPermission);
            }

            $("#closeConfirmModalBtn").click();
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteRole() {
      var url = Common_Lib.getServerUrl() + "/deleteRole";
      var jsonObj = {};
      jsonObj.roleId = this.currentRoleId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除角色失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除角色成功", "");

              this.doGetRoleList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    getCheckedBoxesToPermissionStr() {
      var currentPermissionStr = "";
      var checkboxes = document.querySelectorAll(
        "input[type=checkbox]:checked"
      );

      for (var i = 0; i < checkboxes.length; i++) {
        var permissionId = checkboxes[i].id;
        currentPermissionStr += permissionId;
        if (i != checkboxes.length - 1) {
          currentPermissionStr += ",";
        }
      }

      return currentPermissionStr;
    },
    getRoleItemPermission(roleItem) {
      var url = Common_Lib.getServerUrl() + "/getRolePermission";
      var jsonObj = {};
      jsonObj.roleId = roleItem["id"];

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得角色權限失敗", result.errorMsg);
            } else {
              result = response.data;
              this.currentRolePermission = result.result["rolePermissionList"];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetRoleList() {
      var url = Common_Lib.getServerUrl() + "/getRoleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.roleList = [];
            this.currentRoleList = [];

            result = response.data;
            this.roleList = result.result["roleList"];
            this.currentRoleList = this.roleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetPermissionFunctionName() {
      var url = Common_Lib.getServerUrl() + "/getPermissionName";
      let result = null;
      var jsonObj = {};
      jsonObj.permissionId = this.subId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.functionTitle = result.result["permissionName"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetPermissionItemList() {
      var url = Common_Lib.getServerUrl() + "/getPermissionItemList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得權限列表失敗", result.errorMsg);
            } else {
              this.allMainPermission = result.result["mainPermissionList"];
              this.allSubPermission = result.result["subPermissionList"];

              this.allSubPermissionMap = {};
              for (var i = 0; i < this.allMainPermission.length; i++) {
                var tmpList = [];
                var allMainObj = this.allMainPermission[i];

                for (var j = 0; j < this.allSubPermission.length; j++) {
                  var subObj = this.allSubPermission[j];
                  if (subObj["id"].includes(allMainObj["id"] + "-")) {
                    tmpList.push(subObj);
                  }
                }
                this.allSubPermissionMap[allMainObj["id"]] = tmpList;
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    ///////
    // customer level (004-006)
    doGetCustomerPayMethodList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelPayMethodList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerLevelPayMethodList = [];

            result = response.data;
            this.currentCustomerPayMethodList =
              result.result["customerPayMethodList"];
            this.tmpCustomerPayMethodList =
              result.result["customerPayMethodList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditCustomerPayMethodList() {
      var url = Common_Lib.getServerUrl() + "/updateCustomerPayMethodList";
      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.newCustomerPaymethodListStr =
        this.tmpCustomerPayMethodList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(
                "更新客戶分級支付方式列表失敗",
                result.errorMsg
              );
            } else {
              Toast_Lib.infoToast("更新客戶分級支付方式列表成功", "");

              this.doGetComponentUnitList();
              this.currentComponentUnitText = "";
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewCustomerLevel() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentCustomerLevelName == "") {
        Toast_Lib.errorToast("用戶分級名稱不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newCustomerLevel";
      var jsonObj = {};
      jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.customerLevelCname = this.currentCustomerLevelName;
      jsonObj.payMethod = this.currentCustomerPayMethod;
      jsonObj.tax = this.currentCustomerLevelTax;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("新增客戶分級失敗", result.errorMsg);
            } else {
              if (result.errorMsg != null) {
                Toast_Lib.errorToast("新增客戶分級失敗", result.errorMsg);
              } else {
                Toast_Lib.infoToast("新增客戶分級成功", "");

                this.doGetCustomerLevelList();
                $("#closeConfirmModalBtn").click();
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditCustomerLevel() {
      var url = Common_Lib.getServerUrl() + "/updateCustomerLevel";
      var jsonObj = {};
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.customerLevelId = this.currentCustomerLevelId;
      jsonObj.customerLevelCname = this.currentCustomerLevelName;
      jsonObj.payMethod = this.currentCustomerPayMethod;
      jsonObj.tax = this.currentCustomerLevelTax;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("客戶分級變更失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("客戶分級變更成功", "");

              this.doGetCustomerLevelList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteCustomerLevel() {
      var url = Common_Lib.getServerUrl() + "/deleteCustomerLevel";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerLevelId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除客戶分級失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除客戶分級成功", "");

              this.doGetCustomerLevelList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            // this.customerLevelList = [];
            // this.currentCustomerLevelList = [];

            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // end of 006-004~006-006
    ///////
    // ui add/remove item (007-009, 010, 011, 012, 013)
    clickAddSelectItem(targetList, targetValidObj, addText) {
      targetValidObj.isValid = true;
      targetValidObj.text = "";
      if (addText == "") {
        targetValidObj.isValid = false;
        targetValidObj.text = "沒有輸入值";
        return;
      }
      if (targetList.includes(addText)) {
        targetValidObj.isValid = false;
        targetValidObj.text = "值已存在";
        return;
      }
      targetList.push(addText);
    },
    clickRemoveSelectItem(text, targetList) {
      var tmpList = [];
      for (var i = 0; i < targetList.length; i++) {
        var item = targetList[i];
        if (item != text) {
          tmpList.push(item);
        }
      }

      targetList.length = 0;
      for (var j = 0; j < tmpList.length; j++) {
        targetList.push(tmpList[j]);
      }
    },
    //
    //////
    // vehicle category (007-009)
    doNewVehicleCategory() {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentVehicleCategoryName == "") {
        Toast_Lib.errorToast("車輛類別不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newVehicleCategory";
      var jsonObj = {};
      jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      jsonObj.vehicleCname = this.currentVehicleCategoryName;
      jsonObj.vehicleStyleListStr = this.tmpVehicleStyleList.join(",");
      jsonObj.vehicleModelListStr = this.tmpVehicleModelList.join(",");
      jsonObj.vehicleColorListStr = this.tmpVehicleColorList.join(",");
      jsonObj.vehicleCodeListStr = this.tmpVehicleCodeList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("新增車輛分類失敗", result.errorMsg);
            } else {
              if (result.errorMsg != null) {
                Toast_Lib.errorToast("新增車輛分類失敗", result.errorMsg);
              } else {
                Toast_Lib.infoToast("新增車輛分類成功", "");

                this.doGetVehicleCategoryList();
                $("#closeConfirmModalBtn").click();
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditVehicleCategory() {
      var url = Common_Lib.getServerUrl() + "/updateVehicleCategory";
      var jsonObj = {};
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      jsonObj.vehicleCategoryId = this.currentVehicleCategoryItem.id;
      jsonObj.vehicleStyleListStr = this.tmpVehicleStyleList.join(",");
      jsonObj.vehicleModelListStr = this.tmpVehicleModelList.join(",");
      jsonObj.vehicleColorListStr = this.tmpVehicleColorList.join(",");
      jsonObj.vehicleCodeListStr = this.tmpVehicleCodeList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("編輯車輛分類失敗", result.errorMsg);
            } else {
              if (result.errorMsg != null) {
                Toast_Lib.errorToast("編輯車輛分類失敗", result.errorMsg);
              } else {
                Toast_Lib.infoToast("編輯車輛分類成功", "");

                this.doGetVehicleCategoryList();
                $("#closeConfirmModalBtn").click();
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteVehicleCategory() {
      var url = Common_Lib.getServerUrl() + "/deleteVehicleCategory";
      var jsonObj = {};
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.vehicleCategoryId = this.currentVehicleCategoryItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除車輛分類失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除車輛分類成功", "");

              this.doGetVehicleCategoryList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleCategoryList() {
      var url = Common_Lib.getServerUrl() + "/getVihicleCategoryList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.vehicleList = [];
            this.currentVehicleList = [];

            result = response.data;
            this.vehicleList = result.result["vehicleCategoryList"];
            this.currentVehicleList = this.vehicleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleCategoryDetail(vehicleCategoryItem) {
      var url = Common_Lib.getServerUrl() + "/getVihicleCategoryDetail";
      var jsonObj = {};
      jsonObj.vehicleCategoryId = vehicleCategoryItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得車輛類別細節失敗", result.errorMsg);
            } else {
              this.tmpVehicleStyleList =
                result.result["vehicleCategoryStyleList"];
              this.tmpVehicleModelList =
                result.result["vehicleCategoryModelList"];
              this.tmpVehicleColorList =
                result.result["vehicleCategoryColorList"];
              this.tmpVehicleCodeList =
                result.result["vehicleCategoryCodeList"];
              this.doGetVehicleCategoryList();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // component unit (010)
    doGetComponentUnitList() {
      var url = Common_Lib.getServerUrl() + "/getComponetUnitList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpComponentUnitList = result.result["componentUnitList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditComponentUnitList() {
      var url = Common_Lib.getServerUrl() + "/updateComponetUnitList";
      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.newComponentUnitListStr = this.tmpComponentUnitList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("更新零件單位列表失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("更新零件單位列表成功", "");

              this.doGetComponentUnitList();
              this.currentComponentUnitText = "";
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // component category (012)
    doGetComponentTypeList() {
      var url = Common_Lib.getServerUrl() + "/getComponetTypeList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpComponentTypeList = result.result["componentTypeList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditCompnentType() {
      var url = Common_Lib.getServerUrl() + "/updateComponetTypeList";
      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.newComponentTypeListStr = this.tmpComponentTypeList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("更新零件大類列表失敗", result.errorMsg);
              this.doGetComponentTypeList();
              this.currentComponentTypeText = "";
            } else {
              Toast_Lib.infoToast("更新零件大類列表成功", "");

              this.doGetComponentTypeList();
              this.currentComponentTypeText = "";
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // company title (013)
    doGetCompanyTitleList() {
      var url = Common_Lib.getServerUrl() + "/getCompanyTitleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpCompanyTitleList = result.result["companyTitleList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditCompanyTitle() {
      var url = Common_Lib.getServerUrl() + "/updateCompanyTitleList";
      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.newCompanyTitleListStr = this.tmpCompanyTitleList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("更新公司抬頭列表失敗", result.errorMsg);
              this.doGetCompanyTitleList();
              this.currentCompanyTitleText = "";
            } else {
              Toast_Lib.infoToast("更新公司抬頭列表成功", "");

              this.doGetCompanyTitleList();
              this.currentCompanyTitleText = "";
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // delivery (014)
    doGetDeliveryList() {
      var url = Common_Lib.getServerUrl() + "/getDeliveryList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpDeliveryList = result.result["deliveryList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doEditDelivery() {
      var url = Common_Lib.getServerUrl() + "/updateDeliveryList";
      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.newDeliveryListStr = this.tmpDeliveryList.join(",");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("更新運送方式列表失敗", result.errorMsg);
              this.doGetDeliveryList();
              this.currentDeliveryText = "";
            } else {
              Toast_Lib.infoToast("更新運送方式列表成功", "");

              this.doGetDeliveryList();
              this.currentDeliveryText = "";
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // audit category (015)
    doGetAuditCategory() {
      var url = Common_Lib.getServerUrl() + "/getAuditCategory";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentAuditCategoryMap = result.result["resultAuditCategory"];
            this.currentAuditCnameMap = result.result["auditCnameMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewAudit() {
      var url = Common_Lib.getServerUrl() + "/newAudit";
      if (this.currentAuditCname == "") {
        Toast_Lib.errorToast("會計科目名稱不得為空");
        return;
      }

      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.parentAuditPath =
        this.currentAuditObj.parentAuditPath + "," + this.currentAuditObj.id;
      jsonObj.parentId = this.currentAuditObj.id;
      jsonObj.cname = this.currentAuditCname;
      jsonObj.level = this.currentAuditObj.level + 1;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("新增會計科目失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("新增會計科目成功", "");

              this.doGetAuditCategory();
              $("#closeAuditModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteAudit() {
      var url = Common_Lib.getServerUrl() + "/deleteAudit";

      var jsonObj = {};

      jsonObj.userId = Common_Lib.getLocal("userId");
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.auditId = this.currentAuditObj.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除會計科目失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除會計科目成功", "");

              this.doGetAuditCategory();
              $("#closeAuditModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // search function
    clickSearchItem(roleItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (
        this.subId == "006-002" ||
        this.subId == "006-005" ||
        this.subId == "006-008"
      ) {
        modalTitle = "編輯";
        isEdit = true;
      } else {
        modalTitle = "確認刪除?";
        isEdit = false;
      }
      this.showConfirmModal(roleItem, modalTitle, isEdit);
    },
    searchBtn() {
      if (this.subId == "006-002" || this.subId == "006-003") {
        this.currentRoleList = [];
        if (this.search == "") {
          this.currentRoleList = this.roleList;
        } else {
          for (var i = 0; i < this.roleList.length; i++) {
            var roleItem = this.roleList[i];
            if (roleItem["roleCname"].includes(this.search)) {
              this.currentRoleList.push(roleItem);
            }
          }
        }
      } else if (this.subId == "006-005" || this.subId == "006-006") {
        this.currentCustomerLevelList = [];
        if (this.search == "") {
          this.currentCustomerLevelList = this.customerLevelList;
        } else {
          for (var j = 0; j < this.customerLevelList.length; j++) {
            var customerLevelItem = this.customerLevelList[j];
            if (customerLevelItem["customerLevelCname"].includes(this.search)) {
              this.currentCustomerLevelList.push(customerLevelItem);
            }
          }
        }
      } else if (this.subId == "006-008" || this.subId == "006-009") {
        this.currentVehicleList = [];
        if (this.search == "") {
          this.currentVehicleList = this.vehicleList;
        } else {
          for (var k = 0; k < this.vehicleList.length; k++) {
            var vehicleCategoryItem = this.vehicleList[k];
            // need to fix
            if (vehicleCategoryItem["cname"].includes(this.search)) {
              this.currentVehicleList.push(vehicleCategoryItem);
            }
          }
        }
      }
    },
    // keyboardEvent(e) {
    //   // 按鈕向上
    //   if (e.keyCode === 38) {
    //     console.log("38---##", this.selectedIndex);
    //     if (this.selectedIndex > 0) {
    //       this.selectedIndex--;
    //     }
    //     // 按鈕向下
    //   } else if (e.keyCode === 40) {
    //     console.log("40---##", this.selectedIndex);
    //     this.selectedIndex++;
    //     // enter
    //   } else if (e.keyCode === 13) {
    //     this.filterProducts.forEach((item, i) => {
    //       if (this.selectedIndex === i) {
    //         // 當 selectedIndex 與 filterProducts 的 index相同，就將 search 改成選取項目的書名
    //         this.search = item.title;
    //       }
    //     });
    //   }
    // },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
        // this.getProducts();
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      var searchFrom = [];
      if (this.subId == "006-002" || this.subId == "006-003") {
        searchFrom = this.roleList;
        // 比對字串
        searchFrom.forEach((item) => {
          if (searchKeyword != "" && item.roleCname.includes(searchKeyword)) {
            arr.push(item);
          }
        });
      }
      if (this.subId == "006-005" || this.subId == "006-006") {
        searchFrom = this.customerLevelList;
        // 比對字串
        searchFrom.forEach((item) => {
          if (
            searchKeyword != "" &&
            item.customerLevelCname.includes(searchKeyword)
          ) {
            arr.push(item);
          }
        });
      }
      if (this.subId == "006-008" || this.subId == "006-009") {
        searchFrom = this.vehicleList;
        // 比對字串
        searchFrom.forEach((item) => {
          if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
            arr.push(item);
          }
        });
      }

      // 如果輸入兩個關鍵字就會出現重複的資料，所以需要刪除重複資料。
      // 過濾出重複的元素
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
</style>
