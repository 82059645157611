<template>n</template>

<script>
import Header from "./Header.vue";

import { ref, watch } from "vue";
const Common_Lib = require("../js/Common_Lib");
const Toast_Lib = require("../js/Toast_Lib");
const axios = require("axios").default;

var myID = "App";

export default {
  name: myID,
  components: {},
  data() {
    return {};
  },

  setup() {},
  mounted() {},
  methods: {},

  computer: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
body {
  font-family: "Noto Sans TC", sans-serif !important;
}

.wrap {
  margin: 0;
  background-color: #fff;
  height: 100%;
}
/* .header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 3;
} */

.setCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerIcon {
  width: 50px;
}

.header {
  height: 100px; /*設定header本身高度*/
  text-align: center;
  color: #aaa;
}

.wrapper {
  min-height: calc(100% - 150px); /*減去header+footer高度*/
}

.footer {
  height: 50px; /*設定footer本身高度*/
  background-color: #fff;
  text-align: center;
  color: #aaa;
}
.main {
  border: 0px red solid;
  min-height: calc(100% - 0px);
}
.topTextFooter {
  display: flex !important;
}
.bottomTextFooter {
  display: none !important;
}
.welcome {
  width: 100%;
  height: 100%;
  padding: 5px 0;
  background-color: transparent;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13em;
}
.welcome .item {
  -webkit-text-stroke: 0em #000;
  color: #fff;
  font-size: 2.7em;
  font-weight: 900;
  margin-top: 1em;

  /*animation: oxxo 1s linear 0 1 normal;*/
  animation-name: oxxo;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
.welcome .item2 {
  -webkit-text-stroke: 0em #000;
  color: #fff;
  font-size: 2.5em;
  font-weight: 900;
  opacity: 0;
  /*animation: oxxo 1s linear 0 1 normal;*/
  animation-name: oxxo2;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}
@keyframes oxxo {
  from {
    margin-top: 1em;
  }
  to {
    margin-top: 0.4em;
  }
}
@keyframes oxxo2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer {
  /*margin-top: 50px;*/
  background-color: transparent;
  height: 125px;
  text-align: center;
}

@media screen and (min-width: 1082px) {
  .hamburger {
    display: none !important;
  }
  .nav {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: 85px !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 3;
    margin-top: 0px !important;
    background-color: #fff;
    transition: right 0.3s;
    border-top: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
  }
  .nav .item {
    border-bottom: 0px #d0d0d0 solid !important;
    width: 150px !important;
    height: 85px !important;
    float: left;
  }
  .nav .item:hover {
    background-color: #fff !important ;
    border-top: 3px solid #548dd4 !important;
  }
  .nav a {
    padding: 15px 10px 10px 10px !important;
  }
  .nav span {
    padding: 15px 10px 10px 10px !important;
  }

  /*下拉選單*/
  .foo {
    display: block !important;
    position: absolute !important;
    top: 60px !important;
    left: 0 !important;
    z-index: 3 !important;
    background-color: #fff;
    box-shadow: 0px 0px 10px #cccccc;

    width: 200px;
    padding: 5px;
  }
  .nav .itemGroup {
    position: relative;
  }
  .nav span:hover .subItem {
    display: block !important;
  }
  .subItem {
    display: none !important;
    position: absolute !important;
    top: 52.5px !important;
    left: 0 !important;
    z-index: 3 !important;
    /*background-color:rgba(62,71,84, 0.8) !important;*/
    background-color: transparent;
    /*box-shadow:0px 0px 10px #cccccc;*/

    width: 200px;
    padding: 5px;
  }
  .nav .subItem > a {
    background-color: rgba(62, 71, 84, 0.9) !important;
    width: 200px;
    border-bottom: 1px #ccc solid !important;
    margin-right: 20px;
    color: #fff !important;
  }
  .line {
    border-left: 1px solid red;
  }
  /*
.nav .subItem > a:after{
    content:">";
    color:#ccc;
    margin-left: 80px;
}*/

  /*
.itemGroup:hover .subItem{
   display: block !important;
  position: absolute;
  top:0;
  left: 0;
  z-index: 999;
  background-color: #000;
}*/
} /*end rwd 991*/

/*el-option style*/
.el-scrollbar {
  background-color: #fff;
  border: 1px solid #fff;
}

/*nav*/
.logo {
  position: fixed;
  top: 0;
  left: 25px !important;
  top: 3px !important;
  /*z-index: 4;*/
}
.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  position: fixed;
  top: 0;
  right: -992px;
  width: 100%;
  z-index: 3;
  margin-top: 60px;
  background-color: #fff;
  transition: right 0.3s;
  border-top: 2px solid #548dd4;
  box-shadow: 3px 3px 4px 6px #cccccc;
  opacity: 0;
}
.nav .item {
  border-bottom: 1px #d0d0d0 solid;
  width: 100%;
}
.nav .item {
  /*background-color: #CE0000 ;*/
  background-color: #fff;
}

.nav .item > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  background-color: #fff;
}
.nav .item > a:hover {
  color: #548dd4;
}
.nav .subItem > a {
  text-decoration: none;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #3c3c3c;
  font-weight: 400;
  font-size: 18px;
  background-color: #fff;
}
.nav .item :hover {
  color: #548dd4;
}
.nav a:hover {
  color: #548dd4;
}
.nav span:hover {
  cursor: pointer;
}

/*.nav a:hover{
  background-color: #CE0000;
}*/
.nav .item span {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding: 10px;
  /*position: relative;*/
}
nav .item span .subItem {
  display: none;
}

.foo {
  display: block !important;
  /*position: absolute;
  top:60px;
  left: 0;
  z-index: 999;*/
}

.hamburger:hover {
  cursor: pointer;
}
.toggleHamburger {
  right: 0;
  opacity: 1;
}

#line1 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line1Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(11px) rotate(45deg);
}

#line2 {
  display: block;
  width: 80%;
  height: 3px;

  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line2Close {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
#line3 {
  display: block;
  width: 80%;
  height: 3px;
  background-color: #548dd4;
  transition: all 0.3s ease-in-out;
}
.line3Close {
  transition: all 0.3s ease-in-out;
  transform: translateY(-9px) rotate(-45deg);
}
.showLogoBar {
  display: none;
  z-index: -1;
}

.setCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuBar {
  display: flex;
  justify-content: right;
}
.removeHrefStyle {
  color: #000;
  text-decoration: none !important;
}
.moreProducts {
  display: none;
}
.itemGroupStyle {
  margin-top: -5px;
}
.logoBar {
  position: absolute;
  padding: 0px;
  height: 40px;
  margin-top: 5px;
}
.logo {
  position: absolute;
  padding: 0px;
  height: 60px;
  margin-top: 9px;
}
.footerStyle {
  height: 170px;
}

.solconStyle {
  width: 80%;
}
.technibusStyle {
  margin-top: 10px;
  width: 60%;
}
.MGCStyle {
  margin-top: 10px;
  width: 65%;
}
.squareDStyle {
  width: 80%;
}
.ascoStyle {
  width: 80%;
}
.fanoxStyle {
  width: 60%;
}
.powerMeasuerStyle {
  width: 80%;
}
.schneiderStyle {
  width: 80%;
}
.subtitleStyle {
  font-size: 1.3rem;
  font-weight: 700;
  text-align: left;
  color: #3c3c3c;
}
.productsTitle {
  font-size: 2rem;
}

@media (max-height: 618px) {
  /* .footerStyle {
    height:35%;
  } */
}
.engTitle {
  font-family: "Cambria", sans-serif !important;
  font-size: 3.2rem !important;
}
.cntTitle {
  font-size: 2.2rem;
}
.cntTitle2 {
  font-size: 1.8rem;
}
.cntTitleMain {
  font-size: 2.2rem !important;
}
.engTitleMain {
  font-family: "Cambria", sans-serif !important;
  font-size: 2.8rem;
}
@media (max-width: 767px) {
  .contactDiv {
    padding-top: 20px;
    background-color: #353535;
  }
  .engTitle {
    font-family: "Cambria", sans-serif !important;
    font-size: 2.2rem !important;
  }
  .cntTitle {
    font-size: 1.3rem;
  }
  .cntTitle2 {
    font-size: 1.3rem;
  }
}
@media (max-width: 1082px) {
  .subItemPos {
    margin-top: 0px;
  }
  .logoDiv {
    display: none;
    height: 100%;
  }
  .menuBar {
    display: block;
  }
  .moreProducts {
    display: block;
  }
  .itemGroupStyle {
    margin-top: 0px !important;
  }
  .logoBar {
    position: absolute;
    padding: 0px;
    height: 80%;
    margin-top: -2px;
  }
  .logo {
    position: absolute;
    padding: 0px;
    height: 50px;
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .hiddenDiv {
    display: none !important;
  }
  .topTextFooter {
    display: none !important;
  }
  .bottomTextFooter {
    display: flex !important;
  }
  .footerStyle {
    height: 280px;
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border: 0px solid #000;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 4;
    width: 40px;
    height: 30px;
  }
}
@media (max-width: 768px) {
  .footerStyle {
    height: 430px;
  }
  .logo {
    position: fixed;
    top: 0;
    left: 10px !important;
    /*z-index: 4;*/
  }
}
@media (max-width: 576px) {
  .topTextFooter {
    display: none !important;
  }
  .bottomTextFooter {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .squareDStyle {
    width: 50%;
  }
  .MGCStyle {
    margin-top: 10px;
    width: 45%;
  }
  .technibusStyle {
    margin-top: 10px;
    width: 80%;
  }
  .solconStyle {
    width: 50%;
  }
  .ascoStyle {
    width: 50%;
  }
  .fanoxStyle {
    width: 50%;
  }
  .powerMeasuerStyle {
    width: 50%;
  }
  .schneiderStyle {
    width: 50%;
  }
  .subtitleStyle {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: left;
    color: #3c3c3c;
  }
  .productsTitle {
    font-size: 1.5rem;
  }
}
.projectTitle2 {
  line-height: 50px;
  padding-bottom: 10px !important;
}

@media (max-width: 576px) {
  .projectTitle2 {
    line-height: 30px;
    margin-top: 20px;
  }
  .squareDStyle {
    margin-top: 10px;
    width: 80%;
  }
  .MGCStyle {
    margin-top: 10px;
    width: 65%;
  }
  .technibusStyle {
    width: 80%;
  }
  .solconStyle {
    width: 80%;
  }
  .ascoStyle {
    width: 80%;
  }
  .fanoxStyle {
    width: 60%;
  }
  .powerMeasuerStyle {
    width: 80%;
  }
  .schneiderStyle {
    width: 80%;
  }
  .productsTitle {
    font-size: 1.5rem;
  }
}
@media (min-width: 1082px) {
  .mainTitleBox {
    height: 90px;
    width: 80px;
    position: relative;
    /* border-bottom: 2px solid #f51c40; */
  }

  .mainDiv:after {
    content: "";
    position: absolute;
    right: 0;
    z-index: 100;
    top: 0;
    margin-top: 18%;
    width: 2px;
    height: 50%; /* or 100px */
    background: #ccc;
  }
}
</style>
<style scoped>
span.my-list li {
  list-style: none;
  display: inline-block;
  padding-bottom: 2px; /*the space for the gradient*/
  background: linear-gradient(rgb(255, 255, 255), rgb(145, 145, 145)) center
    bottom; /*OR bottom right OR bottom left*/
  background-size: 0% 2px; /*width:0% height:2px*/
  background-repeat: no-repeat; /* Don't repeat !!*/
  transition: all 0.45s;
}

span.my-list li a {
  color: #ccc;
  text-decoration: none;
}

span.my-list li:hover {
  background-size: 100% 1px; /*width:100% height:2px*/
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes opacityChange {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.card {
  width: 600px;
  border: none;
  border-radius: 10px;

  background-color: #fff;
}

.stats {
  background: #f2f5f8 !important;

  color: #000 !important;
}
.articles {
  font-size: 14px;
  font-weight: 500;
  /* color: #a1aab9; */
  color: #555;
}
.number1 {
  font-weight: 500;
}
.followers {
  font-size: 10px;
  color: #a1aab9;
}
.number2 {
  font-weight: 500;
}
.rating {
  font-size: 10px;
  color: #a1aab9;
}
.number3 {
  font-weight: 500;
}
</style>
