<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead></thead>
            <tbody v-if="subId != '005-004'">
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationNewVenderNo" class="form-label"
                        >供應商編號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewVenderNo"
                        :disabled="subId != '005-001'"
                        v-model="currentVenderNo"
                        required
                      />
                      <div class="invalid-feedback">供應商編號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewVenderName" class="form-label"
                        >供應商名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewVenderName"
                        :disabled="subId == '005-003'"
                        v-model="currentVenderCname"
                        required
                      />
                      <div class="invalid-feedback">供應商名稱不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewTaxIdNo" class="form-label"
                        >統一編號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewTaxIdNo"
                        v-model="currentTaxIdNo"
                        :disabled="subId != '005-001'"
                        required
                      />
                      <div class="invalid-feedback">統一編號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewHead" class="form-label"
                        >公司負責人</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewHead"
                        v-model="currentHead"
                        :disabled="subId == '005-003'"
                        required
                      />
                      <div class="invalid-feedback">公司負責人不得為空</div>
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationNewContactPerson" class="form-label"
                        >聯絡人</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewContactPerson"
                        :disabled="subId == '005-003'"
                        v-model="currentContactPerson"
                        required
                      />
                      <div class="invalid-feedback">聯絡人不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewPhone1" class="form-label"
                        >聯絡電話1</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewPhone1"
                        :disabled="subId == '005-003'"
                        v-model="currentPhone1"
                        required
                      />
                      <div class="invalid-feedback">聯絡電話1不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNewFax" class="form-label"
                        >傳真</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewFax"
                        :disabled="subId == '005-003'"
                        v-model="currentFax"
                      />
                    </div>
                    <div class="col-12">
                      <label
                        for="validationNewCompanyAddress"
                        class="form-label"
                        >公司地址</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewCompanyAddress"
                        :disabled="subId == '005-003'"
                        v-model="currentCompanyAddress"
                        required
                      />
                      <div class="invalid-feedback">公司地址不得為空</div>
                    </div>
                    <div class="col-12">
                      <label for="validationNewCertificate" class="form-label"
                        >證照</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewCertificate"
                        :disabled="subId == '005-003'"
                        v-model="currentCertificate"
                      />
                    </div>
                    <div class="col-12">
                      <label for="validationNewProducts" class="form-label"
                        >供應產品</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewProducts"
                        :disabled="subId == '005-003'"
                        v-model="currentProducts"
                      />
                    </div>
                    <div v-if="subId != '005-001'" class="col-12">
                      <label for="validationNewPurchases" class="form-label"
                        >採購額</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        id="validationNewPurchases"
                        :disabled="subId == '005-002' || subId == '005-003'"
                        v-model="currentPurchases"
                      />
                    </div>
                    <div class="col-12">
                      <label for="validationNewNote" class="form-label"
                        >備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewNote"
                        :disabled="subId == '005-003'"
                        v-model="currentNote"
                      />
                    </div>
                    <div class="col-12">
                      <label for="validationNewComment" class="form-label"
                        >評價</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNewComment"
                        :disabled="subId == '005-003'"
                        v-model="currentComment"
                      />
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '005-001' ||
                      subId == '005-002' ||
                      subId == '005-003'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>證照:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentCertificateSrcList"
                        :key="k"
                      >
                        <img
                          class="col-9 objImg"
                          :src="src.currentCertificateSrc"
                        />
                        <span
                          class="col-2"
                          v-if="subId == '005-002'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteCertificateImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="subId == '005-001' || subId == '005-002'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addSignatureBtn"
                    >
                      上傳證照
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      multiple
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVenderBtn"
              v-if="subId == '005-003'"
              @click="doDeleteVender()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '005-001'"
              @click="doNewAndEditVender(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '005-002'"
              @click="doNewAndEditVender(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-primary"
              id="changePasswordBtn"
              v-if="subId == '005-004'"
              @click="doChangePassword()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <!-- <SearchBar
      v-model="keyword"
      :clickSearchBtn="searchBtn"
      :roleList="roleList"
    ></SearchBar> -->
    <form
      v-if="subId != '005-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        f
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["cname"] }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '005-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentVenderList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '005-002'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '005-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '005-004'"
                @click="showConfirmModal(item, '變更密碼', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-key"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "供應商名稱",
      nameColTitle: "供應商名稱",
      confirmModalTitle: "",
      // API
      venderList: [],
      currentVenderList: [],

      // params
      currentVenderId: "",
      currentVenderNo: "",
      currentVenderCname: "",
      currentTaxIdNo: "",
      currentHead: "",
      currentContactPerson: "",
      currentPhone1: "",
      currentFax: "",
      currentCompanyAddress: "",
      currentCertificate: "",
      currentCertificateSrc: null,
      currentCertificateSrcList: [],
      currentCertificateFileList: [],
      tmpDeleteImgList: [],
      currentProducts: "",
      currentPurchases: "",
      currentNote: "",
      currentComment: "",
      currentFiles: null,
      //
      // search
      search: "",
      autoComplete: false,
    };
  },

  setup() {},
  mounted() {
    this.doGetVenderList();
  },
  methods: {
    showConfirmModal(verdenItem, modalTitle, isEdit) {
      // $("#validationNewRoleName").val("");
      // this.doGetPermissionItemList();
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      if (verdenItem != "") {
        this.currentVenderId = verdenItem.id;
        this.getVenderCertificateFileObjs();

        this.currentVenderNo = verdenItem.venderNo;
        this.currentVenderCname = verdenItem.cname;
        this.currentTaxIdNo = verdenItem.taxIdNo;
        this.currentHead = verdenItem.head;
        this.currentContactPerson = verdenItem.contactPerson;
        this.currentPhone1 = verdenItem.phone1;
        this.currentFax = verdenItem.fax;
        this.currentCompanyAddress = verdenItem.companyAddress;
        this.currentCertificate = verdenItem.certificate;
        this.currentProducts = verdenItem.products;
        this.currentCertificateSrcList = [];
        this.tmpDeleteImgList = [];
        this.currentNote = verdenItem.note;
        this.currentComment = verdenItem.comment;
      }
    },
    resetConfirmModal() {
      this.currentVenderId = "";
      this.currentVenderNo = "";
      this.currentVenderCname = "";
      this.currentTaxIdNo = "";
      this.currentHead = "";
      this.currentContactPerson = "";
      this.currentPhone1 = "";
      this.currentFax = "";
      this.currentCompanyAddress = "";
      this.currentCertificate = "";
      this.currentProducts = "";
      this.currentPurchases = "";
      this.currentNote = "";
      this.currentComment = "";
      this.currentFiles = null;
      // this.currentSignature = null;
    },
    doNewAndEditVender(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentVenderNo == "") {
        Toast_Lib.errorToast("供應商編號不得為空");
        return;
      }

      if (this.currentVenderCname == "") {
        Toast_Lib.errorToast("供應商名稱不得為空");
        return;
      }

      if (this.currentTaxIdNo == "") {
        Toast_Lib.errorToast("統一編號不得為空");
        return;
      }

      if (this.currentHead == "") {
        Toast_Lib.errorToast("公司負責人不得為空");
        return;
      }

      if (this.currentContactPerson == "") {
        Toast_Lib.errorToast("聯絡人不得為空");
        return;
      }

      if (this.currentPhone1 == "") {
        Toast_Lib.errorToast("電話不得為空");
        return;
      }

      if (this.currentCompanyAddress == "") {
        Toast_Lib.errorToast("公司地址不得為空");
        return;
      }

      if (this.currentProducts == "") {
        Toast_Lib.errorToast("供應產品不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newVender";
      var toastMsg = "新增供應商";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateVender";
        toastMsg = "編輯供應商";
      }

      // payload
      const formData = new FormData();
      if (this.currentFiles != null) {
        for (var i = 0; i < this.currentFiles.length; i++) {
          formData.append("files", this.currentFiles[i]);
        }
      }

      if (isEdit) {
        formData.append("updateUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("deleteFileIds", this.tmpDeleteImgList);
        formData.append("venderId", this.currentVenderId);
      } else {
        formData.append("createUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("venderNo", this.currentVenderNo);
        formData.append("taxIdNo", this.currentTaxIdNo);
      }
      formData.append("userId", Common_Lib.getLocal("userId"));

      formData.append("cname", this.currentVenderCname);
      formData.append("head", this.currentHead);
      formData.append("contactPerson", this.currentContactPerson);
      formData.append("phone1", this.currentPhone1);
      formData.append("fax", this.currentFax);
      formData.append("companyAddress", this.currentCompanyAddress);
      formData.append("certificate", this.currentCertificate);
      formData.append("products", this.currentProducts);
      formData.append("note", this.currentNote);
      formData.append("comment", this.currentComment);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetVenderList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    doDeleteVender() {
      var url = Common_Lib.getServerUrl() + "/deleteVender";

      var jsonObj = {};
      jsonObj.venderId = this.currentVenderId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除供應商失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除供應商成功", "");

              this.doGetVenderList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    deleteCertificateImg(fileId) {
      this.tmpDeleteImgList.push(fileId);
      this.getVenderCertificateFileObjs();
    },
    getVenderCertificateFileObjs() {
      var url = Common_Lib.getServerUrl() + "/getVenderCertificateFileObjs";
      var jsonObj = {};
      jsonObj.venderId = this.currentVenderId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得供應商證照檔案失敗", result.errorMsg);
            } else {
              result = response.data;

              this.currentCertificateSrcList = [];
              this.currentCertificateFileList =
                result.result["venderCertificateFileObjs"];

              var fileIds = [];
              for (var i = 0; i < this.currentCertificateFileList.length; i++) {
                var fileObj = this.currentCertificateFileList[i];
                if (!this.tmpDeleteImgList.includes(fileObj.id)) {
                  fileIds.push(fileObj.id);
                }
              }
              this.getVenderCertificateFiles(fileIds, 0);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    getVenderCertificateFiles(fileIds, idx) {
      // 等一張完成再做下一張, 確保順序
      if (idx >= fileIds.length) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getFileResouce";
      var fileId = fileIds[idx];
      var jsonObj = {};
      jsonObj.fileId = fileId;

      axios
        .post(url, jsonObj, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            let result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得供應商證照檔案失敗", result.errorMsg);
            } else {
              result = response.data;

              var data = new Uint8Array(result);
              // var raw = String.fromCharCode.apply(null, data);
              var raw = "";

              for (let i = 0, l = data.length; i < l; i++) {
                raw += String.fromCharCode(data[i]);
              }

              var base64 = btoa(raw);
              // if (base64 == "") {
              //   do nothing
              // }
              var src = "data:image/jpeg;base64," + base64;
              var tmpObj = {};
              tmpObj.currentCertificateSrc = src;
              tmpObj.fileId = fileId;
              this.src = src;
              this.currentCertificateSrcList.push(tmpObj);
              this.getVenderCertificateFiles(fileIds, idx + 1);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVenderList() {
      var url = Common_Lib.getServerUrl() + "/getVenderList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.venderList = [];
            this.currentVenderList = [];

            result = response.data;
            this.venderList = result.result["venderList"];
            this.currentVenderList = this.venderList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(venderItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "005-002") {
        modalTitle = "編輯";
        isEdit = true;
      } else {
        modalTitle = "確認刪除?";
        isEdit = false;
      }
      this.showConfirmModal(venderItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentVenderList = [];
      if (this.search == "") {
        this.currentVenderList = this.venderList;
      } else {
        for (var i = 0; i < this.venderList.length; i++) {
          var venderItem = this.venderList[i];
          if (venderItem["cname"].includes(this.search)) {
            this.currentVenderList.push(venderItem);
          }
        }
      }
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.venderList.forEach((item) => {
        if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
