<template>
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <div class="row">
      <div class="col-12" style="padding-bottom: 20px">
        <form
          v-if="subId == '020-001'"
          class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
        >
          <!--     這裡是搜尋列 -->
          <div class="input-group w-md-50 me-2 col-8">
            <div class="col-lg-3 col-12">
              <label
                for="validationStartDate"
                class="form-label"
                style="font-weight: bold; color: #666"
                >開始日期</label
              >
              <input
                type="date"
                class="form-control"
                id="validationStartDate"
                v-model="currentStartDate"
              />
            </div>
            <div class="col-lg-3 col-12" style="padding-left: 10px">
              <label
                for="validationEndDate"
                class="form-label"
                style="font-weight: bold; color: #666"
                >結束日期</label
              >
              <input
                type="date"
                class="form-control"
                id="validationEndDate"
                v-model="currentEndDate"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="col-4" style="margin: 10px 0px">
        <span class="w-100 p-b-20" style="font-weight: bold; color: #666">
          請選擇訊息種類
        </span>
        <select
          class="form-select"
          id="validationEventlogType"
          v-model="currentEventlogTypeItem"
          @change="eventlogTypeOnChange"
        >
          <option v-for="v in currentEventlogTypeList" :key="v">
            <a class="dropdown-item">{{ v }}</a>
          </option>
        </select>
      </div>
      <div class="col-8" style="text-align: bottom">
        <form
          v-if="subId == '020-001'"
          class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
        >
          <!--     這裡是搜尋列 -->
          <div class="input-group w-md-50 me-2">
            <span
              class="w-100 p-b-20"
              style="font-weight: bold; color: #666; padding-top: 10px"
              >&nbsp;
            </span>
            <input
              class="form-control"
              type="search"
              v-model.trim="search"
              @keyup="keyboardEvent"
              placeholder="請輸入搜尋關鍵字"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
            <div
              class="col-lg-8 col-12"
              style="display: flex; align-items: end; padding-left: 10px"
            >
              <button
                class="btn btn-primary"
                style="padding-left: 10px"
                type="button"
                id="button-addon2"
                @click="doGetEventlogList"
              >
                <i class="fa-solid fa-magnifying-glass text-white"></i
                >&ensp;點擊搜尋
              </button>
            </div>
          </div>
          <!--     這裡是搜尋列 -->
        </form>
      </div>
    </div>
    <div class="table-container" style="margin-top: 40px">
      <span
        v-if="currentEventlogList.length == 0"
        style="font-weight: bold; color: #666; padding: 20px"
        >無資料</span
      >
      <table v-if="currentEventlogList.length > 0" class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>日期</th>
            <th>類別</th>
            <th>訊息</th>
            <th style="text-align: right"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, key) in currentEventlogList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createAt"].split(".")[0].replaceAll("T", " ") }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["mainType"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["description"] }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "訂單名稱",
      nameColTitle: "訂單名稱",
      confirmModalTitle: "",
      orderStausConvert: {
        NOTREVIEW: "未覆核",
        NOTCONFIRM: "未確認",
        FINISH: "結單",
      },

      // API
      eventlogTypeList: [],
      currentEventlogTypeList: [
        "使用者管理",
        "客戶資料管理",
        "供應商管理",
        "物料進銷存設定",
        "訂單管理",
        "參數設定",
      ],
      currentEventlogTypeItem: null,
      currentEventlogList: [],

      // params
      currentStartDate: "",
      currentEndDate: "",

      //
      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchProductText: "",
      autoCompleteProduct: false,
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  mounted() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    this.currentStartDate = yyyy + "-" + mm + "-" + dd;
    this.currentEndDate = yyyy + "-" + mm + "-" + dd;

    this.doGetEventlogList();
  },
  methods: {
    doGetEventlogList() {
      var url = Common_Lib.getServerUrl() + "/getEventlogList";
      let result = null;
      let jsonObj = {};

      if (this.currentStartDate == "") {
        Toast_Lib.errorToast("開始日期不得為空");
        return;
      }
      if (this.currentEndDate == "") {
        Toast_Lib.errorToast("結束日期不得為空");
        return;
      }
      jsonObj.startDate = this.currentStartDate;
      jsonObj.endDate = this.currentEndDate;
      jsonObj.mainType = this.currentEventlogTypeItem;
      jsonObj.keyword = this.search;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentEventlogList = result.result["eventlogList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    doGetOrderListByTimeRange(id) {
      var url = Common_Lib.getServerUrl() + "/getConfirmTimeRangeOrderList";
      let result = null;
      var jsonObj = {};
      if (this.subId == "012-002") {
        if (this.currentStartDate == "") {
          Toast_Lib.errorToast("開始日期不得為空");
          return;
        }
        if (this.currentEndDate == "") {
          Toast_Lib.errorToast("結束日期不得為空");
          return;
        }
        jsonObj.startDate = this.currentStartDate;
        jsonObj.endDate = this.currentEndDate;
      }

      // just finish?
      if (document.getElementById("checkWithoutFinish") == null) {
        jsonObj.withoutFinish = false;
      } else {
        jsonObj.withoutFinish =
          document.getElementById("checkWithoutFinish").checked;
      }

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.orderList = result.result["timeRangeOrderList"];
            this.currentOrderList = this.orderList;

            this.tmpComponentMap = result.result["tmpComponentMap"];
            this.tmpVehicleMap = result.result["tmpVehicleMap"];
            this.customerIdNameMap = result.result["customerIdNameMap"];

            if (id != "") {
              this.tmpSelectComponentList = this.tmpComponentMap[id];
              this.tmpSelectVehicleList = this.tmpVehicleMap[id];
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // get combobox list
    doGetCompanyTitleList(customerLevelId) {
      var url = Common_Lib.getServerUrl() + "/getCompanyTitleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.companyTitleList = [];
            this.currentCompanyTitleList = [];

            result = response.data;
            this.companyTitleList = result.result["companyTitleList"];
            this.currentCompanyTitleList = this.companyTitleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerList(id) {
      var url = Common_Lib.getServerUrl() + "/getCustomerList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerList = [];
            this.currentCustomerList = [];

            result = response.data;
            this.customerList = result.result["customerList"];
            this.currentCustomerList = this.customerList;

            if (id != "") {
              this.getCustomerItem(id);
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetUserList() {
      var url = Common_Lib.getServerUrl() + "/getUserList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.userList = result.result["userList"];
            this.currentUserList = this.userList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            // this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetAllCustomerLevelPriceMap() {
      if (this.currentCustomerItem == null) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getAllCustomerLevelPriceMap";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerItem.customerLevelId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶分類價格失敗", result.errorMsg);
            } else {
              this.allCustomerLevelPriceMap =
                result.result["allCustomerLevelPriceMap"];
              if (this.currentProductItem != null) {
                this.currentUnitPrice =
                  this.allCustomerLevelPriceMap[this.currentProductItem.id];
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    addTmpProduct() {
      if (
        this.currentProductQuantity == null ||
        this.currentProductQuantity < 1 ||
        this.currentUnitPrice == null ||
        this.currentUnitPrice < 1 ||
        this.currentTax == null ||
        this.currentTax < 1 ||
        this.currentProductItem == null
      ) {
        Toast_Lib.errorToast("無法加入, 請填寫所有欄位");
        return;
      }

      let productObj = {};
      if (!this.checkTmpProductList(this.currentProductItem.id)) {
        Toast_Lib.errorToast(
          "無法加入, 該品項已存在: " + this.currentProductItem.cname
        );
        return;
      }
      productObj.productId = this.currentProductItem.id;
      productObj.cname = this.currentProductItem.cname;
      productObj.partNo = this.currentProductItem.partNo;
      productObj.quantity = this.currentProductQuantity;
      productObj.unitPrice = this.currentUnitPrice;
      productObj.priceWithTax = Math.floor(
        this.currentProductQuantity *
          this.currentUnitPrice *
          (1 + this.currentTax / 100)
      );

      if (this.currentComponentTypeItem == "零件") {
        productObj.type = "component";
        this.tmpSelectComponentList.push(productObj);
      } else if (this.currentComponentTypeItem == "完成車") {
        productObj.type = "vehicle";
        this.tmpSelectVehicleList.push(productObj);
      }
    },
    checkTmpProductList(id) {
      let targetList = [];
      if (this.currentComponentTypeItem == "零件") {
        targetList = this.tmpSelectComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId == id) {
          return false;
        }
      }
      return true;
    },
    removeTmpProduct(id, t) {
      let targetList = [];
      let resultList = [];
      if (t == "component") {
        targetList = this.tmpSelectComponentList;
      } else if (t == "vehicle") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId != id) {
          resultList.push(obj);
        }
      }
      if (t == "component") {
        this.tmpSelectComponentList = resultList;
      } else if (t == "vehicle") {
        this.tmpSelectVehicleList = resultList;
      }
      resultList = [];
    },
    // for search bar function
    clickSearchItem(customerItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "012-001") {
        modalTitle = "訂單檢視";
        isEdit = false;
      }
      //  else {
      //   modalTitle = "確認刪除?";
      //   isEdit = false;
      // }
      this.showConfirmModal(customerItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentOrderList = [];
      if (this.search == "") {
        this.currentOrderList = this.orderList;
      } else {
        for (var i = 0; i < this.orderList.length; i++) {
          var orderItem = this.orderList[i];
          if (
            orderItem["companyTitle"].includes(this.search) ||
            orderItem["orderNo"].includes(this.search) ||
            this.customerIdNameMap[orderItem["customerId"]].includes(
              this.search
            )
          ) {
            this.currentOrderList.push(orderItem);
          }
        }
      }
    },
    clickSearchProduct(item) {
      this.currentProductItem = item;
      this.currentPreviewItem = item;
      this.autoCompleteProduct = false;
      this.searchProductText = "";
      this.doGetAllCustomerLevelPriceMap();
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      // if (this.searchProductText) {
      //   console.log("tt");
      //   this.autoCompleteProduct = true;
      // } else {
      //   console.log("ff");
      //   this.autoCompleteProduct = false;
      // }
    },
  },

  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
    searchProductText() {
      if (this.searchProductText) {
        this.autoCompleteProduct = true;
      } else {
        this.autoCompleteProduct = false;
      }
    },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.orderList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.companyTitle.includes(searchKeyword) ||
            item.orderNo.includes(this.search) ||
            this.customerIdNameMap[item["customerId"]].includes(this.search))
        ) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchProductText;
      // 比對字串
      this.currentProductList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.cname.includes(searchKeyword) ||
            item.partNo.includes(searchKeyword))
        ) {
          arr.push(item);
        }
      });
      // if (arr.length > 0) {
      //   this.autoComplete = true;
      // } else {
      //   this.autoComplete = false;
      // }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
