const axios = require("axios").default;
const Toast_Lib = require("../js/Toast_Lib");
import CryptoJS from "crypto-js";

//const serverUrl = "http://127.0.0.1:8090";
const serverUrl = "http://97.74.90.52:8090";
const version = "v0.0.1";

function getLocal(k) {
  if (localStorage.getItem(k) === null) {
    return "";
  }
  return localStorage.getItem(k);
}

function getLocalAndDecrypt(key) {
  var item = localStorage.getItem(key);
  if (item === null) {
    return "";
  }

  var token = localStorage.getItem("token");
  var secretKey = "k" + token + "k";
  var result = doDecrypt(item, secretKey);

  return result;
}

function getServerUrl() {
  return serverUrl;
}

function getVersion() {
  return version;
}

function doDecrypt(cryptStr, key) {
  return CryptoJS.AES.decrypt(cryptStr, key).toString(CryptoJS.enc.Utf8);
}

// WeiSheng ERP
function doDecryptPermissionStrToList(encryptPermissionStr) {
  // get user permission
  var secretKey = "k" + getLocal("token") + "k";
  var permissionListStr = doDecrypt(encryptPermissionStr, secretKey);
  var permissionList = permissionListStr.split(",");
  return permissionList;
}

export {
  getLocal,
  getLocalAndDecrypt,
  doDecrypt,
  getServerUrl,
  getVersion,
  doDecryptPermissionStrToList,
};
