<template>
  <Header></Header>

  <div class="wrapper setCenter" style="margin-top: 40px">
    <div class="row col-12 col-md-7">
      <div
        class="col-4"
        v-for="(value, key) in mainPermissionItemsMap"
        :key="key"
      >
        <button
          class="btn btn-outline-secondary functionButton"
          :disabled="
            value.id != '001' &&
            value.id != '002' &&
            value.id != '003' &&
            value.id != '005' &&
            value.id != '006' &&
            value.id != '007' &&
            value.id != '008' &&
            value.id != '010' &&
            value.id != '012' &&
            value.id != '014' &&
            value.id != '015' &&
            value.id != '016' &&
            value.id != '017' &&
            value.id != '020'
          "
          @click="toFunctionDetail(value.id)"
        >
          <i class="fa-solid fa-file-circle-plus functionButtonIcon"></i
          >&ensp;{{ value["permissionCname"] }}
          <div
            v-if="value.id == '007' && needShowExclamation"
            style="color: #dc3545; float: right"
          >
            <i class="fa-solid fa-circle-exclamation"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

import "../css/profileModal.css";

var myID = "Account";
const Toast_Lib = require("../js/Toast_Lib");
const Common_Lib = require("../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: { Header },
  data() {
    return {
      mainPermissionItemsMap: {},
      needShowExclamation: false,
    };
  },

  setup() {
    Toast_Lib.installUserProfileModal();
    Toast_Lib.initUserProfileModal();
  },
  mounted() {
    this.doGetUserPermission();
    this.doGetNotConfirmNetworkOrderList();
  },
  methods: {
    toFunctionDetail(funcId) {
      this.$router.push("/subFunction/" + funcId);
    },
    doGetUserPermission() {
      var url = Common_Lib.getServerUrl() + "/getPermissionByRoleId";
      let result = null;

      var secretKey = "k" + Common_Lib.getLocal("token") + "k";

      var jsonObj = {};
      jsonObj.roleId = Common_Lib.doDecrypt(
        Common_Lib.getLocal("roleId"),
        secretKey
      );

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得權限失敗", result.errorMsg);
            } else {
              this.mainPermissionItemsMap = {};

              var allMainPermission = result.result["mainPermissionList"];
              this.mainPermissionItemsMap = allMainPermission;
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetNotConfirmNetworkOrderList() {
      var url = Common_Lib.getServerUrl() + "/getNotConfirmNetworkOrder";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.result["networkOrderList"].length > 0) {
              this.needShowExclamation = true;
            } else {
              this.needShowExclamation = false;
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
  },

  computer: {},
};
</script>

<style>
@media (max-width: 992px) {
  .fromIp {
    display: none;
  }
  .title {
    font-size: 16px !important;
  }
}
/*	--------------------------------------------------
	:: Table Filter
	-------------------------------------------------- */
.panel {
  border: 1px solid #ddd;
  background-color: #fcfcfc;
}
.panel .btn-group {
  margin: 15px 0 15px;
}
.panel .btn-group .btn {
  transition: background-color 0.3s ease;
}
.table-filter {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
.table-filter tbody tr:hover {
  cursor: pointer;
  background-color: #eee;
}
.table-filter tbody tr td {
  padding: 10px;
  vertical-align: middle;
  border-top-color: #eee;
}
.table-filter tbody tr.selected td {
  background-color: #eee;
}
.table-filter tr td:first-child {
  width: 38px;
}
.table-filter tr td:nth-child(2) {
  width: 35px;
}
.ckbox {
  position: relative;
}
.ckbox input[type="checkbox"] {
  opacity: 0;
}
.ckbox label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ckbox label:before {
  content: "";
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  border-radius: 2px;
  border: 1px solid #000;
  background-color: #fff;
}
.ckbox input[type="checkbox"]:checked + label:before {
  /* border-color: #2bbcde;
  background-color: #2bbcde; */
  border-color: #000;
  background-color: #fff;
}
.ckbox input[type="checkbox"]:checked + label:after {
  top: -5px;
  left: 1px;
  content: "✔";
  color: #222;
  font-size: 20px;
  /* font-family: "Glyphicons Halflings"; */
  position: absolute;
}
.table-filter .star {
  color: #ccc;
  text-align: center;
  display: block;
}
.table-filter .star.star-checked {
  color: #f0ad4e;
}
.table-filter .star:hover {
  color: #ccc;
}
.table-filter .star.star-checked:hover {
  color: #f0ad4e;
}
.table-filter .media-photo {
  width: 35px;
}
.table-filter .media-body {
  display: block;
  /* Had to use this style to force the div to expand (wasn't necessary with my bootstrap version 3.3.6) */
}
.table-filter .media-meta {
  font-size: 11px;
  color: #999;
}
.table-filter .media .title {
  color: #2bbcde;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  margin: 0;
}
.table-filter .media .title span {
  font-size: 0.8em;
  margin-right: 20px;
}
.table-filter .media .title span.pagado {
  color: #5cb85c;
}
.table-filter .media .title span.pendiente {
  color: #f0ad4e;
}
.table-filter .media .title span.cancelado {
  color: #d9534f;
}
.table-filter .media .summary {
  font-size: 14px;
}

/*********************** */
/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #6a7dfe;
  color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
  color: -o-linear-gradient(left, #21d4fd, #b721ff);
  color: -moz-linear-gradient(left, #21d4fd, #b721ff);
  color: linear-gradient(left, #21d4fd, #b721ff);
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////*/
</style>
