<template>
  <!-- modify stock modal -->
  <div
    class="modal fade"
    id="modifyStockModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeModifyStockModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentCname2" class="form-label"
                        >零件名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentCname2"
                        disabled="true"
                        v-model="currentComponentCname"
                        required
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationPartNo" class="form-label"
                        >料號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationPartNo"
                        disabled="true"
                        v-model="currentPartNo"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="validationComponentType2" class="form-label"
                        >零件分類</label
                      >
                      <select
                        class="form-select"
                        id="validationComponentType2"
                        disabled="true"
                        v-model="currentComponentTypeItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentComponentTypeList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                    </div>
                  </form>
                </th>
              </tr>
            </thead>
            <tbody>
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-md-6">
                      <label for="validationVender2" class="form-label"
                        >供應商</label
                      >
                      <select
                        class="form-select"
                        id="validationVender2"
                        disabled="true"
                        v-model="currentVenderItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVenderList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="validationComponentUnit2" class="form-label"
                        >單位</label
                      >
                      <select
                        class="form-select"
                        id="validationComponentUnit2"
                        disabled="true"
                        v-model="currentComponentUnitItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentComponentUnitList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentModel" class="form-label"
                        >零件型號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentModel"
                        disabled="true"
                        v-model="currentComponentModel"
                        required
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentColor2" class="form-label"
                        >零件顏色</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentColor2"
                        disabled="true"
                        v-model="currentComponentColor"
                        required
                      />
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationSafetyStock" class="form-label"
                        >安全庫存</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="validationSafetyStock"
                        disabled="true"
                        v-model="currentSafetyStock"
                        required
                      />
                    </div>
                  </form>
                  <hr />
                  <h4 style="font-weight: bold; padding: 10px 0px">
                    編輯庫存 (現在庫存:&ensp;{{ currentStockFix }}):
                  </h4>
                  <div class="row col-lg-6 col-12" style="padding-bottom: 20px">
                    <div class="col-md-6">
                      <label for="validationVehicleCategory" class="form-label"
                        >請選擇方式</label
                      >
                      <select
                        class="form-select"
                        id="validationModifyStockMethod"
                        v-model="currentModifyStockMethodItem"
                        @change="modifyStockMethodOnChange()"
                        required
                      >
                        <option
                          v-for="v in currentModifyStockMethodList"
                          :key="v"
                        >
                          <a class="dropdown-item">{{ v }}</a>
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label for="validationStock" class="form-label"
                        >修改量</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="validationModifyStock"
                        v-model="currentModifyStock"
                        required
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-primary"
              id="modifyStockBtn"
              v-if="subId == '002-007'"
              @click="doModifyStock(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of compnent (002-001~002-003)-->
        <!-------------------------->
        <!-------------------------->
        <!--vehicle (002-004~002-006)-->
        <!-------------------------->
        <div
          v-if="subId == '002-004' || subId == '002-005' || subId == '002-006'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="text-align: right">
                    <button
                      class="btn btn-outline-success"
                      id="showCopyVehicleComponentBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#copyVehicleModal"
                      v-if="subId == '002-004'"
                      @click="showCopyVehicleComponent()"
                    >
                      <i class="fa-solid fa-copy"></i>&ensp;複製已有車輛零件
                    </button>
                  </div>
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationVehicleCname2" class="form-label"
                        >車輛名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehicleCname2"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleCname"
                        required
                      />
                      <div class="invalid-feedback">車輛名稱不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationVehiclePartNo" class="form-label"
                        >料號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehiclePartNo"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehiclePartNo"
                        required
                      />
                      <div class="invalid-feedback">料號不得為空</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCategory" class="form-label"
                        >車輛分類</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCategory"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleCategoryItem"
                        @change="
                          vehicleCategoryChange(currentVehicleCategoryItem.id)
                        "
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCategoryList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛分類</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleStyle" class="form-label"
                        >車輛款式</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleStyle"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleStyleItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleStyleList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛款式</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleModel2" class="form-label"
                        >車型</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleModel2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleModelItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleModelList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車型</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleColor" class="form-label"
                        >車輛顏色</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleColor"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleColorItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleColorList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛顏色</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCode2" class="form-label"
                        >車廠代碼</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCode2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleCodeItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCodeList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車廠代碼</div>
                    </div>
                  </form>
                  <hr />
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSecurityReviewCode2"
                        class="form-label"
                        >安審代碼</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSecurityReviewCode2"
                        v-model="currentSecurityReviewCode"
                        required
                      />
                      <div class="invalid-feedback">安審代碼不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedLabel" class="form-label"
                        >合格標示廠牌</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedLabel"
                        v-model="currentQualifiedLabel"
                        required
                      />
                      <div class="invalid-feedback">合格標示廠牌不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedCode2" class="form-label"
                        >合格標示型號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedCode2"
                        v-model="currentQualifiedCode"
                        required
                      />
                      <div class="invalid-feedback">合格標示型號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSpecificationsNote2"
                        class="form-label"
                        >品名規格備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSpecificationsNote2"
                        v-model="currentSpecificationsNote"
                      />
                    </div>
                  </form>
                </th>
              </tr>
            </thead>

            <tbody
              v-if="
                subId == '002-004' || subId == '002-005' || subId == '002-006'
              "
            >
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <div style="padding: 20px 0px">
                    <span>零件資訊:&ensp;&ensp;</span>
                  </div>
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div
                      class="row"
                      v-for="v in currentComponentTypeList"
                      :key="v"
                    >
                      <div class="col-md-6" style="margin: 10px 0px">
                        <label
                          for="validationVender"
                          class="form-label"
                          style="font-weight: bold"
                          >{{ v }}</label
                        >

                        <form
                          v-if="subId == '002-004' || subId == '002-005'"
                          class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
                        >
                          <!--     這裡是搜尋列 -->
                          <div class="input-group w-md-50 me-2">
                            <input
                              class="form-control"
                              style="margin-bottom: 10px"
                              type="search"
                              v-model.trim="searchComponentMap[v]"
                              @keyup="keyboardEvent"
                              placeholder="快速搜尋"
                              aria-label="Search"
                              aria-describedby="button-addon2"
                              @click="clickSearchComponent(v)"
                            />
                          </div>
                          <!--     這裡是搜尋列 -->

                          <!--     這裡才是autoComplete的選單 -->
                          <!--     控制autoComplete的開闔 -->
                          <ul
                            class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
                            :class="autoCompleteMap[v] ? '' : 'd-none'"
                          >
                            <!--     控制按鈕事件的選取背景 -->
                            <li
                              class="searchHover p-2 w-100"
                              v-for="item in filterComponents"
                              :key="item"
                              @click="clickAddComponentItem(item)"
                            >
                              {{ item["cname"] }}
                            </li>
                          </ul>
                          <!--     這裡才是autoComplete的選單 -->
                        </form>
                        <select
                          class="form-select"
                          :id="v"
                          v-model="usedComponentMap[v]"
                          @change="clickAddComponentItem(usedComponentMap[v])"
                        >
                          <option
                            v-for="(value, key) in componentTypeMap[v]"
                            :key="key"
                            :value="value"
                          >
                            <a class="dropdown-item">{{ value.cname }}</a>
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-6"
                        style="
                          margin: 10px 0px;
                          border: solid #ccc 1px;
                          padding: 10px;
                        "
                      >
                        <div
                          class="row vehicleCategoryItem"
                          v-for="(k, v2) in tmpAddedComponentMap[v]"
                          :key="v2"
                          style="
                            width: max-content;
                            padding: 10px;
                            margin: 5px;
                            align-items: center;
                          "
                        >
                          &ensp;{{ k.cname }}&ensp;
                          <input
                            class="col-4"
                            type="number"
                            min="1"
                            v-model="vehicleComponentsCountMap[k['id']]"
                            placeholder="數量"
                          /><span
                            class="col-2"
                            v-if="subId == '002-004' || subId == '002-005'"
                            id="deleteFileBtn"
                            style="cursor: pointer; color: #c73f3f"
                            @click="clickDeleteComponentItem(k)"
                            ><i class="fa-regular fa-circle-xmark"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '002-004' ||
                      subId == '002-005' ||
                      subId == '002-006'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>照片:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentVehiclePicSrcList"
                        :key="k"
                      >
                        <img class="col-9 objImg" :src="src.currentPicSrc" />
                        <span
                          class="col-2"
                          v-if="subId == '002-004' || subId == '002-005'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteVehicleImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="subId == '002-004' || subId == '002-005'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addVehiclePicBtn"
                    >
                      上傳完成車照片
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      multiple
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVenderBtn"
              v-if="subId == '002-006'"
              @click="doDeleteVehicle()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '002-004'"
              @click="doNewAndEditVehicle(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '002-005'"
              @click="doNewAndEditVehicle(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of vehicle (002-004~002-006)-->
        <!-------------------------->
      </div>
    </div>
  </div>
  <!-- modify stock modal end -->
  <!-- copy vehicle modal -->
  <div
    class="modal fade"
    id="copyVehicleModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            請選擇要複製零件的車輛
          </h4>
          <span
            id="closeCopyVehicleBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>
        <table class="table table-filter">
          <thead>
            <tr>
              <th style="align-items: center; min-width: 300px">
                <form
                  class="row g-3 needs-validation"
                  style="margin-bottom: 20px"
                  novalidate
                >
                  <div class="offset-md-3 col-md-6 col-12">
                    <label for="validationCopyVehicle" class="form-label"
                      >完成車列表</label
                    >
                    <select
                      class="form-select"
                      id="validationCopyVehicle"
                      :disabled="subId != '002-004'"
                      v-model="currentCopyVehicleItem"
                      required
                    >
                      <option
                        v-for="(value, key) in currentVehicleList"
                        :key="key"
                        :value="value"
                      >
                        <a class="dropdown-item">{{ value.cname }}</a>
                      </option>
                    </select>
                  </div>
                </form>
              </th>
            </tr>
          </thead>
        </table>
        <div
          class="col-9 mx-3 setCenter"
          style="
            padding: 20px;
            margin-left: 28% !important ;
            justify-content: right;
          "
        >
          <button
            class="btn btn-outline-success"
            id="copyVehicleBtn"
            v-if="subId == '002-004'"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            @click="doCopyVehicle()"
          >
            <i class="fa-solid fa-copy"></i>&ensp;複製
          </button>
          <button
            class="btn btn-outline-secondary"
            id="cancelBtn"
            data-bs-dismiss="modal"
            style="margin-left: 10px"
          >
            <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <!-------------------------->
        <!--compnent (002-001~002-003)-->
        <!-------------------------->
        <div
          v-if="subId == '002-001' || subId == '002-002' || subId == '002-003'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentCname" class="form-label"
                        >零件名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentCname"
                        :disabled="subId != '002-001'"
                        v-model="currentComponentCname"
                        required
                      />
                      <div class="invalid-feedback">零件名稱不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationPartNo2" class="form-label"
                        >料號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationPartNo2"
                        :disabled="subId != '002-001'"
                        v-model="currentPartNo"
                        required
                      />
                      <div class="invalid-feedback">料號不得為空</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationComponentType" class="form-label"
                        >零件分類</label
                      >
                      <select
                        class="form-select"
                        id="validationComponentType"
                        :disabled="subId != '002-001'"
                        v-model="currentComponentTypeItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentComponentTypeList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇零件分類</div>
                    </div>
                  </form>
                </th>
              </tr>
            </thead>
            <tbody
              v-if="
                subId == '002-001' || subId == '002-002' || subId == '002-003'
              "
            >
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-md-6">
                      <label for="validationVender" class="form-label"
                        >供應商</label
                      >
                      <select
                        class="form-select"
                        id="validationVender"
                        :disabled="subId == '002-003'"
                        v-model="currentVenderItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVenderList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇供應商</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationComponentUnit" class="form-label"
                        >單位</label
                      >
                      <select
                        class="form-select"
                        id="validationComponentUnit"
                        :disabled="subId == '002-003'"
                        v-model="currentComponentUnitItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentComponentUnitList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇單位</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentModel2" class="form-label"
                        >零件型號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentModel2"
                        :disabled="subId == '002-003'"
                        v-model="currentComponentModel"
                        required
                      />
                      <div class="invalid-feedback">零件型號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationComponentColor" class="form-label"
                        >零件顏色</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationComponentColor"
                        :disabled="subId == '002-003'"
                        v-model="currentComponentColor"
                        required
                      />
                      <div class="invalid-feedback">零件顏色不得為空</div>
                    </div>

                    <div class="col-lg-6 col-12">
                      <label for="validationSafetyStock2" class="form-label"
                        >安全庫存</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="validationSafetyStock2"
                        :disabled="subId == '002-002' || subId == '002-003'"
                        v-model="currentSafetyStock"
                        required
                      />
                      <div class="invalid-feedback">
                        安全庫存不得為空, 或小於1
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationStock" class="form-label"
                        >現在庫存</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="validationStock"
                        :disabled="subId == '002-002' || subId == '002-003'"
                        v-model="currentStock"
                        required
                      />
                      <div class="invalid-feedback">
                        現在庫存不得為空, 或小於1
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationSpecifications" class="form-label"
                        >參數規格</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSpecifications"
                        v-model="currentSpecifications"
                        :disabled="subId == '002-003'"
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationNote" class="form-label"
                        >備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationNote"
                        :disabled="subId == '002-003'"
                        v-model="currentNote"
                      />
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '002-001' ||
                      subId == '002-002' ||
                      subId == '002-003'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>照片:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentComponentPicSrcList"
                        :key="k"
                      >
                        <img class="col-9 objImg" :src="src.currentPicSrc" />
                        <span
                          class="col-2"
                          v-if="subId == '002-001' || subId == '002-002'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteComponentImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="subId == '002-001' || subId == '002-002'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addComponentPicBtn"
                    >
                      上傳零件照片
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      multiple
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                  <hr />

                  <div class="col-12" style="margin: 5px 0 5px">
                    <span>價格資訊:&ensp;&ensp;</span>
                    <div class="row">
                      <form
                        class="row g-3 needs-validation"
                        style="margin-bottom: 20px"
                        novalidate
                      >
                        <div class="col-lg-4 col-12">
                          <label
                            for="validationComponentCost"
                            class="form-label"
                            >採購成本</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            id="validationComponentCost"
                            :disabled="subId == '002-003'"
                            v-model="currentComponentCost"
                            required
                          />
                          <div class="invalid-feedback">
                            成本不得為空, 或小於1
                          </div>
                        </div>
                      </form>
                      <div
                        class="row col-4"
                        v-for="(k, v) in currentCustomerLevelList"
                        :key="v"
                      >
                        <div class="col-12">
                          <label
                            for="validationComponentCost"
                            class="form-label"
                            >{{ k["customerLevelCname"] }}價格</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="k.id"
                            :disabled="subId == '002-003'"
                            v-model="customerLevelPriceMap[k['id']]"
                            required
                          />
                          <div class="invalid-feedback">
                            成本不得為空, 或小於1
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="subId == '002-002' || subId == '002-003'"
                      class="col-12"
                      style="margin: 5px 0 5px"
                    >
                      <span>適合車型:&ensp;&ensp;</span>
                      <div
                        class="row"
                        v-for="vehicleItem in componentVehicleList"
                        :key="vehicleItem"
                      >
                        <span
                          class="col-2 vehicleCategoryItem"
                          style="margin: 5px"
                        >
                          {{ vehicleItem.cname }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVenderBtn"
              v-if="subId == '002-003'"
              @click="doDeleteComponent()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '002-001'"
              @click="doNewAndEditComponent(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '002-002'"
              @click="doNewAndEditComponent(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of compnent (002-001~002-003)-->
        <!-------------------------->
        <!-------------------------->
        <!--vehicle (002-004~002-006)-->
        <!-------------------------->
        <div
          v-if="subId == '002-004' || subId == '002-005' || subId == '002-006'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="text-align: right">
                    <button
                      class="btn btn-outline-success"
                      id="showCopyVehicleComponentBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#copyVehicleModal"
                      v-if="subId == '002-004'"
                      @click="showCopyVehicleComponent()"
                    >
                      <i class="fa-solid fa-copy"></i>&ensp;複製已有車輛零件
                    </button>
                  </div>
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationVehicleCname" class="form-label"
                        >車輛名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehicleCname"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleCname"
                        required
                      />
                      <div class="invalid-feedback">車輛名稱不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationVehiclePartNo2" class="form-label"
                        >料號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehiclePartNo2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehiclePartNo"
                        required
                      />
                      <div class="invalid-feedback">料號不得為空</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCategory2" class="form-label"
                        >車輛分類</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCategory2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleCategoryItem"
                        @change="
                          vehicleCategoryChange(currentVehicleCategoryItem.id)
                        "
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCategoryList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛分類</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleStyle2" class="form-label"
                        >車輛款式</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleStyle2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleStyleItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleStyleList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛款式</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleModel" class="form-label"
                        >車型</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleModel"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleModelItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleModelList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車型</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleColor2" class="form-label"
                        >車輛顏色</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleColor2"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleColorItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleColorList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛顏色</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCode" class="form-label"
                        >車廠代碼</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCode"
                        :disabled="subId != '002-004' && subId != '002-005'"
                        v-model="currentVehicleCodeItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCodeList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車廠代碼</div>
                    </div>
                  </form>
                  <hr />
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSecurityReviewCode"
                        class="form-label"
                        >安審代碼</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSecurityReviewCode"
                        v-model="currentSecurityReviewCode"
                        required
                      />
                      <div class="invalid-feedback">安審代碼不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedLabel2" class="form-label"
                        >合格標示廠牌</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedLabel2"
                        v-model="currentQualifiedLabel"
                        required
                      />
                      <div class="invalid-feedback">合格標示廠牌不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedCode" class="form-label"
                        >合格標示型號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedCode"
                        v-model="currentQualifiedCode"
                        required
                      />
                      <div class="invalid-feedback">合格標示型號不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSpecificationsNote"
                        class="form-label"
                        >品名規格備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSpecificationsNote"
                        v-model="currentSpecificationsNote"
                      />
                    </div>
                  </form>
                </th>
              </tr>
            </thead>

            <tbody
              v-if="
                subId == '002-004' || subId == '002-005' || subId == '002-006'
              "
            >
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <div style="padding: 20px 0px">
                    <span>零件資訊:&ensp;&ensp;</span>
                  </div>
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div
                      class="row"
                      v-for="v in currentComponentTypeList"
                      :key="v"
                    >
                      <div class="col-md-6" style="margin: 10px 0px">
                        <label
                          for="validationVender"
                          class="form-label"
                          style="font-weight: bold"
                          >{{ v }}</label
                        >

                        <form
                          v-if="subId == '002-004' || subId == '002-005'"
                          class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
                        >
                          <!--     這裡是搜尋列 -->
                          <div class="input-group w-md-50 me-2">
                            <input
                              class="form-control"
                              style="margin-bottom: 10px"
                              type="search"
                              v-model.trim="searchComponentMap[v]"
                              @keyup="keyboardEvent"
                              placeholder="快速搜尋"
                              aria-label="Search"
                              aria-describedby="button-addon2"
                              @click="clickSearchComponent(v)"
                            />
                          </div>
                          <!--     這裡是搜尋列 -->

                          <!--     這裡才是autoComplete的選單 -->
                          <!--     控制autoComplete的開闔 -->
                          <ul
                            class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
                            :class="autoCompleteMap[v] ? '' : 'd-none'"
                          >
                            <!--     控制按鈕事件的選取背景 -->
                            <li
                              class="searchHover p-2 w-100"
                              v-for="item in filterComponents"
                              :key="item"
                              @click="clickAddComponentItem(item)"
                            >
                              {{ item["cname"] }}
                            </li>
                          </ul>
                          <!--     這裡才是autoComplete的選單 -->
                        </form>
                        <select
                          class="form-select"
                          :id="v"
                          v-model="usedComponentMap[v]"
                          @change="clickAddComponentItem(usedComponentMap[v])"
                        >
                          <option
                            v-for="(value, key) in componentTypeMap[v]"
                            :key="key"
                            :value="value"
                          >
                            <a class="dropdown-item">{{ value.cname }}</a>
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-6"
                        style="
                          margin: 10px 0px;
                          border: solid #ccc 1px;
                          padding: 10px;
                        "
                      >
                        <div
                          class="row vehicleCategoryItem"
                          v-for="(k, v2) in tmpAddedComponentMap[v]"
                          :key="v2"
                          style="
                            width: max-content;
                            padding: 10px;
                            margin: 5px;
                            align-items: center;
                          "
                        >
                          &ensp;{{ k.cname }}&ensp;
                          <input
                            class="col-4"
                            type="number"
                            min="1"
                            v-model="vehicleComponentsCountMap[k['id']]"
                            placeholder="數量"
                          /><span
                            class="col-2"
                            v-if="subId == '002-004' || subId == '002-005'"
                            id="deleteFileBtn"
                            style="cursor: pointer; color: #c73f3f"
                            @click="clickDeleteComponentItem(k)"
                            ><i class="fa-regular fa-circle-xmark"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    v-if="
                      subId == '002-004' ||
                      subId == '002-005' ||
                      subId == '002-006'
                    "
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>照片:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentVehiclePicSrcList"
                        :key="k"
                      >
                        <img class="col-9 objImg" :src="src.currentPicSrc" />
                        <span
                          class="col-2"
                          v-if="subId == '002-004' || subId == '002-005'"
                          id="deleteFileBtn"
                          style="cursor: pointer"
                          @click="deleteVehicleImg(src.fileId)"
                          ><i class="fa-regular fa-circle-xmark"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="subId == '002-004' || subId == '002-005'"
                    class="btn-group"
                    style="margin: 5px 0 5px"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-filter"
                      style="padding: 5px; border-radius: 5px"
                      data-target="pagado"
                      onclick="document.getElementById('folder').click();"
                      id="addVehiclePicBtn"
                    >
                      上傳完成車照片
                    </button>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      id="folder"
                      multiple
                      style="display: none"
                      ref="file"
                      @change="filesSelected"
                    />
                    <div
                      style="padding: 10px"
                      v-if="!currentFiles || !currentFiles.length"
                    >
                      沒有選擇檔案
                    </div>
                    <div
                      style="padding: 10px"
                      v-for="file in currentFiles"
                      :key="file.name"
                    >
                      {{ file.name }}
                    </div>
                  </div>
                  <hr />

                  <div class="col-12" style="margin: 5px 0 5px">
                    <span>價格資訊:&ensp;&ensp;</span>
                    <div class="row">
                      <form
                        class="row g-3 needs-validation"
                        style="margin-bottom: 20px"
                        novalidate
                      >
                        <div class="col-lg-4 col-12">
                          <label for="validationVehicleCost" class="form-label"
                            >採購成本</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            id="validationVehicleCost"
                            :disabled="subId == '002-006'"
                            v-model="currentVehicleCost"
                            required
                          />
                          <div class="invalid-feedback">
                            成本不得為空, 或小於1
                          </div>
                        </div>
                      </form>
                      <div
                        class="row col-4"
                        v-for="(k, v) in currentCustomerLevelList"
                        :key="v"
                      >
                        <div class="col-12">
                          <label
                            for="validationComponentCost"
                            class="form-label"
                            >{{ k["customerLevelCname"] }}價格</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="k.id"
                            :disabled="subId == '002-006'"
                            v-model="customerLevelPriceMap[k['id']]"
                            required
                          />
                          <div class="invalid-feedback">
                            成本不得為空, 或小於1
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVenderBtn"
              v-if="subId == '002-006'"
              @click="doDeleteVehicle()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '002-004'"
              @click="doNewAndEditVehicle(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '002-005'"
              @click="doNewAndEditVehicle(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of vehicle (002-004~002-006)-->
        <!-------------------------->
      </div>
    </div>
  </div>
  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="
        subId == '002-002' ||
        subId == '002-003' ||
        subId == '002-005' ||
        subId == '002-006'
      "
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["cname"] }}&ensp;( {{ item["partNo"] }})
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <!-- table content of component(001-003)-->
    <form
      v-if="subId == '002-007'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#modifyStockModal"
          @click="clickSearchItem(item)"
        >
          {{ item["cname"] }}&ensp;( {{ item["partNo"] }})
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div
      v-if="
        subId == '002-001' ||
        subId == '002-002' ||
        subId == '002-003' ||
        subId == '002-007'
      "
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold; text-align: center">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>{{ nameColTitle }}</th>
            <th>供應商</th>
            <th>料號</th>
            <th>型號</th>
            <th>顏色</th>
            <th>安全庫存</th>
            <th>現在庫存</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-001'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentComponentList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ venderIdNameMap[item["venderId"]] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["partNo"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["model"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["color"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["safetyStock"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  <span
                    :class="item.stock < item.safetyStock ? 'redFont' : ''"
                    >{{ item["stock"] }}</span
                  >
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-002'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i></button
              ><button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#modifyStockModal"
                v-if="subId == '002-007'"
                @click="showModifyStockModal(item, '編輯庫存', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-003'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of component(001-003)-->
    <!-- table content of component(004-006)-->
    <div
      v-if="subId == '002-004' || subId == '002-005' || subId == '002-006'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold; text-align: center">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>車輛分類</th>
            <th>車型</th>
            <th>車名</th>
            <th>料號</th>
            <th>顏色</th>
            <th>車型代碼</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-004'"
                @click="showConfirmModal('', '新增', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentVehicleList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleCategory"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleModel"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["partNo"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleColor"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleCode"] }}
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-005'"
                @click="showConfirmModal(item, '編輯', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                class="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '002-006'"
                @click="showConfirmModal(item, '確認刪除?', false)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of component(004-006)-->
  </div>
</template>

<script>
import $ from "jquery";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const Common_API_Lib = require("../../js/Common_API_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "零件名稱",
      nameColTitle: "零件名稱",
      confirmModalTitle: "",

      // API
      // components (001-003)
      componentList: [],
      currentComponentList: [],
      venderList: [],
      venderIdNameMap: {},
      currentVenderList: [],
      currentVenderItem: null,
      componentUnitList: [],
      currentComponentTypeList: [],
      currentComponentUnitList: [],
      currentFitVehicleModel: [],
      customerLevelList: [],
      currentCustomerLevelList: [],
      componentTypeMap: {},
      usedComponentMap: {},

      // vehicle (004-006)
      vehicleList: [],
      vehicleIdCategoryMap: null,
      currentVehicleCategoryItem: null,
      currentVehicleList: [],
      currentVehicleCategoryList: [],
      currentVehicleStyleItem: null,
      currentVehicleStyleList: [],
      currentVehicleModelItem: null,
      currentVehicleModelList: [],
      currentVehicleColorItem: null,
      currentVehicleColorList: [],
      currentVehicleCodeItem: null,
      currentVehicleCodeList: [],
      currentVehicleCost: 1,

      // params
      // components (001-003)
      currentComponentId: "",
      currentVenderId: "",
      currentPartNo: "",
      currentComponentCname: "",
      currentComponentUnitItem: null,
      currentComponentTypeItem: null,
      currentComponentModel: "",
      currentComponentColor: "",
      currentSpecifications: "",
      currentComponentCost: 1,
      currentSafetyStock: 1,
      currentStock: 1,
      currentStockFix: 1,
      currentNote: "",
      customerLevelPriceMap: {},
      componentIdVehicleMap: null,
      componentVehicleList: [],
      // vehicle (004-006)
      currentVehicleItem: null,
      currentVehiclePartNo: "",
      currentVehicleCname: "",
      currentSecurityReviewCode: "",
      currentQualifiedLabel: "",
      currentQualifiedCode: "",
      currentSpecificationsNote: "",
      // currentVehicleCost: 1,

      // customerLevelPriceMap: {},

      currentCopyVehicleItem: null,
      vehicleComponentsCountMap: {},

      // modify stock (007)
      currentModifyStockMethodItem: null,
      currentModifyStockMethodList: ["新增庫存", "盤點修正"],
      currentModifyStock: 0,

      // img
      currentFiles: null,
      currentComponentPicSrcList: [],
      currentComponentFileList: [],
      tmpDeleteComponentImgList: [],
      currentVehiclePicSrcList: [],
      currentVehicleFileList: [],
      tmpDeleteVehicleImgList: [],

      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchComponentMap: {},
      autoCompleteMap: {},
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  async mounted() {
    this.doGetComponentList();
    if (
      this.subId == "002-001" ||
      this.subId == "002-002" ||
      this.subId == "002-003" ||
      this.subId == "002-007"
    ) {
      this.doGetCustomerLevelList();

      // get vender
      let [venderList, venderIdNameMap] =
        await Common_API_Lib.handleVenderObjs();

      this.currentVenderList = venderList;
      this.venderIdNameMap = venderIdNameMap;
    } else if (
      this.subId == "002-004" ||
      this.subId == "002-005" ||
      this.subId == "002-006"
    ) {
      this.doGetVehicleList();
      this.doGetVehicleCategoryList();
      this.doGetCustomerLevelList();
    }
  },
  methods: {
    showCopyVehicleComponent() {
      this.doGetVehicleList();
    },
    async doCopyVehicle() {
      this.currentVehicleItem = this.currentCopyVehicleItem;
      this.doGetVehicleComponentsMap();

      var item = this.currentVehicleItem;
      this.currentVehiclePartNo = item.partNo;
      this.currentVehicleCname = item.cname;
      this.currentSecurityReviewCode = item.securityReviewCode;
      this.currentQualifiedLabel = item.qualifiedLabel;
      this.currentQualifiedCode = item.qualifiedCode;
      this.currentSpecificationsNote = item.specificationsNote;

      var categoryItem = this.vehicleIdCategoryMap[item.id];
      this.currentVehicleCategoryItem = categoryItem;
      this.vehicleCategoryChange(categoryItem.id);

      this.currentVehicleStyleItem = item.vehicleStyle;
      this.currentVehicleModelItem = item.vehicleModel;
      this.currentVehicleColorItem = item.vehicleColor;
      this.currentVehicleCodeItem = item.vehicleCode;

      // vehicle price
      let res2 = await this.doGetCustomerLevelPriceMap();
      Common_API_Lib.checkResp(res2, "取得客戶分級價格列表");
      this.customerLevelPriceMap = res2.data.result["customerLevelPriceMap"];
    },
    async showModifyStockModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetModifyStockModal();

      this.doGetComponentTypeList();
      this.doGetComponentUnitList();

      if (item != "") {
        this.currentComponentId = item.id;
        this.currentVenderId = item.venderId;
        this.componentVehicleList = this.componentIdVehicleMap[item.id];

        // get venderList
        let res = await this.getVenderObj();
        Common_API_Lib.checkResp(res, "取得供應商資訊");
        this.currentVenderItem = res.data.result["vendersObj"];

        // get customerLevelPriceMap
        this.customerLevelPriceMap = {};
        let res2 = await this.doGetCustomerLevelPriceMap();
        Common_API_Lib.checkResp(res2, "取得客戶分級價格列表");
        this.customerLevelPriceMap = res2.data.result["customerLevelPriceMap"];

        // data
        this.currentPartNo = item.partNo;
        this.currentComponentCname = item.cname;
        this.currentComponentUnitItem = item.unit;
        this.currentComponentTypeItem = item.componentType;
        this.currentComponentModel = item.model;
        this.currentComponentColor = item.color;
        this.currentSpecifications = item.specifications;
        this.currentComponentCost = item.cost;
        this.currentSafetyStock = item.safetyStock;
        this.currentStock = item.stock;
        this.currentStockFix = this.currentStock;
        this.currentNote = item.note;
      }
    },
    resetModifyStockModal() {
      this.currentModifyStockMethodItem = "";
      this.currentModifyStock = 0;
    },
    async showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      this.doGetComponentTypeList();
      this.doGetComponentUnitList();

      if (item != "") {
        if (this.subId == "002-002" || this.subId == "002-003") {
          this.currentComponentId = item.id;
          this.currentVenderId = item.venderId;
          this.componentVehicleList = this.componentIdVehicleMap[item.id];

          // get file objs
          // 1. get fileList by objId
          var fileIds = await Common_API_Lib.handleFileObjs(
            item.id,
            this.tmpDeleteComponentImgList,
            "component"
          );

          // 2. get file resouce
          let [respSrc, respPicSrcList] =
            await Common_API_Lib.handleFileResouce(fileIds);

          this.src = respSrc;
          this.currentComponentPicSrcList = respPicSrcList;

          // get venderList
          let res = await this.getVenderObj();
          Common_API_Lib.checkResp(res, "取得供應商資訊");
          this.currentVenderItem = res.data.result["vendersObj"];

          // get customerLevelPriceMap
          this.customerLevelPriceMap = {};
          let res2 = await this.doGetCustomerLevelPriceMap();
          Common_API_Lib.checkResp(res2, "取得客戶分級價格列表");
          this.customerLevelPriceMap =
            res2.data.result["customerLevelPriceMap"];

          // data
          this.currentPartNo = item.partNo;
          this.currentComponentCname = item.cname;
          this.currentComponentUnitItem = item.unit;
          this.currentComponentTypeItem = item.componentType;
          this.currentComponentModel = item.model;
          this.currentComponentColor = item.color;
          this.currentSpecifications = item.specifications;
          this.currentComponentCost = item.cost;
          this.currentSafetyStock = item.safetyStock;
          this.currentStock = item.stock;
          this.currentNote = item.note;
        } else if (this.subId == "002-005" || this.subId == "002-006") {
          this.currentVehicleItem = item;

          // get file objs
          // 1. get fileList by objId
          fileIds = await Common_API_Lib.handleFileObjs(
            item.id,
            this.tmpDeleteVehicleImgList,
            "vehicle"
          );

          // 2. get file resouce
          let [respSrc, respPicSrcList] =
            await Common_API_Lib.handleFileResouce(fileIds);

          this.src = respSrc;
          this.currentVehiclePicSrcList = respPicSrcList;

          // get customerLevelPriceMap
          this.customerLevelPriceMap = {};
          let res2 = await this.doGetCustomerLevelPriceMap();
          Common_API_Lib.checkResp(res2, "取得客戶分級價格列表");
          this.customerLevelPriceMap =
            res2.data.result["customerLevelPriceMap"];

          // data
          this.currentVehiclePartNo = item.partNo;
          this.currentVehicleCname = item.cname;
          this.currentSecurityReviewCode = item.securityReviewCode;
          this.currentQualifiedLabel = item.qualifiedLabel;
          this.currentQualifiedCode = item.qualifiedCode;
          this.currentSpecificationsNote = item.specificationsNote;

          var categoryItem = this.vehicleIdCategoryMap[item.id];
          this.currentVehicleCategoryItem = categoryItem;
          this.vehicleCategoryChange(categoryItem.id);

          this.currentVehicleStyleItem = item.vehicleStyle;
          this.currentVehicleModelItem = item.vehicleModel;
          this.currentVehicleColorItem = item.vehicleColor;
          this.currentVehicleCodeItem = item.vehicleCode;

          this.currentVehicleCost = item.cost;
          this.currentSafetyStock = item.safetyStock;
          this.currentStock = item.stock;

          this.doGetVehicleComponentsMap();
        }
      }
    },
    resetConfirmModal() {
      this.currentVenderItem = null;
      this.currentComponentId = "";
      this.currentVenderId = "";
      this.currentPartNo = "";
      this.currentComponentCname = "";
      this.currentComponentUnitItem = null;
      this.currentComponentModel = "";
      this.currentComponentColor = "";
      this.currentSpecifications = "";
      this.currentComponentCost = 1;
      this.currentSafetyStock = 1;
      this.currentStock = 1;
      this.currentNote = "";
      this.currentFiles = null;
      this.currentVehicleCategoryItem = null;
      this.currentVehicleStyleItem = null;
      this.currentVehicleModelItem = null;
      this.currentVehicleColorItem = null;
      this.currentVehicleCodeItem = null;

      this.currentVehiclePartNo = "";
      this.currentVehicleCname = "";
      this.currentSecurityReviewCode = "";
      this.currentQualifiedLabel = "";
      this.currentQualifiedCode = "";
      this.currentSpecificationsNote = "";

      // img
      this.currentComponentPicSrcList = [];
      this.tmpDeleteComponentImgList = [];
      this.currentVehiclePicSrcList = [];
      this.tmpDeleteVehicleImgList = [];
    },
    doNewAndEditComponent(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentComponentCname == "") {
        Toast_Lib.errorToast("零件名稱不得為空");
        return;
      }

      if (this.currentPartNo == "") {
        Toast_Lib.errorToast("料號不得為空");
        return;
      }

      if (this.currentComponentTypeItem == null) {
        Toast_Lib.errorToast("單位不得為空");
        return;
      }

      if (this.currentVenderItem == null) {
        Toast_Lib.errorToast("供應商不得為空");
        return;
      }

      if (this.currentComponentUnitItem == null) {
        Toast_Lib.errorToast("單位不得為空");
        return;
      }

      if (this.currentComponentModel == "") {
        Toast_Lib.errorToast("零件型號不得為空");
        return;
      }

      if (this.currentComponentColor == "") {
        Toast_Lib.errorToast("零件顏色不得為空");
        return;
      }

      if (this.currentSafetyStock == "") {
        Toast_Lib.errorToast("安全庫存不得為空, 或小於1");
        return;
      }

      if (this.currentStock == "") {
        Toast_Lib.errorToast("現在庫存不得為空, 或小於1");
        return;
      }

      if (this.currentComponentCost == "") {
        Toast_Lib.errorToast("採購成本不得為空, 或小於1");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newComponent";
      var toastMsg = "新增零件";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateComponent";
        toastMsg = "編輯零件";
      }

      // payload
      const formData = new FormData();
      if (this.currentFiles != null) {
        for (var i = 0; i < this.currentFiles.length; i++) {
          formData.append("files", this.currentFiles[i]);
        }
      }

      if (isEdit) {
        formData.append("updateUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("deleteFileIds", this.tmpDeleteComponentImgList);
        formData.append("componentId", this.currentComponentId);
      } else {
        formData.append("createUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("partNo", this.currentPartNo);
        formData.append("cname", this.currentComponentCname);
      }

      formData.append("userId", Common_Lib.getLocal("userId"));
      formData.append("venderId", this.currentVenderItem.id);
      formData.append("componentType", this.currentComponentTypeItem);
      formData.append("unit", this.currentComponentUnitItem);
      formData.append("model", this.currentComponentModel);
      formData.append("color", this.currentComponentColor);
      formData.append("specifications", this.currentSpecifications);
      formData.append("cost", this.currentComponentCost);
      formData.append("safetyStock", this.currentSafetyStock);
      formData.append("stock", this.currentStock);
      formData.append("note", this.currentNote);

      formData.append(
        "customerLevelPriceMapStr",
        JSON.stringify(this.customerLevelPriceMap)
      );

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetComponentList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    doDeleteComponent() {
      var url = Common_Lib.getServerUrl() + "/deleteCompnent";

      var jsonObj = {};
      jsonObj.componentId = this.currentComponentId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除零件失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除零件成功", "");

              this.doGetComponentList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doModifyStock() {
      var url = Common_Lib.getServerUrl() + "/modifyStock";

      var jsonObj = {};
      jsonObj.componentId = this.currentComponentId;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      let modifyMethod = this.currentModifyStockMethodItem;
      if (modifyMethod == null) {
        Toast_Lib.errorToast("請選擇修改方法");
        return;
      }

      if (this.currentModifyStockMethodItem == "新增庫存") {
        jsonObj.newStock = this.currentStock + this.currentModifyStock;
      } else if (this.currentModifyStockMethodItem == "盤點修正") {
        jsonObj.newStock = this.currentModifyStock;
      }

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("修正零件庫存失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("修正零件庫存成功", "");

              this.doGetComponentList();
              $("#closeModifyStockModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // getfile
    async deleteComponentImg(fileId) {
      this.tmpDeleteComponentImgList.push(fileId);

      // 1. get fileList by objId
      var fileIds = await Common_API_Lib.handleFileObjs(
        this.currentComponentId,
        this.tmpDeleteComponentImgList,
        "component"
      );

      // 2. get file resouce
      let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
        fileIds
      );

      this.src = respSrc;
      this.currentComponentPicSrcList = respPicSrcList;
    },
    async deleteVehicleImg(fileId) {
      this.tmpDeleteVehicleImgList.push(fileId);

      // 1. get fileList by objId
      var fileIds = await Common_API_Lib.handleFileObjs(
        this.currentVehicleItem.id,
        this.tmpDeleteVehicleImgList,
        "vehicle"
      );

      // 2. get file resouce
      let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
        fileIds
      );

      this.src = respSrc;
      this.currentVehiclePicSrcList = respPicSrcList;
    },

    // CRUD (001-003)
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.componentList = [];
            this.currentComponentList = [];

            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.componentTypeMap = {};
            this.searchComponentMap = {};
            this.autoCompleteMap = {};
            this.tmpAddedComponentMap = {};
            this.vehicleComponentsCountMap = {};

            for (var i = 0; i < this.currentComponentList.length; i++) {
              var componentObj = this.currentComponentList[i];
              var componentType = componentObj.componentType;
              if (!(componentType in this.componentTypeMap)) {
                this.componentTypeMap[componentType] = [];
              }
              this.componentTypeMap[componentType].push(componentObj);
              this.searchComponentMap[componentType] = "";
              this.autoCompleteMap[componentType] = false;
              this.tmpAddedComponentMap[componentType] = [];
              this.vehicleComponentsCountMap[componentObj.id] = 1;
            }

            this.componentIdVehicleMap = result.result["componentIdVehicleMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // advance data (001-003)
    getVenderObj() {
      var url = Common_Lib.getServerUrl() + "/getVenderObj";
      var jsonObj = {};
      jsonObj.venderId = this.currentVenderId;

      return new Promise((resolve, reject) => {
        axios
          .post(url, jsonObj)
          .then((response) => {
            if (response.status != 200) {
              var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
              Toast_Lib.errorToast("連接錯誤", msg);
            } else {
              resolve(response);
            }
          })
          .catch((err) => {
            // axios的錯誤都會跑到這邊
            Toast_Lib.errorToast("錯誤", err);
          });
      });
    },

    doGetComponentUnitList() {
      var url = Common_Lib.getServerUrl() + "/getComponetUnitList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentComponentUnitList = result.result["componentUnitList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetComponentTypeList() {
      var url = Common_Lib.getServerUrl() + "/getComponetTypeList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentComponentTypeList = result.result["componentTypeList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelList() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.customerLevelList = [];
            this.currentCustomerLevelList = [];

            result = response.data;
            this.customerLevelList = result.result["customerLevelList"];
            this.currentCustomerLevelList = this.customerLevelList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerLevelPriceMap() {
      var url = Common_Lib.getServerUrl() + "/getCustomerLevelPriceMap";
      var jsonObj = {};
      let objId = this.currentComponentId;
      if (
        this.subId == "002-004" ||
        this.subId == "002-005" ||
        this.subId == "002-006"
      ) {
        objId = this.currentVehicleItem.id;
      }
      jsonObj.componentId = objId;

      return new Promise((resolve, reject) => {
        axios
          .post(url, jsonObj)
          .then((response) => {
            if (response.status != 200) {
              var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
              Toast_Lib.errorToast("連接錯誤", msg);
            } else {
              resolve(response);
            }
          })
          .catch((err) => {
            // axios的錯誤都會跑到這邊
            Toast_Lib.errorToast("錯誤", err);
          });
      });
    },
    // end of (001-003) advance data

    // CRUD (004-006)
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.vehicleList = [];
            this.currentVehicleList = [];

            result = response.data;
            this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doNewAndEditVehicle(isEdit) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      if (this.currentVehicleCname == "") {
        Toast_Lib.errorToast("車輛名稱不得為空");
        return;
      }

      if (this.currentVehiclePartNo == "") {
        Toast_Lib.errorToast("料號不得為空");
        return;
      }

      if (this.currentVehicleCategoryItem == null) {
        Toast_Lib.errorToast("車輛分類不得為空");
        return;
      }

      if (this.currentVehicleStyleItem == null) {
        Toast_Lib.errorToast("車輛款式不得為空");
        return;
      }

      if (this.currentVehicleModelItem == null) {
        Toast_Lib.errorToast("車型不得為空");
        return;
      }

      if (this.currentVehicleColorItem == "") {
        Toast_Lib.errorToast("車輛顏色不得為空");
        return;
      }

      if (this.currentVehicleCodeItem == "") {
        Toast_Lib.errorToast("車廠代碼不得為空");
        return;
      }

      if (this.currentSecurityReviewCode == "") {
        Toast_Lib.errorToast("安審代碼代碼不得為空");
        return;
      }

      if (this.currentQualifiedLabel == "") {
        Toast_Lib.errorToast("合格標示廠牌不得為空");
        return;
      }

      if (this.currentQualifiedCode == "") {
        Toast_Lib.errorToast("合格標示型號不得為空");
        return;
      }

      var url = Common_Lib.getServerUrl() + "/newVehicle";
      var toastMsg = "新增完成車";
      if (isEdit) {
        url = Common_Lib.getServerUrl() + "/updateVehicle";
        toastMsg = "編輯完成車";
      }

      // payload
      const formData = new FormData();
      if (this.currentFiles != null) {
        for (var i = 0; i < this.currentFiles.length; i++) {
          formData.append("files", this.currentFiles[i]);
        }
      }

      if (isEdit) {
        formData.append("updateUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("deleteFileIds", this.tmpDeleteVehicleImgList);
        formData.append("vehicleId", this.currentVehicleItem.id);
      } else {
        formData.append("createUser", Common_Lib.getLocalAndDecrypt("account"));
        formData.append("cname", this.currentVehicleCname);
      }

      formData.append("userId", Common_Lib.getLocal("userId"));
      formData.append("partNo", this.currentVehiclePartNo);
      formData.append("vehicleCategory", this.currentVehicleCategoryItem.cname);
      formData.append("vehicleStyle", this.currentVehicleStyleItem);
      formData.append("vehicleModel", this.currentVehicleModelItem);
      formData.append("vehicleColor", this.currentVehicleColorItem);
      formData.append("vehicleCode", this.currentVehicleCodeItem);
      formData.append("securityReviewCode", this.currentSecurityReviewCode);
      formData.append("qualifiedLabel", this.currentQualifiedLabel);
      formData.append("qualifiedCode", this.currentQualifiedCode);
      formData.append("specificationsNote", this.currentSpecificationsNote);

      formData.append("cost", this.currentVehicleCost);
      formData.append("safetyStock", 1);
      formData.append("stock", 1);
      formData.append(
        "customerLevelPriceMapStr",
        JSON.stringify(this.customerLevelPriceMap)
      );

      let keys = Object.keys(this.tmpAddedComponentMap);
      let allComponentIds = [];
      for (var k = 0; k < keys.length; k++) {
        var tmpList = this.tmpAddedComponentMap[keys[k]];
        for (var j = 0; j < tmpList.length; j++) {
          allComponentIds.push(tmpList[j].id);
        }
      }
      formData.append("allComponentIdsStr", JSON.stringify(allComponentIds));

      formData.append(
        "vehicleComponentsCountMapStr",
        JSON.stringify(this.vehicleComponentsCountMap)
      );

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetVehicleList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doDeleteVehicle() {
      var url = Common_Lib.getServerUrl() + "/deleteVehicle";

      var jsonObj = {};
      jsonObj.vehicleId = this.currentVehicleItem.id;
      jsonObj.updateUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.userId = Common_Lib.getLocal("userId");

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("刪除完成車失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast("刪除完成車成功", "");

              this.doGetVehicleList();
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    // advance data (004-006)
    doGetVehicleComponentsMap() {
      var url = Common_Lib.getServerUrl() + "/getVehicleComponenstMap";
      let result = null;
      var jsonObj = {};
      jsonObj.vehicleId = this.currentVehicleItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpAddedComponentMap = result.result["vehicleIdComponentsMap"];
            this.vehicleComponentsCountMap =
              result.result["componentIdCountMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleCategoryList() {
      var url = Common_Lib.getServerUrl() + "/getVihicleCategoryList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.vehicleCategoryList = [];
            this.currentVehicleCategoryList = [];

            result = response.data;
            this.vehicleCategoryList = result.result["vehicleCategoryList"];
            this.currentVehicleCategoryList = this.vehicleCategoryList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    vehicleCategoryChange(vehicleCategoryId) {
      for (var i = 0; i < this.currentVehicleCategoryList.length; i++) {
        var vehicleCategoryObj = this.currentVehicleCategoryList[i];
        if (vehicleCategoryObj["id"] == vehicleCategoryId) {
          this.currentVehicleStyleList =
            vehicleCategoryObj["vehicleStyle"].split(",");
          this.currentVehicleModelList =
            vehicleCategoryObj["vehicleModel"].split(",");
          this.currentVehicleColorList =
            vehicleCategoryObj["vehicleColor"].split(",");
          this.currentVehicleCodeList =
            vehicleCategoryObj["vehicleCode"].split(",");
          return;
        }
      }
    },
    modifyStockMethodOnChange() {
      this.currentModifyStock = 0;
    },
    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(componentItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId != "002-007") {
        if (this.subId == "002-002") {
          modalTitle = "編輯";
          isEdit = true;
        } else {
          modalTitle = "確認刪除?";
          isEdit = false;
        }
        this.showConfirmModal(componentItem, modalTitle, isEdit);
      } else {
        modalTitle = "編輯庫存";
        isEdit = true;
        this.showModifyStockModal(componentItem, modalTitle, isEdit);
      }
    },
    searchBtn() {
      if (
        this.subId == "002-002" ||
        this.subId == "002-003" ||
        this.subId == "002-007"
      ) {
        this.currentComponentList = [];
        if (this.search == "") {
          this.currentComponentList = this.componentList;
        } else {
          for (var i = 0; i < this.componentList.length; i++) {
            var componentItem = this.componentList[i];
            if (
              componentItem["cname"].includes(this.search) ||
              componentItem["partNo"].includes(this.search)
            ) {
              this.currentComponentList.push(componentItem);
            }
          }
        }
      } else if (this.subId == "002-005" || this.subId == "002-006") {
        this.currentVehicleList = [];
        if (this.search == "") {
          this.currentVehicleList = this.vehicleList;
        } else {
          for (var j = 0; j < this.vehicleList.length; j++) {
            var vehicleItem = this.vehicleList[j];
            if (
              vehicleItem["cname"].includes(this.search) ||
              vehicleItem["partNo"].includes(this.search)
            ) {
              this.currentVehicleList.push(vehicleItem);
            }
          }
        }
      }
    },
    clickSearchComponent(t) {
      this.currentSearchColumn = t;
      this.autoCompleteMap = {};
      for (var i = 0; i < this.currentComponentList.length; i++) {
        this.autoCompleteMap[this.currentComponentList.componentType] = false;
      }
      this.autoCompleteMap[t] = true;
    },
    clickAddComponentItem(item) {
      var cname = item.cname;
      var componentType = item.componentType;

      this.currentSearchColumn = componentType;
      this.autoCompleteMap[componentType] = false;

      var isExist = true;
      for (
        var i = 0;
        i < this.tmpAddedComponentMap[componentType].length;
        i++
      ) {
        var tmpObj = this.tmpAddedComponentMap[componentType][i];
        if (tmpObj.cname == cname) {
          isExist = false;
          break;
        }
      }
      if (isExist) {
        this.tmpAddedComponentMap[componentType].push(item);
        this.vehicleComponentsCountMap[item.id] = 1;
      }

      // reset
      this.searchComponentMap[this.currentSearchColumn] = "";
    },
    clickDeleteComponentItem(item) {
      var cname = item.cname;
      var componentType = item.componentType;
      var tmpAddedComponentMapList =
        this.tmpAddedComponentMap[item.componentType];

      this.currentSearchColumn = componentType;
      this.autoCompleteMap[componentType] = false;

      var tmpList = [];
      for (var i = 0; i < tmpAddedComponentMapList.length; i++) {
        var tmpObj = tmpAddedComponentMapList[i];
        if (tmpObj.cname != cname) {
          tmpList.push(tmpObj);
        }
      }

      // reset
      this.tmpAddedComponentMap[componentType] = tmpList;
      this.usedComponentMap[componentType] = "";
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    currentSearchColumn() {
      if (this.currentSearchColumn || this.currentSearchColumn != "") {
        this.autoCompleteMap[this.currentSearchColumn] = true;
      } else {
        this.autoCompleteMap[this.currentSearchColumn] = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      if (
        this.subId == "002-002" ||
        this.subId == "002-003" ||
        this.subId == "002-007"
      ) {
        this.componentList.forEach((item) => {
          if (
            (searchKeyword != "" && item.cname.includes(searchKeyword)) ||
            item.partNo.includes(searchKeyword)
          ) {
            arr.push(item);
          }
        });
      } else if (this.subId == "002-005" || this.subId == "002-006") {
        this.vehicleList.forEach((item) => {
          if (
            (searchKeyword != "" && item.cname.includes(searchKeyword)) ||
            item.partNo.includes(searchKeyword)
          ) {
            arr.push(item);
          }
        });
      }
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchComponentMap[this.currentSearchColumn];
      if (!(this.currentSearchColumn in this.componentTypeMap)) {
        return [...new Set(arr)];
      }
      // 比對字串
      this.componentTypeMap[this.currentSearchColumn].forEach((item) => {
        if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      // if (arr.length > 0) {
      //   this.autoComplete = true;
      // } else {
      //   this.autoComplete = false;
      // }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
.redFont {
  color: red;
  font-weight: bold;
}
</style>
