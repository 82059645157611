<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <!-------------------------->
        <!--vehicle (015-001~015-002)-->
        <!-------------------------->
        <div
          v-if="subId == '015-001' || subId == '015-002'"
          class="modal-body mx-3"
        >
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label for="validationVehicleCname" class="form-label"
                        >車輛名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehicleCname"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleCname"
                        required
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationVehiclePartNo" class="form-label"
                        >料號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationVehiclePartNo"
                        :disabled="subId != '002-004'"
                        v-model="currentVehiclePartNo"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCategory" class="form-label"
                        >車輛分類</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCategory"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleCategoryItem"
                        @change="
                          vehicleCategoryChange(currentVehicleCategoryItem.id)
                        "
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCategoryList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value.cname }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛分類</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleStyle" class="form-label"
                        >車輛款式</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleStyle"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleStyleItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleStyleList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛款式</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleModel" class="form-label"
                        >車型</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleModel"
                        :disabled="true"
                        v-model="currentVehicleModelItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleModelList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車型</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleColor" class="form-label"
                        >車輛顏色</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleColor"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleColorItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleColorList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車輛顏色</div>
                    </div>
                    <div class="col-md-6">
                      <label for="validationVehicleCode" class="form-label"
                        >車廠代碼</label
                      >
                      <select
                        class="form-select"
                        id="validationVehicleCode"
                        :disabled="subId != '002-004'"
                        v-model="currentVehicleCodeItem"
                        required
                      >
                        <option
                          v-for="(value, key) in currentVehicleCodeList"
                          :key="key"
                          :value="value"
                        >
                          <a class="dropdown-item">{{ value }}</a>
                        </option>
                      </select>
                      <div class="invalid-feedback">請選擇車廠代碼</div>
                    </div>
                  </form>
                  <hr />
                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSecurityReviewCode"
                        class="form-label"
                        >安審代碼</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSecurityReviewCode"
                        v-model="currentSecurityReviewCode"
                        disabled="true"
                        required
                      />
                      <div class="invalid-feedback">安審代碼不得為空</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedLabel" class="form-label"
                        >合格標示廠牌</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedLabel"
                        v-model="currentQualifiedLabel"
                        disabled="true"
                        required
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label for="validationQualifiedCode" class="form-label"
                        >合格標示型號</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationQualifiedCode"
                        disabled="true"
                        v-model="currentQualifiedCode"
                        required
                      />
                    </div>
                    <div class="col-lg-6 col-12">
                      <label
                        for="validationSpecificationsNote"
                        class="form-label"
                        >品名規格備註</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="validationSpecificationsNote"
                        disabled="true"
                        v-model="currentSpecificationsNote"
                      />
                    </div>
                  </form>
                </th>
              </tr>
            </thead>

            <tbody v-if="subId == '015-001'">
              <td style="min-width: 100%; float: left; align-items: center">
                <div
                  class="wrap-input100 validate-input"
                  style="margin-bottom: 20px"
                >
                  <div
                    style="
                      padding: 20px 0px;
                      font-weight: bold;
                      font-size: 20px;
                    "
                  >
                    <span
                      >目前零件庫存可製作:&ensp;&ensp;{{
                        countVehicleResult
                      }}&ensp;輛</span
                    >
                  </div>

                  <form
                    class="row g-3 needs-validation"
                    style="margin-bottom: 20px"
                    novalidate
                  >
                    <div
                      class="row"
                      v-for="v in currentComponentTypeList"
                      :key="v"
                    >
                      <div class="col-12" style="margin: 10px 0px">
                        <label
                          for="validationVender"
                          class="form-label"
                          style="font-weight: bold"
                          >{{ v }}</label
                        >
                      </div>
                      <div
                        class="row col-12"
                        style="border: solid #ccc 1px; padding: 10px"
                      >
                        <div
                          class="vehicleCategoryItem col-3"
                          v-for="(k, v2) in tmpAddedComponentMap[v]"
                          :key="v2"
                          style="
                            width: max-content;
                            padding: 5px;
                            margin: 5px 5px;
                          "
                        >
                          &ensp;{{ k.cname }}&ensp;({{
                            vehicleComponentsCountMap[k["id"]]
                          }})&ensp;
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    v-if="subId == '015-001' || subId == '015-002'"
                    class="col-12"
                    style="margin: 5px 0 5px"
                  >
                    <span>照片:&ensp;&ensp;</span>
                    <div class="row">
                      <div
                        class="row col-4"
                        v-for="(src, k) in currentVehiclePicSrcList"
                        :key="k"
                      >
                        <img class="col-9 objImg" :src="src.currentPicSrc" />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-danger"
              id="deleteVenderBtn"
              v-if="subId == '002-006'"
              @click="doDeleteVehicle()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定刪除
            </button>
            <button
              class="btn btn-outline-primary"
              id="editUserBtn"
              v-if="subId == '002-004'"
              @click="doNewAndEditVehicle(false)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-primary"
              id="newUserBtn"
              v-if="subId == '002-005'"
              @click="doNewAndEditVehicle(true)"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定變更
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
        <!-------------------------->
        <!--end of vehicle (015-001~015-002)-->
        <!-------------------------->
      </div>
    </div>
  </div>
  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="subId == '015-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item["cname"] }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>

    <!-- table content of vehicle(001)-->
    <div
      v-if="subId == '015-001'"
      class="table-container"
      style="margin-top: 40px"
    >
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold; text-align: center">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>車輛分類</th>
            <th>車型</th>
            <th>車名</th>
            <th>顏色</th>
            <th>車型代碼</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentVehicleList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleCategory"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleModel"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["cname"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleColor"] }}
                </div>
              </div>
            </td>
            <td>
              <div class="media formContent setCenter">
                <div class="media-body">
                  {{ item["vehicleCode"] }}
                </div>
              </div>
            </td>

            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '015-001'"
                @click="showConfirmModal(item, '檢視', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end of table content of vehicle(001)-->
    <!--(002)-->
    <div v-if="subId == '015-002'" class="row">
      <div class="col-12">
        <div>
          <h4 class="w-100 p-b-20" style="font-weight: bold; color: #666">
            請選擇要計算的車輛
          </h4>
        </div>
        <table class="table table-filter">
          <thead>
            <tr>
              <th style="align-items: center; min-width: 300px">
                <form
                  class="row g-3 needs-validation"
                  style="margin-bottom: 20px"
                  novalidate
                >
                  <div class="col-7">
                    <label for="validationSelectVehicle" class="form-label"
                      >完成車列表</label
                    >
                    <select
                      class="form-select"
                      id="validationSelectVehicle"
                      v-model="currentSelectVehicleItem"
                      required
                      @change="resetResult"
                    >
                      <option
                        v-for="(value, key) in currentVehicleList"
                        :key="key"
                        :value="value"
                      >
                        <a class="dropdown-item">{{ value.cname }}</a>
                      </option>
                    </select>
                  </div>
                  <div class="col-6">
                    <label for="validationVehicleCount" class="form-label"
                      >數量</label
                    >
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      id="validationVehicleCount"
                      v-model="currentVehicleCount"
                      required
                    />
                    <div class="invalid-feedback">數量不得為空</div>
                  </div>
                </form>
              </th>
            </tr>
          </thead>
        </table>
        <div
          class="col-9 mx-3 setCenter"
          style="
            padding: 20px;
            margin-left: 28% !important ;
            justify-content: right;
          "
        >
          <button
            class="btn btn-outline-success"
            id="claculateVehicleBtn"
            v-if="subId == '015-002'"
            @click="doCalculateVehicle()"
          >
            <i class="fa-solid fa-copy"></i>&ensp;計算
          </button>
        </div>
        <hr />
        <form class="row g-3 needs-validation" style="margin: 20px" novalidate>
          <div class="row" v-for="v in currentComponentTypeList" :key="v">
            <div class="col-12" style="margin: 10px 0px">
              <label
                for="validationVender"
                class="form-label"
                style="font-weight: bold"
                >{{ v }}</label
              >
            </div>
            <div
              class="row col-12"
              style="border: solid #ccc 1px; padding: 10px"
            >
              <div
                class="vehicleCategoryItem col-3"
                v-for="(k, v2) in tmpAddedComponentMap[v]"
                :key="v2"
                style="width: max-content; padding: 5px; margin: 5px 5px"
              >
                &ensp;{{ k.cname }}&ensp;({{
                  vehicleComponentsCountMap[k["id"]]
                }})&ensp;<span
                  style="color: red"
                  v-if="
                    currentVehicleCount * vehicleComponentsCountMap[k['id']] >
                    k.stock
                  "
                  >(缺料{{
                    currentVehicleCount * vehicleComponentsCountMap[k["id"]] -
                    k.stock
                  }}{{ k.unit }})&ensp;</span
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--end of (002)-->
  </div>
</template>

<script>
import $ from "jquery";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const Common_API_Lib = require("../../js/Common_API_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "零件名稱",
      nameColTitle: "零件名稱",
      confirmModalTitle: "",

      // API
      // vehicle (001-003)
      vehicleList: [],
      vehicleIdCategoryMap: null,
      currentVehicleCategoryItem: null,
      currentVehicleList: [],
      currentVehicleCategoryList: [],
      currentVehicleStyleItem: null,
      currentVehicleStyleList: [],
      currentVehicleModelItem: null,
      currentVehicleModelList: [],
      currentVehicleColorItem: null,
      currentVehicleColorList: [],
      currentVehicleCodeItem: null,
      currentVehicleCodeList: [],

      // params
      // vehicle (001-002)
      currentVehicleItem: null,
      currentVehiclePartNo: "",
      currentVehicleCname: "",
      currentSecurityReviewCode: "",
      currentQualifiedLabel: "",
      currentQualifiedCode: "",
      currentSpecificationsNote: "",
      currentCopyVehicleItem: null,
      countVehicleResult: 0,
      currentVehicleCount: 1,
      currentSelectVehicleItem: null,
      vehicleComponentsCountMap: null,

      // img
      currentFiles: null,
      currentVehiclePicSrcList: [],
      currentVehicleFileList: [],
      currentComponentTypeList: [],

      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchComponentMap: {},
      autoCompleteMap: {},
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  async mounted() {
    if (this.subId == "015-001" || this.subId == "015-002") {
      this.doGetVehicleList();
      this.doGetVehicleCategoryList();
    }
  },
  methods: {
    async showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      this.doGetComponentTypeList();

      if (item != "") {
        if (this.subId == "015-001") {
          this.currentVehicleItem = item;
          this.doGetVehicleCountResult();

          // get file objs
          // 1. get fileList by objId
          var fileIds = await Common_API_Lib.handleFileObjs(
            item.id,
            this.currentVehicleFileList,
            "vehicle"
          );

          // 2. get file resouce
          let [respSrc, respPicSrcList] =
            await Common_API_Lib.handleFileResouce(fileIds);

          this.src = respSrc;
          this.currentVehiclePicSrcList = respPicSrcList;

          // data
          this.currentVehiclePartNo = item.partNo;
          this.currentVehicleCname = item.cname;
          this.currentSecurityReviewCode = item.securityReviewCode;
          this.currentQualifiedLabel = item.qualifiedLabel;
          this.currentQualifiedCode = item.qualifiedCode;
          this.currentSpecificationsNote = item.specificationsNote;

          var categoryItem = this.vehicleIdCategoryMap[item.id];
          this.currentVehicleCategoryItem = categoryItem;
          this.vehicleCategoryChange(categoryItem.id);

          this.currentVehicleStyleItem = item.vehicleStyle;
          this.currentVehicleModelItem = item.vehicleModel;
          this.currentVehicleColorItem = item.vehicleColor;
          this.currentVehicleCodeItem = item.vehicleCode;

          this.doGetVehicleComponentsMap();
        }
      }
    },
    resetConfirmModal() {
      this.currentFiles = null;

      this.currentVehiclePartNo = "";
      this.currentVehicleCname = "";
      this.currentSecurityReviewCode = "";
      this.currentQualifiedLabel = "";
      this.currentQualifiedCode = "";
      this.currentSpecificationsNote = "";

      // img
      this.currentVehiclePicSrcList = [];
    },

    doGetComponentTypeList() {
      var url = Common_Lib.getServerUrl() + "/getComponetTypeList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.currentComponentTypeList = result.result["componentTypeList"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // CRUD
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.vehicleList = [];
            this.currentVehicleList = [];

            result = response.data;
            this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // advance data
    doGetVehicleCountResult() {
      var url = Common_Lib.getServerUrl() + "/getVehicleCountResult";
      let result = null;
      var jsonObj = {};
      jsonObj.vehicleId = this.currentVehicleItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.countVehicleResult = result.result["vehicleCount"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleComponentsMap() {
      var url = Common_Lib.getServerUrl() + "/getVehicleComponenstMap";
      let result = null;
      var jsonObj = {};
      jsonObj.vehicleId = this.currentVehicleItem.id;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.tmpAddedComponentMap = result.result["vehicleIdComponentsMap"];
            this.vehicleComponentsCountMap =
              result.result["componentIdCountMap"];
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleCategoryList() {
      var url = Common_Lib.getServerUrl() + "/getVihicleCategoryList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            this.vehicleCategoryList = [];
            this.currentVehicleCategoryList = [];

            result = response.data;
            this.vehicleCategoryList = result.result["vehicleCategoryList"];
            this.currentVehicleCategoryList = this.vehicleCategoryList;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    vehicleCategoryChange(vehicleCategoryId) {
      for (var i = 0; i < this.currentVehicleCategoryList.length; i++) {
        var vehicleCategoryObj = this.currentVehicleCategoryList[i];
        if (vehicleCategoryObj["id"] == vehicleCategoryId) {
          this.currentVehicleStyleList =
            vehicleCategoryObj["vehicleStyle"].split(",");
          this.currentVehicleModelList =
            vehicleCategoryObj["vehicleModel"].split(",");
          this.currentVehicleColorList =
            vehicleCategoryObj["vehicleColor"].split(",");
          this.currentVehicleCodeList =
            vehicleCategoryObj["vehicleCode"].split(",");
          return;
        }
      }
    },
    doCalculateVehicle() {
      if (this.currentSelectVehicleItem == null || this.vehicleCount < 1) {
        return;
      }
      this.currentVehicleItem = this.currentSelectVehicleItem;
      this.doGetComponentTypeList();
      this.doGetVehicleComponentsMap();
    },
    resetResult() {
      this.currentComponentTypeList = null;
    },
    filesSelected(e) {
      this.currentFiles = e.target.files;
    },
    // for search bar function
    clickSearchItem(componentItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      modalTitle = "檢視";
      isEdit = true;
      this.showConfirmModal(componentItem, modalTitle, isEdit);
    },
    searchBtn() {
      if (this.subId == "015-001") {
        this.currentVehicleList = [];
        if (this.search == "") {
          this.currentVehicleList = this.vehicleList;
        } else {
          for (var j = 0; j < this.vehicleList.length; j++) {
            var vehicleItem = this.vehicleList[j];
            if (vehicleItem["cname"].includes(this.search)) {
              this.currentVehicleList.push(vehicleItem);
            }
          }
        }
      }
    },
  },
  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    currentSearchColumn() {
      if (this.currentSearchColumn || this.currentSearchColumn != "") {
        this.autoCompleteMap[this.currentSearchColumn] = true;
      } else {
        this.autoCompleteMap[this.currentSearchColumn] = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      if (this.subId == "015-001") {
        this.vehicleList.forEach((item) => {
          if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
            arr.push(item);
          }
        });
      }
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchComponentMap[this.currentSearchColumn];
      if (!(this.currentSearchColumn in this.componentTypeMap)) {
        return [...new Set(arr)];
      }
      // 比對字串
      this.componentTypeMap[this.currentSearchColumn].forEach((item) => {
        if (searchKeyword != "" && item.cname.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
.redFont {
  color: red;
  font-weight: bold;
}
</style>
