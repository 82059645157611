<template>
  <!-- confirm modal -->
  <div
    class="modal fade"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div
          class="modal-header text-center pull-center"
          style="margin-bottom: 30px"
        >
          <h4
            class="modal-title w-100 login100-form-title p-b-20"
            style="font-weight: bold; color: #666"
          >
            {{ confirmModalTitle }}
          </h4>
          <span
            id="closeConfirmModalBtn"
            data-bs-dismiss="modal"
            style="cursor: pointer"
            ><i class="fa-solid fa-xmark"></i
          ></span>
        </div>

        <div class="modal-body mx-3">
          <table class="table table-filter">
            <thead>
              <tr>
                <th style="align-items: center; min-width: 300px">
                  <div style="margin: 5px 0 5px" v-if="!isView">
                    <h4
                      v-if="subId == '014-001'"
                      style="font-weight: bold; padding-bottom: 20px"
                    >
                      選擇要加入的貨品:
                    </h4>
                    <div
                      class="wrap-input100 validate-input"
                      v-if="subId == '014-001'"
                      style="padding: 10px; border: 1px dashed #ccc"
                    >
                      <form
                        class="row g-3"
                        style="padding-bottom: 20px"
                        novalidate
                      >
                        <div class="col-md-2 col-12">
                          <label
                            for="validationComponentType"
                            class="form-label"
                            >貨品類型</label
                          >
                          <select
                            class="form-select"
                            id="validationComponentType"
                            v-model="currentComponentTypeItem"
                            @change="componentTypeOnChange"
                          >
                            <option
                              v-for="v in currentProductTypeList"
                              :key="v"
                            >
                              <a class="dropdown-item">{{ v }}</a>
                            </option>
                          </select>
                        </div>
                        <div class="col-md-4 col-12">
                          <label for="validationProduct" class="form-label"
                            >貨品列表</label
                          >
                          <form
                            class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
                          >
                            <!--     這裡是搜尋列 -->
                            <div class="input-group w-md-50 me-2">
                              <input
                                class="form-control"
                                style="margin-bottom: 10px"
                                type="search"
                                v-model.trim="searchProductText"
                                @keyup="keyboardEvent"
                                placeholder="快速搜尋"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                              />
                            </div>
                            <!--     這裡是搜尋列 -->

                            <!--     這裡才是autoComplete的選單 -->
                            <!--     控制autoComplete的開闔 -->
                            <ul
                              class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
                              :class="autoCompleteProduct ? '' : 'd-none'"
                            >
                              <!--     控制按鈕事件的選取背景 -->
                              <li
                                class="searchHover p-2 w-100"
                                v-for="item in filterComponents"
                                :key="item"
                                @click="clickSearchProduct(item)"
                              >
                                {{ item["cname"] }}&ensp;({{ item["partNo"] }})
                              </li>
                            </ul>
                            <!--     這裡才是autoComplete的選單 -->
                          </form>
                          <select
                            class="form-select"
                            id="validationProduct"
                            v-model="currentProductItem"
                            @change="clickSearchProduct(currentProductItem)"
                          >
                            <option
                              v-for="(v, k) in currentProductList"
                              :key="k"
                              :value="v"
                            >
                              <a class="dropdown-item"
                                >{{ v.cname }}&ensp;({{ v.partNo }})</a
                              >
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-2 col-12">
                          <label
                            for="validationProductQuantity"
                            class="form-label"
                            >數量</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationProductQuantity"
                            v-model="currentProductQuantity"
                          />
                        </div>
                        <div class="col-lg-3 col-12">
                          <label for="validationUnitPrice" class="form-label"
                            >單價</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="validationUnitPrice"
                            disabled="true"
                            v-model="currentUnitPrice"
                          />
                        </div>
                      </form>
                      <span style="font-weight: bold">貨品預覧</span>

                      <table
                        class="table table-filter"
                        style="border: 1px dashed #ccc"
                      >
                        <thead>
                          <tr>
                            <th style="width: 10px"></th>
                            <th style="color: #888">#</th>
                            <th style="color: #888">產品編號/車架號碼</th>
                            <th style="color: #888">品名規格</th>
                            <th style="color: #888">單位</th>
                            <th style="color: #888">數量</th>
                            <th style="color: #888">單價</th>
                            <th style="color: #888">含稅價</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="currentComponentTypeItem == '零件'">
                            <td style="width: 10px"></td>
                            <td></td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentPreviewItem["partNo"] }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentPreviewItem["cname"] }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentPreviewItem["unit"] }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentProductQuantity }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentUnitPrice }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{
                                    Math.floor(
                                      currentProductQuantity *
                                        currentUnitPrice *
                                        (1 + currentTax / 100)
                                    )
                                  }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  <div
                                    class="row"
                                    v-for="(
                                      src, k
                                    ) in currentComponentPicSrcList"
                                    :key="k"
                                  >
                                    <img
                                      class="objImg"
                                      :src="src.currentPicSrc"
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="currentComponentTypeItem == '完成車'">
                            <td style="width: 10px"></td>
                            <td></td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentPreviewItem["partNo"] }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentPreviewItem["cname"] }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  台
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentProductQuantity }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{ currentUnitPrice }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  {{
                                    Math.floor(
                                      currentProductQuantity *
                                        currentUnitPrice *
                                        (1 + currentTax / 100)
                                    )
                                  }}
                                </div>
                              </div>
                            </td>
                            <td style="color: #888">
                              <div class="media formContent">
                                <div
                                  class="media-body"
                                  v-if="currentPreviewItem != null"
                                >
                                  <div
                                    class="row"
                                    v-for="(src, k) in currentVehiclePicSrcList"
                                    :key="k"
                                  >
                                    <img
                                      class="objImg"
                                      :src="src.currentPicSrc"
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div style="text-align: right">
                        <button
                          class="btn btn-outline-success"
                          @click="addTmpProduct"
                          style="margin-right: 10px"
                        >
                          <i class="fa-solid fa-cart-plus"></i>&ensp;確定加入
                        </button>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <!-- added prodcuts -->
          <h4 style="font-weight: bold; padding: 20px 0px">貨品列表:</h4>
          <table class="table table-filter" style="border: 1px solid #ccc">
            <thead>
              <tr style="font-size: 20px; font-weight: bold">
                <th style="width: 10px"></th>
                <th>#</th>
                <th>產品編號/車架號碼</th>
                <th>品名規格</th>
                <th>數量</th>
                <th>單價</th>
                <th>含稅價</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in tmpSelectComponentList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>

                <td v-if="!isView">
                  <button
                    class="btn btn-outline-danger"
                    id="removeTmpProductBtn"
                    v-if="subId == '014-001'"
                    @click="removeTmpProduct(item.productId, item.type)"
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(item, key) in tmpSelectVehicleList" :key="key">
                <td style="width: 10px"></td>
                <td></td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["partNo"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["cname"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["quantity"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{ item["unitPrice"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media formContent">
                    <div class="media-body">
                      {{
                        Math.floor(
                          item["quantity"] *
                            item["unitPrice"] *
                            (1 + currentTax / 100)
                        )
                      }}
                    </div>
                  </div>
                </td>

                <td v-if="!isView">
                  <button
                    class="btn btn-outline-danger"
                    id="removeTmpProductBtn"
                    v-if="subId == '014-001'"
                    @click="removeTmpProduct(item.productId, item.type)"
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="col-9 mx-3 setCenter"
            style="
              padding: 20px;
              margin-left: 28% !important ;
              justify-content: right;
            "
          >
            <button
              class="btn btn-outline-primary"
              id="editCustomerBtn"
              v-if="subId == '014-001'"
              @click="doNewCustomerNetworkOrder()"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;確定新增
            </button>
            <button
              class="btn btn-outline-secondary"
              id="cancelBtn"
              data-bs-dismiss="modal"
              style="margin-left: 10px"
            >
              <i class="fa-solid fa-pen-to-square"></i>&ensp;取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm modal end -->
  <div class="row col-12">
    <h2>
      <b>{{ functionTitle }}</b>
    </h2>
    <form
      v-if="subId != '014-001'"
      class="autocomplete-container position-relative w-100 mb-4 mb-md-0"
    >
      <!--     這裡是搜尋列 -->
      <div class="input-group w-md-50 me-2">
        <input
          class="form-control"
          type="search"
          v-model.trim="search"
          @keyup="keyboardEvent"
          placeholder="請輸入搜尋關鍵字"
          aria-label="Search"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="searchBtn"
        >
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!--     這裡是搜尋列 -->

      <!--     這裡才是autoComplete的選單 -->
      <!--     控制autoComplete的開闔 -->
      <ul
        class="autoComplete position-absolute box-shadow bg-white w-100 w-md-50 z-index-3"
        :class="autoComplete ? '' : 'd-none'"
      >
        <!--     控制按鈕事件的選取背景 -->
        <li
          class="searchHover p-2 w-100"
          v-for="item in filterProducts"
          :key="item"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          @click="clickSearchItem(item)"
        >
          {{ item }}
        </li>
      </ul>
      <!--     這裡才是autoComplete的選單 -->
    </form>
    <div class="table-container" style="margin-top: 40px">
      <table class="table table-filter">
        <thead>
          <tr style="font-size: 20px; font-weight: bold">
            <th style="width: 10px"></th>
            <th>#</th>
            <th>訂單日期</th>
            <th>訂單狀態</th>
            <th style="text-align: right">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '014-001'"
                @click="showConfirmModal('', '新增訂單', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in currentOrderList" :key="key">
            <td style="width: 10px"></td>
            <td>{{ key + 1 }}</td>
            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ item["createAt"].split(".")[0].replaceAll("T", " ") }}
                </div>
              </div>
            </td>

            <td>
              <div class="media formContent">
                <div class="media-body">
                  {{ orderStausConvert[item["status"]] }}
                </div>
              </div>
            </td>
            <td style="text-align: right">
              <button
                class="btn btn-outline-success"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal"
                v-if="subId == '014-001'"
                @click="showConfirmModal(item, '訂單檢視', true)"
                style="margin-right: 10px"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import SearchBar from "../subComponents/SearchBar.vue";
import { user } from "fontawesome-regular";
import $ from "jquery";
import { ref } from "vue";

var myID = "ProjectDetail";

const Toast_Lib = require("../../js/Toast_Lib");
const Common_Lib = require("../../js/Common_Lib");
const Common_API_Lib = require("../../js/Common_API_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {},
  props: {
    subId: {
      type: String,
    },
  },
  data() {
    return {
      // function page params
      functionTitle: "",
      listTableTitle: "訂單名稱",
      nameColTitle: "訂單名稱",
      confirmModalTitle: "",
      orderStausConvert: {
        RECEIVE: "已接單",
        NOTCONFIRM: "未確認",
        DELETE: "已刪除",
        FINISH: "結單",
      },

      // API
      companyTitleList: [],
      currentCompanyTitleList: [],
      currentCompanyTitleItem: null,
      customerList: [],
      currentCustomerList: [],
      currentCustomerItem: null,
      customerLevelList: [],
      currentCustomerLevelList: [],
      currentCustomerLevelItem: null,
      userList: [],
      currentUserList: [],
      currentUserItem: null,
      orderList: [],
      currentOrderList: [],
      currentOrderItem: null,
      customerIdNameMap: null,

      componentList: [],
      currentComponentList: [],
      vehicleList: [],
      currentVehicleList: [],
      allCustomerLevelPriceMap: null,

      // params
      currentShipmentNo: "",
      currentPhone: "",
      currentShipmentDate: "",
      currentReceiveCustomer: "",
      currentClearanceDate: "",
      currentDeliveryAddress: "",
      currentPayPerson: "",
      currentFax: "",
      currentDetp: "",
      currentOrderNo: "",
      currentInvoiceNo: "",
      currentTax: "",
      currentSales: "",
      currentDeliveryMethod: "",
      currentProductTypeList: ["零件", "完成車"],
      currentComponentTypeItem: null,
      currentProductList: [],
      currentProductItem: null,
      currentPreviewItem: null,
      currentProductQuantity: 1,
      currentUnitPrice: "N/A",

      tmpSelectComponentList: [],
      tmpSelectVehicleList: [],
      tmpComponentMap: null,
      tmpVehicleMap: null,

      isView: false,

      // img
      currentComponentPicSrcList: [],
      currentVehiclePicSrcList: [],

      // search
      search: "",
      autoComplete: false,
      currentSearchColumn: "",
      searchProductText: "",
      autoCompleteProduct: false,
      tmpAddedComponentMap: {},
    };
  },

  setup() {},
  mounted() {
    if (this.subId == "014-001") {
      this.doGetCustomerNetworkOrderList("");
    }
  },
  methods: {
    async showConfirmModal(item, modalTitle, isEdit) {
      this.confirmModalTitle = modalTitle;
      this.resetConfirmModal();

      this.doGetComponentList();
      this.doGetVehicleList();
      this.isView = false;

      if (item != "") {
        this.isView = true;
        this.currentOrderItem = item;

        if (this.subId == "014-001") {
          this.doGetCustomerNetworkOrderList(item.id);
        }
      }
    },
    resetConfirmModal() {
      this.currentComponentTypeItem = null;
      this.currentProductList = [];
      this.currentProductItem = null;
      this.currentPreviewItem = null;
      this.currentProductQuantity = 1;
      this.currentUnitPrice = "N/A";

      this.tmpSelectComponentList = [];
      this.tmpSelectVehicleList = [];

      this.currentUserItem = null;
      this.currentCustomerItem = null;
      this.currentCompanyTitleItem = null;
    },
    componentTypeOnChange() {
      if (this.currentComponentTypeItem == "零件") {
        this.currentProductList = this.currentComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        this.currentProductList = this.currentVehicleList;
      }
    },

    doNewCustomerNetworkOrder(item) {
      var forms = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(forms).forEach(function (form) {
        form.classList.add("was-validated");
      });

      var url = Common_Lib.getServerUrl() + "/newCustomerNetworkOrder";
      var toastMsg = "新增網路訂單";

      // payload
      var jsonObj = {};
      let customerId = Common_Lib.getLocal("userId");
      jsonObj.createUser = Common_Lib.getLocalAndDecrypt("account");
      jsonObj.customerId = customerId;
      jsonObj.status = "NOTCONFIRM";

      if (
        this.tmpSelectComponentList.length == 0 &&
        this.tmpSelectVehicleList.length == 0
      ) {
        Toast_Lib.errorToast("沒有選擇任何產品");
        return;
      }
      jsonObj.tmpSelectComponentList = this.tmpSelectComponentList;
      jsonObj.tmpSelectVehicleList = this.tmpSelectVehicleList;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast(toastMsg + "失敗", result.errorMsg);
            } else {
              Toast_Lib.infoToast(toastMsg + "成功", "");

              this.doGetCustomerNetworkOrderList("");
              $("#closeConfirmModalBtn").click();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetSpecificCustomer() {
      var url = Common_Lib.getServerUrl() + "/getSpecificCustomer";

      var jsonObj = {};
      jsonObj.customerId = Common_Lib.getLocal("userId");

      let result = null;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶詳細資料失敗", result.errorMsg);
              return;
            } else {
              this.currentCustomerItem = result.result["customerObj"];
              this.doGetAllCustomerLevelPriceMap();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetCustomerNetworkOrderList(id) {
      var url = Common_Lib.getServerUrl() + "/getCustomerNetworkOrderList";

      var jsonObj = {};
      jsonObj.customerId = Common_Lib.getLocal("userId");

      let result = null;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得網路訂單失敗", result.errorMsg);
              this.$router.push("/dashboard");
            } else {
              this.orderList = result.result["customerNetworkOrderList"];
              this.currentOrderList = this.orderList;

              this.tmpComponentMap = result.result["tmpComponentMap"];
              this.tmpVehicleMap = result.result["tmpVehicleMap"];

              if (id != "") {
                this.tmpSelectComponentList = this.tmpComponentMap[id];
                this.tmpSelectVehicleList = this.tmpVehicleMap[id];
              }
              this.doGetSpecificCustomer();
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },

    // get combobox list
    doGetComponentList() {
      var url = Common_Lib.getServerUrl() + "/getComponentList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            this.componentList = result.result["componentList"];
            this.currentComponentList = this.componentList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetVehicleList() {
      var url = Common_Lib.getServerUrl() + "/getVehicleList";
      let result = null;

      axios
        .get(url)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            result = response.data;
            // this.vehicleIdCategoryMap = result.result["vehicleIdCategoryMap"];
            this.vehicleList = result.result["vehicleList"];
            this.currentVehicleList = this.vehicleList;

            this.searchProductText = "";
            this.autoCompleteProduct = false;
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    doGetAllCustomerLevelPriceMap() {
      if (this.currentCustomerItem == null) {
        return;
      }
      var url = Common_Lib.getServerUrl() + "/getAllCustomerLevelPriceMap";
      var jsonObj = {};
      jsonObj.customerLevelId = this.currentCustomerItem.customerLevelId;

      axios
        .post(url, jsonObj)
        .then((response) => {
          if (response.status != 200) {
            var msg = `${response.status}  ${response.statusText}<br>${response.request.responseURL}`;
            Toast_Lib.errorToast("連接錯誤", msg);
          } else {
            var result = response.data;
            if (result.errorMsg != null) {
              Toast_Lib.errorToast("取得客戶分類價格失敗", result.errorMsg);
            } else {
              this.allCustomerLevelPriceMap =
                result.result["allCustomerLevelPriceMap"];
              if (this.currentProductItem != null) {
                this.currentUnitPrice =
                  this.allCustomerLevelPriceMap[this.currentProductItem.id];
              }
            }
          }
        })
        .catch((err) => {
          // axios的錯誤都會跑到這邊
          Toast_Lib.errorToast("錯誤", err);
        });
    },
    addTmpProduct() {
      if (
        this.currentProductQuantity == null ||
        this.currentProductQuantity < 1 ||
        this.currentUnitPrice == null ||
        this.currentUnitPrice < 1 ||
        this.currentProductItem == null
      ) {
        Toast_Lib.errorToast("無法加入, 請填寫所有欄位");
        return;
      }

      let productObj = {};
      if (!this.checkTmpProductList(this.currentProductItem.id)) {
        Toast_Lib.errorToast(
          "無法加入, 該品項已存在: " + this.currentProductItem.cname
        );
        return;
      }
      productObj.productId = this.currentProductItem.id;
      productObj.cname = this.currentProductItem.cname;
      productObj.partNo = this.currentProductItem.partNo;
      productObj.quantity = this.currentProductQuantity;
      productObj.unitPrice = this.currentUnitPrice;
      productObj.priceWithTax = Math.floor(
        this.currentProductQuantity *
          this.currentUnitPrice *
          (1 + this.currentTax / 100)
      );

      if (this.currentComponentTypeItem == "零件") {
        productObj.type = "component";
        this.tmpSelectComponentList.push(productObj);
      } else if (this.currentComponentTypeItem == "完成車") {
        productObj.type = "vehicle";
        this.tmpSelectVehicleList.push(productObj);
      }
    },
    checkTmpProductList(id) {
      let targetList = [];
      if (this.currentComponentTypeItem == "零件") {
        targetList = this.tmpSelectComponentList;
      } else if (this.currentComponentTypeItem == "完成車") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId == id) {
          return false;
        }
      }
      return true;
    },
    removeTmpProduct(id, t) {
      let targetList = [];
      let resultList = [];
      if (t == "component") {
        targetList = this.tmpSelectComponentList;
      } else if (t == "vehicle") {
        targetList = this.tmpSelectVehicleList;
      }

      for (let i = 0; i < targetList.length; i++) {
        let obj = targetList[i];
        if (obj.productId != id) {
          resultList.push(obj);
        }
      }
      if (t == "component") {
        this.tmpSelectComponentList = resultList;
      } else if (t == "vehicle") {
        this.tmpSelectVehicleList = resultList;
      }
      resultList = [];
    },
    // for search bar function
    clickSearchItem(customerItem) {
      this.search = "";
      var modalTitle = "";
      var isEdit = false;
      if (this.subId == "014-001") {
        modalTitle = "訂單檢視";
        isEdit = false;
      }
      //  else {
      //   modalTitle = "確認刪除?";
      //   isEdit = false;
      // }
      this.showConfirmModal(customerItem, modalTitle, isEdit);
    },
    searchBtn() {
      this.currentOrderList = [];
      if (this.search == "") {
        this.currentOrderList = this.orderList;
      } else {
        for (var i = 0; i < this.orderList.length; i++) {
          var orderItem = this.orderList[i];
          if (orderItem["orderNo"].includes(this.search)) {
            this.currentOrderList.push(orderItem);
          }
        }
      }
    },
    async clickSearchProduct(item) {
      this.currentProductItem = item;
      this.currentPreviewItem = item;
      this.autoCompleteProduct = false;
      this.searchProductText = "";
      this.doGetSpecificCustomer();

      // get file objs
      if (this.currentComponentTypeItem == "零件") {
        // 1. get fileList by objId
        let fileIds = await Common_API_Lib.handleFileObjs(
          item.id,
          [],
          "component"
        );
        // 2. get file resouce
        let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
          fileIds
        );
        this.src = respSrc;
        this.currentComponentPicSrcList = respPicSrcList;
      } else if (this.currentComponentTypeItem == "完成車") {
        // 1. get fileList by objId
        let fileIds = await Common_API_Lib.handleFileObjs(
          item.id,
          [],
          "vehicle"
        );

        // 2. get file resouce
        let [respSrc, respPicSrcList] = await Common_API_Lib.handleFileResouce(
          fileIds
        );

        this.src = respSrc;
        this.currentVehiclePicSrcList = respPicSrcList;
      }

      // 如果有搜尋字詞的話，就顯示autoComplete選單
      // if (this.searchProductText) {
      //   console.log("tt");
      //   this.autoCompleteProduct = true;
      // } else {
      //   console.log("ff");
      //   this.autoCompleteProduct = false;
      // }
    },
  },

  watch: {
    search() {
      // 如果有搜尋字詞的話，就顯示autoComplete選單
      if (this.search) {
        this.autoComplete = true;
      } else {
        this.autoComplete = false;
      }
    },
    // 當選定選項，將 search 改成選取項目的書名後，關閉autoComplete選單
    // products() {
    //   if (this.roleList.length <= 1) {
    //     this.autoComplete = false;
    //   }
    // },
    searchProductText() {
      if (this.searchProductText) {
        this.autoCompleteProduct = true;
      } else {
        this.autoCompleteProduct = false;
      }
    },
  },
  // 搜尋功能
  computed: {
    filterProducts() {
      const arr = [];
      var searchKeyword = this.search;
      // 比對字串
      this.orderList.forEach((item) => {
        if (searchKeyword != "" && item.orderNo.includes(searchKeyword)) {
          arr.push(item);
        }
      });
      return [...new Set(arr)];
    },
    filterComponents() {
      const arr = [];
      var searchKeyword = this.searchProductText;
      // 比對字串
      this.currentProductList.forEach((item) => {
        if (
          searchKeyword != "" &&
          (item.cname.includes(searchKeyword) ||
            item.partNo.includes(searchKeyword))
        ) {
          arr.push(item);
        }
      });
      // if (arr.length > 0) {
      //   this.autoComplete = true;
      // } else {
      //   this.autoComplete = false;
      // }
      return [...new Set(arr)];
    },
  },
};
</script>

<style>
thead {
  background-color: #1c87c9;
  color: #ffffff;
}
.input-disable {
  pointer-events: none;
  border: 1px solid grey;
  background-color: lightgrey;
}
</style>
