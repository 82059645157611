<template>
  <Header></Header>
  <div class="wrapper setCenter" style="margin-top: 40px">
    <div class="row col-12 col-md-10">
      <h3>{{ functionTitle }}</h3>
      <div v-if="currentPageId == '001'">
        <Function001 :subId="subId"></Function001>
      </div>
      <div v-if="currentPageId == '002'">
        <Function002 :subId="subId"></Function002>
      </div>
      <div v-if="currentPageId == '003'">
        <Function003 :subId="subId"></Function003>
      </div>
      <div v-if="currentPageId == '005'">
        <Function005 :subId="subId"></Function005>
      </div>
      <div v-if="currentPageId == '006'">
        <Function006 :subId="subId"></Function006>
      </div>
      <div v-if="currentPageId == '007'">
        <Function007 :subId="subId"></Function007>
      </div>
      <div v-if="currentPageId == '008'">
        <Function008 :subId="subId"></Function008>
      </div>
      <div v-if="currentPageId == '010'">
        <Function010 :subId="subId"></Function010>
      </div>
      <div v-if="currentPageId == '012'">
        <Function012 :subId="subId"></Function012>
      </div>
      <div v-if="currentPageId == '014'">
        <Function014 :subId="subId"></Function014>
      </div>
      <div v-if="currentPageId == '015'">
        <Function015 :subId="subId"></Function015>
      </div>
      <div v-if="currentPageId == '016'">
        <Function016 :subId="subId"></Function016>
      </div>
      <div v-if="currentPageId == '017'">
        <Function017 :subId="subId"></Function017>
      </div>
      <div v-if="currentPageId == '020'">
        <Function020 :subId="subId"></Function020>
      </div>
    </div>
  </div>
</template>

<script>
import Function001 from "../components/functions/Function001.vue";
import Function002 from "../components/functions/Function002.vue";
import Function003 from "../components/functions/Function003.vue";
import Function005 from "../components/functions/Function005.vue";
import Function006 from "../components/functions/Function006.vue";
import Function007 from "../components/functions/Function007.vue";
import Function008 from "../components/functions/Function008.vue";
import Function010 from "../components/functions/Function010.vue";
import Function012 from "../components/functions/Function012.vue";
import Function014 from "../components/functions/Function014.vue";
import Function015 from "../components/functions/Function015.vue";
import Function016 from "../components/functions/Function016.vue";
import Function017 from "../components/functions/Function017.vue";
import Function020 from "../components/functions/Function020.vue";
import Header from "./Header.vue";
import $ from "jquery";

var myID = "ProjectDetail";

const Toast_Lib = require("../js/Toast_Lib");
const Common_Lib = require("../js/Common_Lib");
const axios = require("axios").default;

export default {
  name: myID,
  components: {
    Header,
    Function001,
    Function002,
    Function003,
    Function005,
    Function006,
    Function007,
    Function008,
    Function010,
    Function012,
    Function014,
    Function015,
    Function016,
    Function017,
    Function020,
  },
  props: {
    mainId: {
      type: String,
    },
    subId: {
      type: String,
    },
  },
  data() {
    return {
      functionTitle: "",
      currentPageId: "",
      subPermissionItemsList: {},
    };
  },

  setup() {},
  mounted() {
    this.currentPageId = this.mainId;
  },
  methods: {},
  watch: {},
  // 搜尋功能
  computed: {},
};
</script>

<style></style>
./functions/Function003.vue./functions/Function003.vue
