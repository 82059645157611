import Toastr from "toastr2";
import "toastr2/dist/toastr.min.css";
import $ from "jquery";

// toastr2 ===================================================================================================
const toastr = new Toastr({
  positionClass: "toast-top-right",
  debug: false,
  toastClass: "show", // 最重要的地方，沒有設定show class的話不會顯示
  progressBar: true,
});

function errorToast(title, message) {
  toastr.error(message, title);
}

function successToast(title, message) {
  toastr.success(message, title);
}

function warnToast(title, message) {
  toastr.warning(message, title);
}

function infoToast(title, message) {
  toastr.info(message, title);
}

// model ===================================================================================================
function installWaitingModal() {
  $("body").append(
    `<div class="modal" id="waitingModal" data-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="waitingModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered ">
			<div class="modal-content">
				<div class="modal-header bg-secondary bg-gradient text-white">
				<h4 class="modal-title" id="waitingModalLabel">系統處理中</h4>
				</div>
				<div class="modal-body">
				<h5><i class="fas fa-spinner fa-spin"></i> 請稍等...</h5>
				</div>
			</div>
			</div>
		</div>`
  );
}

function installUserProfileModal() {
  $("body").append(
    `<div class="modal" id="userProfileModal" data-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered ">
			<div class="modal-content">
				<div class="modal-header bg-secondary bg-gradient text-white">
          <h4 class="modal-title" id="userProfileModalLabel"></h4>
          <span id="closeProfile" style="cursor:pointer"><i class="fa-solid fa-xmark"></i></span>
				</div>
				<div class="modal-body">
  				<h5>帳號<span id="account"></span></h5>
          <h5>姓名</h5>
          <h5>部門</h5>
          <h5>權限</h5>
          <h5>上次登入</h5>
          <div style="text-align: right">
            <button id="doEditBtn" class="btn btn-outline-secondary" style="margin: 5px">
              <span id="editAccount" class="fa-solid fa-pen"></span>
            </button>
          </div>
				</div>
			</div>
			</div>
		</div>`
  );
}

function initUserProfileModal() {
  $("#userProfileModalLabel").text("帳戶資訊");
  $("#closeProfile").on("click", function () {
    $("#userProfileModal").hide();
  });

  $("#doEditBtn").on("click", function () {
    if ($("#editAccount")[0].classList.contains("fa-pen")) {
      $("#editAccount").removeClass("fa-pen");
      $("#editAccount").addClass("fa-floppy-disk");
    } else {
      $("#editAccount").removeClass("fa-floppy-disk");
      $("#editAccount").addClass("fa-pen");
    }
  });
}

export {
  errorToast,
  successToast,
  warnToast,
  infoToast,
  installWaitingModal,
  installUserProfileModal,
  initUserProfileModal,
};
